import client from '../client';

export const GET_LIVE_SEARCH = 'GET_LIVE_SEARCH';

export const GET_ACCEPTED_CONNECTIONS = 'GET_ACCEPTED_CONNECTIONS';
export const GET_DECLINED_CONNECTIONS = 'GET_DECLINED_CONNECTIONS';
export const GET_PENDING_CONNECTIONS = 'GET_PENDING_CONNECTIONS';

export const SEND_PLAN_INFO_REQUEST = 'SEND_PLAN_INFO_REQUEST';

export const GET_MESSAGES = 'GET_MESSAGES';
export const LOADING_MESSAGES = 'LOADING_MESSAGES';

export function getLiveSearch(schoolId, params) {
  return {
    type: GET_LIVE_SEARCH,
    promise: client.get(`schools/${schoolId}/marketing-dashboard/live-search`, params, { credentials: 'omit' }),
  };
}

export function getAcceptedConnections(schoolId, params) {
  return {
    type: GET_ACCEPTED_CONNECTIONS,
    promise: client.get(`requesttoconnect/${schoolId}/user-connections`, params, { credentials: 'omit' }),
  };
}

export function getDeclinedConnections(schoolId, params) {
  return {
    type: GET_DECLINED_CONNECTIONS,
    promise: client.get(`requesttoconnect/${schoolId}/user-connections`, params, { credentials: 'omit' }),
  };
}

export function getPendingConnections(schoolId, params) {
  return {
    type: GET_PENDING_CONNECTIONS,
    promise: client.get(`requesttoconnect/${schoolId}/user-connections`, params, { credentials: 'omit' }),
  };
}

export function sendPlanInfoRequest(name, email, message, plan) {
  var payload = new FormData();
  payload.append('name', name);
  payload.append('email', email);
  payload.append('message', message);
  payload.append('plan', plan);
  return {
    type: SEND_PLAN_INFO_REQUEST,
    promise: client.post(`features/planInfoRequest`, payload, { credentials: 'omit' }),
  };
}
