import React from 'react';
import { DownOutlined, CalendarOutlined } from '@ant-design/icons';
import styles from './range-selector.module.scss';
import { useToggle } from 'hooks/useToggle';
import { useClickOutside } from 'hooks/useClickOutside';

export const RangeSelector = ({ ranges, children, currentRange, onChangeRange }) => {
  const [isRangeOpen, toggleRange] = useToggle(false);
  const ref = useClickOutside(() => isRangeOpen && toggleRange());

  const handleSelection = (item) => {
    onChangeRange(item);
    toggleRange();
  };

  return (
    <div className={styles.selectorBlock}>
      <div className={styles.selector} ref={ref}>
        <button id="date-selector" className={styles.selector_button} onClick={toggleRange}>
          <div className={styles.selector_button_label}>
            <CalendarOutlined />
            <span>{children}</span>
          </div>
          <DownOutlined />
        </button>
        {isRangeOpen && (
          <ul className={styles.droplist}>
            {ranges.map((item) => (
              <li
                key={item.id}
                className={item.label === currentRange.label ? styles.current : undefined}
                onClick={() => handleSelection(item)}>
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
