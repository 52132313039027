import React from 'react';
import styles from './table-header.module.scss';

export const TableHeader = () => {
  return (
    <thead>
      <tr className={styles.tableHeader}>
        <th className={styles.tableHeaderCell}>Name of Campaign</th>
        <th className={styles.tableHeaderCell}>Type</th>
        <th className={styles.tableHeaderCell}>Mode</th>
        <th className={styles.tableHeaderCell}>Audience</th>
        <th className={styles.tableHeaderCell}># Executions</th>
        <th className={styles.tableHeaderCell}># Sent To</th>
        <th className={styles.tableHeaderCell}>Last Edited</th>
        <th className={styles.tableHeaderCell}>Last Sent</th>
        <th className={styles.tableHeaderCell}>Status</th>
        <th className={styles.tableHeaderCell} />
      </tr>
    </thead>
  );
};
