import { _getLeadSources, _getLeadSourceCost } from 'controllers/leads/leads_controller';
import { dateToString } from './campaign_utils';
import { getResponseValue } from 'util/validate';
import {
  _getMarketingCampaigns,
  _getScholaMarketingCampaigns,
  _getMarketingTableCounts,
} from 'controllers/schools/schools_controller';

export const otherCampaingLoadData = async (params) => {
  const { school_id: schoolId } = params;
  let payload = { data: [], pagination: {} };
  if (schoolId) {
    const { page, pageSize, start_date: startDate, end_date: endDate, sourceSelected } = params;

    let _payload = {
      page: page || 1,
      pageSize: pageSize || 10,
      source_id: sourceSelected?.value || '',
      start_date: dateToString(startDate),
      end_date: dateToString(endDate),
      school_id: schoolId,
    };

    let sourceCosts = await _getLeadSourceCost(_payload);
    sourceCosts = await getResponseValue(sourceCosts);

    payload = { data: sourceCosts.results, pagination: sourceCosts.pagination };
  }
  return payload;
};

export const otherLoadExtras = async (params) => {
  const { school_id: schoolId, extraTypes, all } = params;
  let payload = {};

  const getSources = async () => {
    let sources = await _getLeadSources(schoolId);
    return await sources.json();
  };

  if (all) payload = { ...payload, sources: await getSources() };
  else
    for (let idx in extraTypes) {
      const type = extraTypes[idx];
      switch (type) {
        case 'sources':
          payload = { ...payload, sources: await getSources() };
          break;
        default:
          break;
      }
    }

  return payload;
};

export const emailCampaingLoadData = async (params) => {
  const { school_id: schoolId } = params;
  let payload = { data: [], pagination: {} };
  if (schoolId) {
    const { page, pageSize, archived, sortField, sortDirection, filters } = params;

    let _payload = {
      school_id: schoolId,
      page: page || 1,
      pageSize: pageSize || 0,
      platform: 'email',
      archived: archived || false,
      sortField: sortField || 'updated_at',
      sortDirection: sortDirection || 'DESC',
      filters: filters || [],
    };

    let sourceCosts = await _getMarketingCampaigns(_payload);

    payload = { data: sourceCosts.results, pagination: sourceCosts.pagination };
  }
  return payload;
};

export const smsCampaingLoadData = async (params) => {
  const { school_id: schoolId } = params;
  let payload = { data: [], pagination: {} };
  if (schoolId) {
    const { page, pageSize, archived, sortField, sortDirection, filters } = params;

    let _payload = {
      school_id: schoolId,
      page: page || 1,
      pageSize: pageSize || 10,
      platform: 'sms',
      archived: archived || false,
      sortField: sortField || 'updated_at',
      sortDirection: sortDirection || 'DESC',
      filters: filters || [],
    };

    let sourceCosts = await _getMarketingCampaigns(_payload);

    payload = { data: sourceCosts.results, pagination: sourceCosts.pagination };
  }
  return payload;
};

export const emailLoadExtras = async (params) => {};

export const scholaCampaingLoadData = async (params) => {
  const { school_id: schoolId } = params;
  let payload = { data: [], pagination: {} };

  if (schoolId) {
    const { page, pageSize } = params;

    const response = await _getScholaMarketingCampaigns(schoolId, page || 1, pageSize || 10);
    payload = { data: response.results, pagination: response.pagination };
  }

  return payload;
};

export const getMarketingTableCounts = (schoolId, params) => {
  return _getMarketingTableCounts(schoolId, params);
};
