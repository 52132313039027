import { useEffect, useState } from 'react';

export function useForm(initialValue = {}, validate, callback, dynamicInputs) {
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleValue = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleToggle = (event, value) => {
    const { name } = event.target;

    setValues({
      ...values,
      [name]: !value,
    });
  };

  const reset = () => {
    setValues(initialValue);
  };

  const handleSubmit = (event) => {
    event && event.preventDefault();

    setErrors(validate(values, dynamicInputs));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  return {
    values,
    handleChange,
    handleValue,
    handleToggle,
    reset,
    handleSubmit,
    errors,
  };
}
