import React from 'react';
import { LeftOutlined, RightOutlined, CalendarOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import styles from './calendar-range.module.scss';

export const { RangePicker } = DatePicker;

export const CalendarRange = ({ className, ...props }) => {
  return (
    <div className={`${styles.calendarContainer} ${className}`}>
      <RangePicker
        className={styles.rangePicker}
        suffixIcon={<CalendarOutlined className={styles.icon} />}
        prevIcon={<LeftOutlined className={styles.icon} />}
        nextIcon={<RightOutlined className={styles.icon} />}
        {...props}
      />
    </div>
  );
};
CalendarRange.displayName = 'CalendarRange';
