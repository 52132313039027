/* eslint-disable no-debugger */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Form, Input, Select } from 'antd';
import { Buttonv2 as Button } from 'schola-components';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './historical-campaign-form.module.scss';

const HistoricalCampaignForm = ({
  setCampaignSourceID,
  setTotalSpend,
  setStudentsRecruited,
  sources,
  onSave,
  editingCampaign,
  setEditingCampaign,
}) => {
  const [form] = Form.useForm();
  const [startdate, setStartdate] = useState(moment.utc());
  const [enddate, setEnddate] = useState(moment.utc());

  useEffect(() => {
    form.setFieldsValue({
      campaignSourceID: editingCampaign ? editingCampaign.sources_id : undefined,
      totalSpend: editingCampaign ? Number(editingCampaign.amount) : undefined,
      studentsRecruited: editingCampaign ? Number(editingCampaign.students_recruited) : undefined,
      startDate: editingCampaign?.start_date ? new Date(editingCampaign.start_date) : null,
      endDate: editingCampaign?.end_date ? new Date(editingCampaign.end_date) : null,
    });
  }, [editingCampaign, form]);

  const handleFinish = (values) => {
    const formattedValues = {
      ...values,
      campaignSourceID: parseInt(values.campaignSourceID),
      totalSpend: values.totalSpend.toString(),
      studentsRecruited: parseInt(values.studentsRecruited),
      startDate: values.startDate ? values.startDate.toISOString().split('T')[0] : null,
      endDate: values.endDate ? values.endDate.toISOString().split('T')[0] : null,
    };
    onSave(formattedValues);
  };

  return (
    <div className={styles.container}>
      <div>
        <h3 className={styles.heading}>
          {editingCampaign ? 'Edit Historical Campaign' : 'Record Historical Campaign'}
        </h3>
        <p className={classNames(styles.description, 'body-regular', 'spacing-mb-16')}>
          Track the performance of your past marketing campaigns by recording their details. This helps maintain a
          comprehensive campaign history and analyze ROI.
        </p>
        <Form form={form} className="form" onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="campaignSourceID"
            label="Select Campaign Source"
            rules={[{ required: true, message: 'Please select a campaign source' }]}>
            <Select
              className={styles.select}
              placeholder="Select or Create Campaign Source"
              onChange={setCampaignSourceID}>
              {Array.isArray(sources.sources) &&
                sources.sources?.map((source) => (
                  <Select.Option key={source.id} value={source.id}>
                    {source.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <div className={styles.row}>
            <Form.Item
              name="totalSpend"
              label="Total Spend"
              className={styles.halfWidth}
              value={editingCampaign ? parseFloat(editingCampaign.amount) : undefined}
              rules={[{ required: true, message: 'Please enter total spend' }]}>
              <Input
                className={styles.input}
                type="number"
                placeholder="Enter amount"
                onChange={(e) => setTotalSpend(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="studentsRecruited"
              label="Total Students Recruited"
              className={styles.halfWidth}
              value={editingCampaign ? Number(editingCampaign.students_recruited) : undefined}
              rules={[{ required: true, message: 'Please enter number of students' }]}>
              <Input
                className={styles.input}
                type="number"
                placeholder="Enter number"
                onChange={(e) => setStudentsRecruited(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className={styles.row}>
            <Form.Item
              name="startDate"
              label="Start Date"
              className={styles.halfWidth}
              rules={[{ required: true, message: 'Please select start date' }]}>
              <DatePicker
                ref={(c) => c}
                className={styles.datePicker}
                selected={editingCampaign?.start_date ? moment.utc(editingCampaign.start_date) : startdate}
                onChange={(value) => {
                  if (value) {
                    const startDate = value._d;
                    if (editingCampaign) {
                      let endDate = editingCampaign.end_date;
                      if (startDate > endDate) endDate = startDate;
                      setEditingCampaign({ ...editingCampaign, end_date: endDate, start_date: startDate });
                    } else {
                      setStartdate(value);
                    }
                  }
                }}
                placeholderText="Pick a date"
              />
            </Form.Item>

            <Form.Item
              name="endDate"
              label="End Date"
              className={styles.halfWidth}
              rules={[{ required: true, message: 'Please select end date' }]}>
              <DatePicker
                ref={(c) => c}
                className={styles.datePicker}
                selected={editingCampaign?.end_date ? moment.utc(editingCampaign.end_date) : enddate}
                onChange={(value) => {
                  if (editingCampaign) {
                    setEditingCampaign({ ...editingCampaign, end_date: value._d });
                  } else {
                    setEnddate(value);
                  }
                }}
                placeholderText="Pick a date"
                todayButton="Today"
              />
            </Form.Item>
          </div>
          <Form.Item className={styles.submitButton}>
            <Button color="primary" size="small" type="submit" className={styles.button}>
              {editingCampaign ? 'Update Campaign' : 'Save Campaign'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default HistoricalCampaignForm;
