import React from 'react';

const Pencil = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="1 1 19 19">
      <path d="M1 15.252499v3.7499998h3.74999978L15.8099985 7.9425l-3.7499998-3.74999978L1 15.252499zM18.7099979 5.0424996c.3899994-.38999985.3899994-1.01999992 0-1.40999977L16.369998 1.29250006c-.3899994-.39000008-1.0200005-.39000008-1.4099998 0l-1.8299998 1.83000006 3.7499997 3.75000024 1.8299998-1.8299998v-.6e-7z" />
    </svg>
  );
  /* eslint-enable */
};

export default Pencil;
