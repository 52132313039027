import { combineReducers } from 'redux';
import getApiReducer, { defaultState } from '../util/get-api-reducer';

import {
  GET_APPLICATION_FIELDS,
  SET_APPLICATION_FIELDS,
  ADD_APPLICATION_FIELDS,
  REMOVE_APPLICATION_FIELDS,
  CREATE_SCHOOL,
  DELETE_SCHOOL,
  GET_SCHOOLS,
  GET_SCHOOL,
  UPDATE_SCHOOL,
  UPLOAD_SCHOOL_LOGO,
  UPLOAD_SCHOOL_IMAGE,
  UPDATE_SCHOOL_FEATURES,
  GET_SCHOOL_USERS,
  CREATE_SCHOOL_USER,
  GET_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_INVOICES,
  IMPORT_SCHOOLS,
  CLAIM_SCHOOL,
  GET_AUTOCOMPLETE_SCHOOLS,
  GET_AUTOCOMPLETE_SCHOOLS_V2,
  GET_SCHOOL_APPLICATION,
  SUBMIT_APPLICATION,
  UPDATE_PROFILE_IMAGE,
  GET_SCHOOL_CARDS,
  GET_SCHOOL_TESTIMONIAL,
  GET_SCHOOL_SCHEDULE_TOUR,
  GET_SCHOOL_SCHEDULE_TOUR_ID,
  UPDATE_SCHOOL_SCHEDULE_TOUR,
  GET_SCHOOL_REVIEWS,
  GET_SCHOOL_SEATS,
  UPDATE_SCHOOL_SEATS,
  ADD_SCHOOL_SEATS,
  ADD_SCHOOL_TOUR_TIMES,
  GET_SCHOOL_TOUR_TIMES,
  UPDATE_SCHOOL_TOUR_TIMES,
  ADD_SCHOOL_SITES_REVIEWS,
  GET_SCHOOL_SITES_REVIEWS,
  GET_SCHOOL_ACADEMICS,
  ADD_SCHOOL_ACADEMICS,
  GET_SCHOOL_VIEWS,
  UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD,
  GET_SCHOOL_CONNECT_COUNT,
  CUSTOM_UPGRADE_SCHOOL_PLAN,
  UPDATE_SCHOOL_TESTIMONIAL,
  UPDATE_SCHOOL_EMAIL_MARKETING,
  GET_SCHOOL_ENROLLEMENT,
  GET_SCHOOL_AWERENESS,
  GET_SCHOOL_ENGAGEMENT,
  GET_SCHOOL_LEADERS,
  GET_SCHOOL_TEACHER_STUFF,
} from './school-actions';
import Immutable from 'immutable';

const currentSchool = (state = null, action) => {
  switch (action.type) {
    case 'GET_SCHOOL_SUCCESS':
    case 'UPDATE_SCHOOL_SUCCESS':
    case 'UPLOAD_SCHOOL_LOGO_SUCCESS':
    case 'UPDATE_SCHOOL_FEATURES_SUCCESS':
    case 'UPDATE_PROFILE_IMAGE_SUCCESS':
    case 'CREATE_SUBSCRIPTION_SUCCESS':
      const hasPremium = function () {
        return Date.now() < new Date(this.subscription_end);
      };

      return {
        ...state,
        hasPremium,
        ...action.json,
      };
    case 'UPLOAD_SCHOOL_IMAGE_SUCCESS':
      return {
        ...state,
        images: state.images.concat(action.json),
      };
    case 'DELETE_SCHOOL_IMAGE_SUCCESS':
      return {
        ...state,
        images: state.images.filter((image) => image.id !== action.imageId),
      };
    case 'DELETE_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        stripe_subscription_id: null,
      };
    case 'GET_SCHOOL_RESET':
      return null;
    default:
      return state;
  }
};

const getSchoolUsers = (state = defaultState, action) => {
  state = getApiReducer(GET_SCHOOL_USERS)(state, action);

  switch (action.type) {
    case 'DELETE_SCHOOL_USER_SUCCESS':
      return state.set(
        'data',
        state.get('data').filterNot((user) => user.get('user_id') === action.userId)
      );
    case 'CREATE_SCHOOL_USER_SUCCESS':
      const provisionalUserRecord = Immutable.fromJS({
        user_id: action.json.user_id,
        email: action.email,
      });

      return state.set('data', state.get('data').push(provisionalUserRecord));
    default:
      return state;
  }
};

const reducers = combineReducers({
  getApplicationFields: getApiReducer(GET_APPLICATION_FIELDS),
  setApplicationFields: getApiReducer(SET_APPLICATION_FIELDS),
  addApplicationFields: getApiReducer(ADD_APPLICATION_FIELDS),
  removeApplicationFields: getApiReducer(REMOVE_APPLICATION_FIELDS),
  createSchool: getApiReducer(CREATE_SCHOOL),
  getSchool: getApiReducer(GET_SCHOOL),
  schools: getApiReducer(GET_SCHOOLS),
  deleteSchool: getApiReducer(DELETE_SCHOOL),
  updateSchool: getApiReducer(UPDATE_SCHOOL),
  updateSchoolFeatures: getApiReducer(UPDATE_SCHOOL_FEATURES),
  uploadSchoolLogo: getApiReducer(UPLOAD_SCHOOL_LOGO),
  uploadSchoolImage: getApiReducer(UPLOAD_SCHOOL_IMAGE),
  currentSchool,
  getSchoolUsers,
  createSchoolUser: getApiReducer(CREATE_SCHOOL_USER),
  getSubscription: getApiReducer(GET_SUBSCRIPTION),
  createSubscription: getApiReducer(CREATE_SUBSCRIPTION),
  deleteSubscription: getApiReducer(DELETE_SUBSCRIPTION),
  getInvoices: getApiReducer(GET_INVOICES),
  importSchools: getApiReducer(IMPORT_SCHOOLS),
  claimSchool: getApiReducer(CLAIM_SCHOOL),
  getAutocompleteSchools: getApiReducer(GET_AUTOCOMPLETE_SCHOOLS),
  getAutocompleteSchoolsV2: getApiReducer(GET_AUTOCOMPLETE_SCHOOLS_V2),
  getSchoolApplication: getApiReducer(GET_SCHOOL_APPLICATION),
  submitApplication: getApiReducer(SUBMIT_APPLICATION),
  updateSchoolProfileImage: getApiReducer(UPDATE_PROFILE_IMAGE),
  getSchoolCards: getApiReducer(GET_SCHOOL_CARDS),
  getSchoolScheduleTour: getApiReducer(GET_SCHOOL_SCHEDULE_TOUR),
  getSchoolScheduleTourById: getApiReducer(GET_SCHOOL_SCHEDULE_TOUR_ID),
  updateSchoolSheduleTour: getApiReducer(UPDATE_SCHOOL_SCHEDULE_TOUR),
  getSchoolTestimonials: getApiReducer(GET_SCHOOL_TESTIMONIAL),
  getSchoolReviews: getApiReducer(GET_SCHOOL_REVIEWS),
  getSchoolSeats: getApiReducer(GET_SCHOOL_SEATS),
  updateSchoolSeats: getApiReducer(UPDATE_SCHOOL_SEATS),
  addSchoolSeats: getApiReducer(ADD_SCHOOL_SEATS),
  addSchoolTourTimes: getApiReducer(ADD_SCHOOL_TOUR_TIMES),
  getSchoolTourTimes: getApiReducer(GET_SCHOOL_TOUR_TIMES),
  updateSchoolTouRtimes: getApiReducer(UPDATE_SCHOOL_TOUR_TIMES),
  addSchoolSitesReviews: getApiReducer(ADD_SCHOOL_SITES_REVIEWS),
  getSchoolSitesReviews: getApiReducer(GET_SCHOOL_SITES_REVIEWS),
  getSchoolAcademics: getApiReducer(GET_SCHOOL_ACADEMICS),
  addSchoolAcademics: getApiReducer(ADD_SCHOOL_ACADEMICS),
  getSchoolViews: getApiReducer(GET_SCHOOL_VIEWS),
  updateSchoolScheduleTourLead: getApiReducer(UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD),
  getSchoolConnectCount: getApiReducer(GET_SCHOOL_CONNECT_COUNT),
  customerUpgrade: getApiReducer(CUSTOM_UPGRADE_SCHOOL_PLAN),
  updateSchoolTestimonial: getApiReducer(UPDATE_SCHOOL_TESTIMONIAL),
  updateSchoolEmailMarketing: getApiReducer(UPDATE_SCHOOL_EMAIL_MARKETING),
  getSchoolEnrollement: getApiReducer(GET_SCHOOL_ENROLLEMENT),
  getSchoolAwareness: getApiReducer(GET_SCHOOL_AWERENESS),
  getSchoolEngagement: getApiReducer(GET_SCHOOL_ENGAGEMENT),
  getSchoolLeaders: getApiReducer(GET_SCHOOL_LEADERS),
  getSchoolTeacherStuff: getApiReducer(GET_SCHOOL_TEACHER_STUFF),
});

export default reducers;
