import React from 'react';

const LogoWhite = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 4 241 280">
      <defs>
        <path id="a" d="M.07964453 13.0395604H24.0190679V.1561483H.07964453v12.8834121z" />
        <path id="c" d="M10.3764255.23829158H.12893617V46.7571791H10.3764255V.23829157z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M126.352664 216.06007c-7.68-3.462131-15.013277-6.726879-22.975915-10.294349l-.191489-.086931-.169022-.082583-.155489-.068266-.131745-.062641h-.00434l-.026809-.014063c-30.0717444-13.946704-57.4886805-31.135913-57.2609359-70.392916.2468937-43.1381899 33.7424681-70.8810052 75.4562549-70.8810052 41.713532 0 75.214469 27.7428153 75.456 70.8810052.099064 17.393495-7.505361 33.09108-18.875489 45.599598-3.364596 3.701446-9.182808 3.957891-12.710808.412408-.084256-.084629-.166979-.169514-.248171-.254399-3.089872-3.223328-3.037276-8.407704-.022212-11.701343 8.560595-9.351922 14.571319-21.038181 14.500595-33.956294-.19251-33.5236873-25.971319-53.5951503-58.099915-53.5951503-32.1278293 0-57.9074038 20.071463-58.0988931 53.5951503-.2236596 39.659439 41.2159151 51.618762 70.7443401 65.076867 9.153702 4.17496 25.963149 12.344628 22.825022 24.924991-3.087064 12.34872-22.87532 13.664181-33.212937 13.710715-9.92834.044488-31.6669783-.831464-33.5964251-13.581342-.0079149-.052414-.0153192-.104827-.0227235-.157241-.7077446-5.104605 5.4525958-7.959246 9.3702128-4.617283 13.0669788 11.146779 56.5988938 5.799792 27.4508938-4.454928m-3.191004 33.675049c-7.089958.041164-19.386128-.429539-28.2896174-5.160342-1.6468086-.874674-3.6566809.482975-3.438383 2.337149.0020425.016108.0040851.032215.0058723.048323 1.8217021 15.142509 22.3353191 16.186441 31.7057871 16.132749 9.75549-.051391 28.433362-1.616139 31.346553-16.284366.026554-.134742.051575-.268973.075319-.402692.348-1.970252-1.795914-3.438098-3.528255-2.441722-8.634127 4.964749-20.393362 5.730504-27.877276 5.770901"
          fill="#FEFEFE"
        />
        <g transform="translate(110.787 269.923)">
          <mask id="b" fill="white">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M.1113702 1.39423582l1.87480852 7.0076132C2.7179234 11.1373442 5.19324255 13.039586 8.02115745 13.039586h8.04153195c2.8228085 0 5.2937872-1.8989179 6.024-4.6295553l1.9005957-7.1057934C24.156817.67092373 23.6234553.08005266 22.9739234.1641706 19.1073702.6652988 15.4629447.809501 12.480817.8279098 9.42388085.84606292 5.41179574.75989956 1.11477447.25110102.46830637.17465342-.0568851.76450177.1113702 1.39423582"
            fill="#FEFEFE"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(115.638 4.018)">
          <mask id="d" fill="white">
            <use xlinkHref="#c" />
          </mask>
          <path
            d="M5.25268085 46.7571791c-2.82995745 0-5.12374468-2.2972637-5.12374468-5.1309392V5.3693586c0-2.83393113 2.29378723-5.13119486 5.12374468-5.13119486 2.82995745 0 5.12374465 2.29726373 5.12374465 5.13119487v36.2568813c0 2.8336755-2.2937872 5.1309392-5.12374465 5.1309392"
            fill="#FEFEFE"
            mask="url(#d)"
          />
        </g>
        <path
          d="M72.7411319 63.6968974c-1.7655319 0-3.4833191-.9145589-4.4346383-2.553197l-8.1107234-13.9725282c-1.4218723-2.450159-.5915745-5.5906477 1.8551489-7.0147722 2.4462128-1.4241245 5.5825532-.5926608 7.0046809 1.8574982l8.1107234 13.9725282c1.4218723 2.4499033.5915745 5.5903919-1.8551489 7.0145165-.8098724.4717252-1.6958298.6959545-2.5700426.6959545M37.4741532 98.9991552c-.8693617 0-1.7502128-.2216725-2.5572766-.6882843L3.56291915 80.18306947c-2.4508085-1.41696557-3.2905532-4.55489744-1.87557447-7.00914726 1.41497872-2.4542498 4.54876596-3.29491792 6.99931915-1.87820803L40.0408766 89.4235156c2.4505532 1.4169656 3.2902979 4.5551531 1.8753191 7.0091473-.9487659 1.6460527-2.6711489 2.5664923-4.4420425 2.5664923M24.5760681 147.224103H6.71011064c-2.82995745 0-5.12374468-2.297264-5.12374468-5.131195 0-2.833675 2.29378723-5.130939 5.12374468-5.130939H24.5760681c2.8299574 0 5.1237447 2.297264 5.1237447 5.130939 0 2.833931-2.2937873 5.131195-5.1237447 5.131195m210.4991489 0h-17.868255c-2.829958 0-5.124-2.297264-5.124-5.131195 0-2.833675 2.294042-5.130939 5.124-5.130939h17.868255c2.829957 0 5.123745 2.297264 5.123745 5.130939 0 2.833931-2.293788 5.131195-5.123745 5.131195m-30.766213-48.2249479c-1.770893 0-3.493276-.9201839-4.442042-2.5664923-1.414979-2.4542498-.575234-5.5921817 1.875319-7.0091473l31.355489-18.1278014c2.450809-1.4167099 5.584596-.5757861 6.999319 1.878208 1.414979 2.4542498.575234 5.5924374-1.875574 7.0091473l-31.355234 18.1278014c-.807064.4666118-1.68817.6882843-2.557277.6882843m-35.266187-35.3023599c-.913787 0-1.838808-.2446836-2.674979-.75834-2.411744-1.4821633-3.167234-4.6418278-1.687149-7.0569589l8.560341-13.9702271c1.479574-2.4151312 4.635064-3.171937 7.047064-1.689518 2.411744 1.4819077 3.167234 4.6415722 1.687149 7.056959l-8.560341 13.9699714c-.966893 1.5780425-2.649191 2.4481136-4.372085 2.4481136"
          fill="#FEFEFE"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default LogoWhite;
