import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './modal-desktop.module.scss';

export const ModalDesktop = ({ children, className, isVisible, onClose, title }) => {
  if (!isVisible) return null;

  const modalClasses = {
    [styles.modal]: true,
    [className]: className,
  };

  return (
    <div className={styles.background}>
      <div className={classNames(modalClasses)}>
        <Header title={title} onClose={onClose} />
        {children}
      </div>
    </div>
  );
};

const Header = ({ title, onClose }) => {
  return (
    <div className={styles.header}>
      {title && <h3>{title}</h3>}

      <button className={styles.close} onClick={onClose}>
        <CloseOutlined />
      </button>
    </div>
  );
};

const Body = ({ children, className }) => {
  return <div className={`${styles.body} ${className}`}>{children}</div>;
};

const BarContainer = ({ children }) => {
  return <>{children}</>;
};

const Footer = ({ children }) => {
  return <div className={`${styles.footer} modal-desktop-footer`}>{children}</div>;
};

ModalDesktop.Header = Header;
ModalDesktop.Body = Body;
ModalDesktop.Footer = Footer;
ModalDesktop.BarContainer = BarContainer;
