import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './button.module.scss';

const Button = ({
  className,
  children,
  color,
  disabled,
  fullWidth,
  fullWidthMobile,
  icon,
  onClick,
  type,
  ...props
}) => {
  const buttonClasses = {
    [styles.global]: true,
    [styles.primary]: color === 'primary',
    [styles.secondary]: color === 'secondary',
    [styles.ghost]: color === 'ghost',
    [styles.elementbase]: color === 'elementbase',
    [styles.link]: color === 'link',
    [styles.bluelegacy]: color === 'bluelegacy' || color === 'primarylegacy',
    [styles.greenlegacy]: color === 'greenlegacy' || color === 'secondarylegacy',
    [styles.danger]: color === 'danger' || color === 'red' || color === 'redlegacy',
    [styles.disabled]: disabled,
    [styles.block_mobile]: fullWidthMobile,
    [styles.display_block]: fullWidth,
    [className]: className,
  };
  const iconClassess = {
    icon: true,
    [`${className}__icon`]: className,
    [styles.children_icon]: icon,
  };

  return (
    <button type={type} className={classNames(buttonClasses)} disabled={disabled} onClick={onClick} {...props}>
      {icon ? (
        <span className={classNames(iconClassess)}>{children}</span>
      ) : (
        <span className={styles.children}>{children}</span>
      )}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'ghost',
    'elementbase',
    'link',
    'bluelegacy',
    'primarylegacy',
    'greenlegacy',
    'secondarylegacy',
    'danger',
    'red',
    'redlegacy',
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullWidthMobile: PropTypes.bool,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button']),
};

Button.defaultProps = {
  className: null,
  children: 'Label',
  color: 'primary',
  disabled: false,
  fullWidth: false,
  fullWidthMobile: false,
  icon: null,
  onClick: () => {},
  type: 'submit',
};

export default Button;
