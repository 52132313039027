import React from 'react';

const Logo = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="133 102 252 293">
      <defs>
        <polygon
          id="path-1"
          points="0.0823857965 13.6190476 25.0855954 13.6190476 25.0855954 0.163087649 0.0823857965 0.163087649 0.0823857965 13.6190476"
        />
      </defs>
      <g
        id="Logo"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(139.000000, 108.000000)">
        <path
          d="M125.589707,215.876325 C117.568373,212.260334 109.909173,208.850499 101.59264,205.124487 L101.39264,205.033694 L101.21584,204.94744 L101.053973,204.87614 L100.91584,204.810715 L100.911573,204.810715 L100.883573,204.796028 C69.4755733,190.229522 40.8401067,172.276413 41.0777067,131.274801 C41.3355733,86.2195201 76.3201067,57.2437946 119.887573,57.2437946 C163.45504,57.2437946 198.444907,86.2195201 198.697173,131.274801 C198.80064,149.441275 190.85824,165.836472 178.982773,178.900878 C175.46864,182.766818 169.39184,183.034659 165.70704,179.331613 C165.61904,179.243223 165.53264,179.154566 165.44784,179.065908 C162.22064,175.699333 162.275573,170.28456 165.42464,166.844549 C174.365707,157.077021 180.643573,144.871417 180.569707,131.379214 C180.36864,96.3657106 153.444107,75.4022577 119.887573,75.4022577 C86.3321067,75.4022577 59.4065067,96.3657106 59.2065067,131.379214 C58.9731733,172.801147 102.254507,185.29195 133.095307,199.348142 C142.655573,203.708641 160.212107,212.241374 156.934507,225.380818 C153.71024,238.278323 133.042507,239.652245 122.24544,239.700846 C111.87584,239.747311 89.17104,238.832431 87.15584,225.51594 C87.1475733,225.461197 87.1395733,225.406454 87.1321067,225.351711 C86.39264,220.020254 92.8267733,217.038751 96.9187733,220.529232 C110.566507,232.171382 156.033173,226.586771 125.589707,215.876325"
          id="Fill-1"
          fill="#0199CF"
        />
        <path
          d="M122.25688,251.047916 C114.851813,251.090909 102.009147,250.599289 92.7099467,245.658245 C90.9899467,244.7447 88.8907467,246.162683 89.1190133,248.099259 C89.12088,248.116082 89.1230133,248.132906 89.12488,248.149729 C91.0275467,263.965182 112.45288,265.055507 122.239813,264.999429 C132.42888,264.945754 151.93688,263.311468 154.979547,247.991374 C155.00728,247.850644 155.033413,247.710448 155.058213,247.570786 C155.42168,245.512975 153.18248,243.979897 151.373147,245.020553 C142.35528,250.205938 130.073413,251.005724 122.25688,251.047916"
          id="Fill-3"
          fill="#8ac341"
        />
        <g id="Group-7" transform="translate(109.333333, 272.132605)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Clip-6" />
          <path
            d="M0.1156,1.45619664 L2.07346667,8.77523305 C2.83773333,11.6322956 5.42333333,13.6190743 8.37666667,13.6190743 L16.7756,13.6190743 C19.7241333,13.6190743 22.3046667,11.6357671 23.0676,8.78377834 L25.0524,1.36219851 C25.2294667,0.700740056 24.6724,0.0836102708 23.994,0.17146648 C19.9558667,0.694865173 16.1492,0.845475817 13.0348,0.864702708 C9.84173333,0.883662558 5.6516,0.793670028 1.16333333,0.262260131 C0.4884,0.182415126 -0.0604,0.798476751 0.1156,1.45619664"
            id="Fill-5"
            fill="#8ac341"
            mask="url(#mask-2)"
          />
        </g>
        <path id="Stroke-8" stroke="#8ac341" strokeWidth="10.7032" strokeLinecap="round" />
        <path
          d="M69.5906133,51.3820764 L61.1194133,36.7885993"
          id="Stroke-10"
          stroke="#8ac341"
          strokeWidth="10.7032"
          strokeLinecap="round"
        />
        <path
          d="M32.7658667,88.2531643 L0.0181333333,69.3197507"
          id="Stroke-12"
          stroke="#8ac341"
          strokeWidth="10.7032"
          strokeLinecap="round"
        />
        <path id="Stroke-14" stroke="#8ac341" strokeWidth="10.7032" strokeLinecap="round" />
        <path id="Stroke-16" stroke="#8ac341" strokeWidth="10.7032" strokeLinecap="round" />
        <path
          d="M207.00584,88.2531643 L239.754907,69.3197507"
          id="Stroke-18"
          stroke="#8ac341"
          strokeWidth="10.7032"
          strokeLinecap="round"
        />
        <path
          d="M170.1824,51.3820764 L179.1232,36.7912697"
          id="Stroke-20"
          stroke="#8ac341"
          strokeWidth="10.7032"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default Logo;
