import moment from 'moment';
import { isBlank } from 'util/validate';
import campaingConstants from './CampaingConstants';

export const getConversionRate = (fbReport, applicationReceived, leadsCount) => {
  let convertRage;

  if (fbReport) {
    convertRage =
      fbReport.total_leads_received && fbReport.total_leads_received > 0
        ? applicationReceived / fbReport.total_leads_received
        : 0;
  } else {
    let leadTotal = 0;
    leadsCount &&
      leadsCount.map((i) => {
        if (i && i.total) leadTotal += parseInt(i.total);
      });
    const completed = leadsCount
      ? leadsCount
          .filter((lc) => lc.status === 'accepted' || lc.status === 'declined')
          .reduce((total, value) => total + parseInt(value.total), 0)
      : 0;
    let omitearchiveds = 0;
    if (leadsCount[7] !== undefined) {
      let _archived = leadsCount[7].data;
      if (_archived.length > 0) {
        omitearchiveds = parseInt(_archived[0].total);
      }
    }

    convertRage = completed ? (completed / (leadTotal - omitearchiveds)) * 100 : 0;
  }

  return convertRage;
};

export const toCurrency = (value) => {
  return value
    ? `$${parseFloat(value)
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
    : 'N/A';
};

export const toPercentage = (value) => {
  return value ? `${parseFloat(value).toFixed(2).toString()}%` : 'N/A';
};

export const toAudienceLanguage = (value) => {
  if (value === 'es') {
    return 'Spanish Only';
  }
  if (value === 'en') {
    return 'English Only';
  }
  if (value === 'all') {
    return 'All';
  }

  return '';
};

export const toCampaingTypeLabel = (platform, type) => {
  var campaignType = campaingConstants.CAMPAIGN_TYPES.find((x) => x.type === type);
  if (campaignType) {
    var isSms = campaingConstants.PLATAFORM_SMS === platform;
    return isSms === true ? campaignType.labelSms : campaignType.labelEmail;
  }

  return '';
};

export const dateToString = (date) => (date ? moment.utc(date).format('YYYY-MM-DD') : '');

export const campaingContentCheck = (campaign, keys) => {
  if (!campaign || !keys) return {};
  return {
    // subject: !isBlank(campaign.subject),
    // from: !isBlank(campaign.from_name) && isEmail(campaign.from_email),
    languaje: !isBlank(campaign.audience_language),
    ...checkReady(campaign, keys),
  };
};

const getParamValue = (campaign, key) => campaign?.params.find((p) => p.key == key)?.value;

const checkReady = (campaign, layoutKeys) => {
  let isReady = {};
  if (campaign) {
    const { params } = campaign;
    if (params) {
      layoutKeys.map(
        (key) => (isReady[key.replace(/{/gi, '').replace(/}/gi, '')] = !isBlank(getParamValue(campaign, key)))
      );
    }
  }

  return isReady;
};
