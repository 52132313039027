import React from 'react';

const Facebook = () => {
  /* eslint-disable max-len */
  return (
    <svg width="1792" height="1792" viewBox="0 0 1792 1792">
      <path d="M1376 128q119 0 203.5 84.5T1664 416v960q0 119-84.5 203.5T1376 1664h-188v-595h199l30-232h-229V689q0-56 23.5-84t91.5-28l122-1V369q-63-9-178-9-136 0-217.5 80T948 666v171H748v232h200v595H416q-119 0-203.5-84.5T128 1376V416q0-119 84.5-203.5T416 128h960z" />
    </svg>
  );
  /* eslint-enable */
};

export default Facebook;
