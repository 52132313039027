import React from 'react';

const CircleFill = ({ color }) => {
  return (
    <div style={{ width: '6px' }} className="circle-fill">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 16">
        <circle fill={color} cx="8" cy="8" r="6" />
      </svg>
    </div>
  );
};

export default CircleFill;
