import { useEffect, useState } from 'react';
import { _getLiveLeadCategoriesDetails } from 'controllers/livesearch/livesearch_controller';

export const useLiveLeadsDetails = (schoolId, { longitude, latitude }) => {
  const [liveLeads, setLiveLeads] = useState([]);

  useEffect(() => {
    const payload = {
      distance: 50,
      longitude,
      latitude,
      categories: ['potential'],
      pageSize: 50,
    };

    if (longitude && latitude) {
      _getLiveLeadCategoriesDetails(schoolId, payload).then(({ leads }) => setLiveLeads(leads));
    }
  }, [longitude, latitude]);

  return { liveLeads };
};
