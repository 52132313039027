import React from 'react';

const Checkbox = ({ color }) => {
  const [colorIcon, setColorIcon] = React.useState(color);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 14" width="20">
      <g>
        <path
          fill={colorIcon}
          d="M5.3,12.5c-0.1,0-0.3-0.1-0.4-0.2l-5.3-5.5c-0.2-0.2-0.2-0.5,0-0.7C-0.2,6,0.2,6,0.4,6.2l5,5.1L16.6-0.3
					c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7l-11.7,12C5.6,12.4,5.4,12.5,5.3,12.5z"
        />
      </g>
    </svg>
  );
};

export default Checkbox;
