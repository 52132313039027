import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';

import {
  GET_LIVE_SEARCH,
  GET_ACCEPTED_CONNECTIONS,
  GET_DECLINED_CONNECTIONS,
  GET_PENDING_CONNECTIONS,
  LOADING_MESSAGES,
  GET_MESSAGES,
} from './dashboard-actions';
import { _getMessageCountBySchool } from 'controllers/messages/messages_controllers';
import { _getSchoolById } from 'controllers/schools/schools_controller';

const reducers = combineReducers({
  userSearchResults: getApiReducer(GET_LIVE_SEARCH),
  accepedConnections: getApiReducer(GET_ACCEPTED_CONNECTIONS),
  declinedConnections: getApiReducer(GET_DECLINED_CONNECTIONS),
  pendingConnections: getApiReducer(GET_PENDING_CONNECTIONS),
});

const initialState = {
  messageCount: {},
  status: 'uninitialized',
};

export const getMessagesAction = (schoolId) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_MESSAGES });

    try {
      const messages = await _getMessageCountBySchool(schoolId);

      dispatch({ type: GET_MESSAGES, payload: messages });
    } catch (error) {
      console.log('Error trying to fetch count messages', error);
    }
  };
};

export const messagesCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MESSAGES:
      return {
        ...state,
        status: 'loading',
      };
    case GET_MESSAGES:
      return {
        ...state,
        messageCount: action.payload,
        status: 'success',
      };

    default:
      return state;
  }
};

export const getSchoolAction = (schoolId) => {
  return async (dispatch) => {
    dispatch({ type: 'LOADING_SCHOOL' });
    try {
      const res = await _getSchoolById(schoolId);
      const school = await res.json();

      dispatch({ type: 'GET_SCHOOL', payload: school });
    } catch (error) {
      console.log(error);
    }
  };
};

export const schoolReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_SCHOOL':
      return {
        ...state,
        school: action.payload,
        status: 'success',
      };
    case 'LOADING_SCHOOL':
      return {
        ...state,
        status: 'loading',
      };
    default:
      return state;
  }
};

export default reducers;
