import { useMemo, useState } from 'react';
import { Typography, Row, Col, Flex } from 'antd';
import CampaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { InputPersonalization } from 'components/pages/campaigns/components/shared/input-personalization';
import { CTAcampaign } from 'components/pages/campaigns/components/shared/cta-campaign';
import { LAYOUTS } from 'components/pages/campaigns/components/email/Layouts';
import { Signature } from 'components/pages/campaigns/components/shared/signature';
import CDPreview from 'components/pages/campaigns/components/email/CDPreview';
import EmailMainBody from 'components/pages/campaigns/components/email/EmailMainBody';
import { InputCampaign, SectionTitle } from 'components/pages/campaigns/components/shared/input-campaign';
import { useUpdateNode } from '../../hooks';
import { useNodesData } from '@xyflow/react';
import styles from './edit-node-content.module.scss';
import { SwitchPreview } from 'components/pages/campaigns/components/shared/switch-preview';

export const EditNodeContent = ({ nodeId, campaign, currentSchool }) => {
  const [layout] = LAYOUTS;
  const { SUBJECT_KEY, EMAIL_KEY, NAME_KEY } = CampaingConstants;
  const { HEADER_KEY } = layout.keys;

  const currentNode = useNodesData(nodeId);

  const { handleNodeParam } = useUpdateNode();
  const getNodeParamValue = (key) => {
    const value = currentNode.data.find((item) => item.key === key)?.value;
    if (!value) return '';
    return value;
  };

  const onChangeParams = (payload) => {
    handleNodeParam({ nodeId, key: payload.key, value: payload.value });
  };

  const campaignModified = useMemo(() => {
    return {
      ...campaign,
      params: currentNode.data,
    };
  }, [campaign, currentNode.data]);

  const [view, setView] = useState('edit'); // 'edit' || 'preview'
  const onSwitchView = (key) => {
    setView(key);
  };

  return (
    <Row className={styles.layout}>
      <Col span={12} className={styles.details}>
        <Title>Email Details</Title>
        <GroupInputs>
          <div>
            <SectionTitle>Subject</SectionTitle>
            <InputPersonalization
              field={SUBJECT_KEY}
              value={getNodeParamValue(SUBJECT_KEY)}
              onChangeParams={onChangeParams}
            />
          </div>
          <div>
            <SectionTitle>From</SectionTitle>
            <Row gutter={12}>
              <Col span={12}>
                <InputCampaign id={EMAIL_KEY} value={getNodeParamValue(EMAIL_KEY)} onChange={onChangeParams} />
              </Col>
              <Col span={12}>
                <InputCampaign id={NAME_KEY} value={getNodeParamValue(NAME_KEY)} onChange={onChangeParams} />
              </Col>
            </Row>
          </div>

          <div>
            <SectionTitle>Call to Action</SectionTitle>
            <CTAcampaign layout={layout} getParamValue={getNodeParamValue} onChangeParams={onChangeParams} />
          </div>

          <div>
            <SectionTitle>Signature</SectionTitle>
            <Signature
              school={{ name: 'Test School' }}
              layout={layout}
              getParamValue={getNodeParamValue}
              onChangeParams={onChangeParams}
            />
          </div>
        </GroupInputs>
      </Col>

      <Col span={12} className={styles.content}>
        <Title>Email Content</Title>
        <SwitchPreview onSwitchView={onSwitchView} />
        {view === 'edit' && (
          <GroupInputs>
            <div>
              <SectionTitle>Header</SectionTitle>
              <InputPersonalization
                field={HEADER_KEY}
                value={getNodeParamValue(HEADER_KEY)}
                onChangeParams={onChangeParams}
              />
            </div>

            <EmailMainBody
              campaign={campaignModified}
              validatePlanInFrontend={false}
              isPremium
              school={currentSchool}
              layout={layout}
              onChangeParams={onChangeParams}
            />
          </GroupInputs>
        )}
        {view === 'preview' && <CDPreview school={currentSchool} campaign={campaignModified} layout={layout} />}
      </Col>
    </Row>
  );
};

const Title = ({ children }) => (
  <Typography.Title level={4} style={{ marginBottom: '1.5rem' }}>
    {children}
  </Typography.Title>
);
const GroupInputs = ({ children }) => (
  <Flex vertical gap="large">
    {children}
  </Flex>
);
