import React, { Component, Fragment } from 'react';
import Modal from 'components/modal/Modal';

export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return () => {
      return (
        <Modal isVisible className="modal-loading">
          <div className="img-loading-search" style={{ display: 'block' }}>
            <img
              src="https://scholamatch-static.s3-us-west-2.amazonaws.com/LoadingAnimation200Opt.gif"
              alt="schola loading"
            />
          </div>
        </Modal>
      );
    };
  }
}
