import { _getTimelineByMonth } from 'controllers/dashboard/dashboard_controller';
import { useStatus } from 'hooks/useStatus';
import { useEffect, useState } from 'react';

export const useLeadTimeline = ({ schoolId, sinceDate, today }) => {
  const [timeline, setTimeline] = useState([]);
  const { onLoading, onSuccess, onError, status } = useStatus();

  useEffect(() => {
    if (sinceDate) {
      onLoading();

      getTimeline({ schoolId, sinceDate })
        .then((timeline) => {
          setTimeline(timeline);
          onSuccess();
        })
        .catch((err) => {
          console.log('timeline error:', err.message);
          onError();
        });
    }

    /** Call to _getTimelineByMonth every time sinceDate change */
  }, [sinceDate]);

  return { timeline, isErrorTimeline: status.error, isLoadingChart: status.loading };
};

const getTimeline = async ({ schoolId, sinceDate }) => {
  const data = await _getTimelineByMonth(schoolId, { sinceDate });
  return data;
};
