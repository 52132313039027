import { Modal, Typography } from 'antd';
import { Button, SettingSelector, TemplateSelector } from '..';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { STAGES, useCampaignSettings } from '../../hooks/useCampaignSettings';
import styles from './campaign-creation-settings.module.scss';
import { _addMarketingCampaign } from 'controllers/schools/schools_controller';
import { useTemplates } from '../../hooks/useCampaignTemplates';

const { Title } = Typography;

export const CampaignCreationSettings = ({ isVisible = true, toggleVisible }) => {
  const { stage, settings, optionsModes, optionsAdvanced, optionsSimple, onTemplateSelect, backToMode, titles } =
    useCampaignSettings();

  const options = {
    mode: optionsModes,
    simple: optionsSimple,
    advanced: optionsAdvanced,
  };

  const templates = useTemplates();

  return (
    <Modal open={isVisible} onCancel={toggleVisible} footer={null}>
      <div className={styles.header}>
        <Button
          type="ghost"
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={backToMode}
          className={styles.backButton}
        />
        <Title level={4}>{titles[stage]}</Title>
      </div>

      {stage !== STAGES.TEMPLATE ? (
        <SettingSelector options={stage === STAGES.MODE ? options.mode : options[settings.mode]} />
      ) : (
        <TemplateSelector templates={templates} onTemplateSelect={onTemplateSelect} />
      )}
    </Modal>
  );
};
