import { ContentPreview, NodeWrapper } from '.';
import { MessageOutlined } from '@ant-design/icons';

export const NodeSms = ({ data, id }) => {
  const previewText = data.content || 'No content';

  return (
    <NodeWrapper isFirstNode={data.isFirstNode}>
      <NodeWrapper.Header id={id} Icon={MessageOutlined}>
        Send SMS
      </NodeWrapper.Header>
      <ContentPreview>{previewText}</ContentPreview>
    </NodeWrapper>
  );
};
