import localstorage from 'store2';

export function validate(key, value) {
  let validated = false;
  switch (key) {
    case 'email':
      var emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      validated = emailRegex.test(String(value).toLowerCase());
      break;
    case 'phone':
      var phoneRegex = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)\s[0-9]{3}\s-\s[0-9]{4}$/;
      validated = phoneRegex.test(String(value).toLowerCase());
      break;
    default:
      validated = true;
  }
  return validated;
}
export const isEmpty = (arry) => !(arry != null && Array.isArray(arry) && arry.length > 0);

export const isBlank = (str) => str == null || /^\s*$/.test(str);

export const isEmail = (str) =>
  !isBlank(str) &&
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(str).toLowerCase()
  );

export const isPhone = (str) =>
  str && /^(\([0-9]{3}\)\s*|[0-9]{3}\-)\s[0-9]{3}\s-\s[0-9]{4}$/.test(String(str).toLowerCase());

export const getResponseValue = (res) => {
  return res.json().then((value) => {
    let redirectLogin = false;
    if (value && value.error) {
      const { error } = value;
      if (error && (error.toLowerCase == 'unauthorized' || value.statusCode == 401)) {
        redirectLogin = true;
      }
    } else if (value.statusCode === 401) {
      redirectLogin = true;
    }
    if (redirectLogin) {
      localstorage.set('idToken', null);
      window.location.replace('/login');
    } else {
      return value;
    }
  });
};
