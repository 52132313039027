import React, { Component, Fragment } from 'react';
import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';
import { LAYOUTS } from './Layouts';

export default class LayoutSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSave = (e) => {
    e.preventDefault();
    const { selected } = this.state;
    this.props.onSave(selected.id);
  };

  render() {
    return (
      <Modal isVisible={this.props.show} className="email-layouts-modal">
        <form>
          <div className="modal-header w-100 ">
            <div className="header-title-breadcrums">
              <h3>Select a different layout</h3>
            </div>
            <div className="header-close-icon">
              <div>
                <img
                  className="cursor-pointer"
                  src="https://scholamatch-static.s3-us-west-2.amazonaws.com/close.svg"
                  width="16"
                  onClick={this.props.onCancel}
                />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="d-flex flex-wrap">
              {LAYOUTS.map((l, i) => {
                const { selected } = this.state;
                return (
                  <div className="layout-frame mb-3" key={`ls-${i}`}>
                    <div
                      className={`d-flex justify-content-center layout-icon ${selected?.id == l.id ? 'selected' : ''}`}
                      onClick={() => this.setState({ selected: l })}>
                      {selected?.id == l.id ? (
                        <i className="icon-check-layout text-blue fa fa-check-circle" aria-hidden="true" />
                      ) : null}
                      <img src={l.icon} style={{ maxWidth: '166px' }} />
                    </div>
                    <div className="layout-description">
                      <p>
                        <strong>{l.name}</strong>
                      </p>
                      <p>{l.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <div />
            <div className="d-flex flex-row justify-content-end align-items-center">
              <p className="font-500 font-14 mr-2 cursor-pointer" onClick={this.props.onCancel}>
                Cancel
              </p>
              <Button color="primary" className="m-2" onClick={this.onSave}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}
