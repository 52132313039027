import React, { useMemo, useState } from 'react';
import styles from './enrollment-progress.module.scss';
import { ModalDesktop } from '../modal-desktop/modal-desktop';
import { useToggle } from 'hooks/useToggle';
import { _getSchoolSeats, _updateSchoolSeats } from 'controllers/schools/schools_controller';
import { useSchoolSeats } from '../../hooks';
import { EditFilled, InfoCircleOutlined } from '@ant-design/icons';
import Button from 'components/button/v2/Button';
import ReactTooltip from 'react-tooltip';
import { _isSuperadmin } from 'util/auth';

export const EnrollmentProgress = ({ schoolId, isAdmin }) => {
  /**
   * current_enrollment from school endpoint or leads on accepted status?
   */
  const [isEditOpen, toggleEdition] = useToggle(false);

  const { isLoading, isSuccess, seats, countSeats, handleSeats, onUpdateSeats, toggleBlockASM } =
    useSchoolSeats(schoolId);

  const progress = useMemo(() => {
    if (countSeats.total === 0) return 0;

    return Math.ceil((countSeats.current * 100) / countSeats.total) || 0;
  }, [countSeats]);

  return (
    <div>
      <div className={styles.wrapper}>
        <p className={styles.percentage}>{isLoading ? '-' : `${progress}%`}</p>
        <span className={styles.chip}>
          {countSeats.current}/{countSeats.total} Enrolled Students
        </span>
        <button className={styles.edit} onClick={toggleEdition}>
          <EditFilled />
          Edit Goal
        </button>
      </div>
      <div className={styles.bar}>
        <div className={styles.progress} style={{ width: `${progress}%` }} />
      </div>
      <ModalDesktop title="Edit Goal" isVisible={isEditOpen} onClose={toggleEdition} className={styles.gradesContainer}>
        <ModalDesktop.Body className={styles.modalBody}>
          <p className="spacing-mb-24">Edit your enrollment goal by grade</p>
          <div className={styles.grade}>
            <span>Grade</span>
            <span>Current Students</span>
            <span>Total Seats</span>
            {isAdmin && (
              <div className={styles.asmLabel}>
                <span>Block ASM</span>
                <InfoCircleOutlined data-for="asm-tip" data-tip />
                <ReactTooltip
                  id="asm-tip"
                  place="top"
                  effect="solid"
                  backgroundColor="rgba(0, 31, 41, 0.9)"
                  className={styles.tooltip}>
                  If this box is marked, the school will not receive <b>AutoScholaMatch</b> leads for the corresponding
                  grade.
                </ReactTooltip>
              </div>
            )}
          </div>
          {isSuccess &&
            seats.map((seat) => (
              <div key={seat.grade} className={styles.grade}>
                <span>{seat.grade}</span>
                <div className={styles.currentInput}>
                  <GoalInput
                    id={seat.grade}
                    initialGoal={seat.currentBussy}
                    handleSeats={(e) => handleSeats(e, 'current')}
                    disabled={seat.total <= 0}
                  />
                  {seat.currentBussy > seat.total && (
                    <span className={styles.warning}>The total available should be greater than the current</span>
                  )}
                </div>
                <GoalInput id={seat.grade} initialGoal={seat.total} handleSeats={(e) => handleSeats(e, 'total')} />
                {isAdmin && (
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    name={`block_asm - ${seat.id}`}
                    id={`block_asm - ${seat.id}`}
                    value={seat.block_asm}
                    checked={seat.block_asm}
                    onChange={() => toggleBlockASM(seat.id)}
                  />
                )}
              </div>
            ))}
        </ModalDesktop.Body>
        <ModalDesktop.Footer>
          <Button
            fullWidth
            onClick={() => {
              onUpdateSeats();
              toggleEdition();
            }}>
            Save
          </Button>
        </ModalDesktop.Footer>
      </ModalDesktop>
    </div>
  );
};

const GoalInput = ({ id, initialGoal, handleSeats, disabled }) => {
  return <input id={id} type="number" name={id} value={initialGoal} onChange={handleSeats} disabled={disabled} />;
};
