export default {
  'any.required': 'This field is required',
  'any.empty': 'This field is required',
  'any.allowOnly': 'This field is required',
  'any.invalid': 'Invalid value',
  'string.uri': 'Must be a valid URL',
  'string.email': 'Must be a valid email',
  'string.max': 'This field must be less than or equal to 255 characters in length',
  'number.base': 'Must be a valid number',
};
