import React, { Component, Fragment } from 'react';
import {
  _updateMarketingCampaignStatus,
  _addMarketingCampaign,
  _renameMarketingCampaign,
  _changeTypeMarketingCampaign,
} from 'controllers/schools/schools_controller';
import CDPreview from './CDPreview';
import { LAYOUTS } from './Layouts';
import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';
import campaingConstants from '../../utils/CampaingConstants';
import { toCampaingTypeLabel } from '../../utils/campaign_utils';
import { getPathAdmin } from 'util/getPathAdmin';

export default class EmailRowAction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleMenu = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  onClickOutsideHandler = (event) => {
    if (this.toggleMenu.current && !this.toggleMenu.current.contains(event.target)) this.onHideMenu();
  };

  onHideMenu = () => this.setState({ showActionMenu: false });

  updateStatusTo = (status, campaign) => {
    const { school_id: schoolId } = this.props;
    const campaignId = campaign.id;

    this.setState({ loading: true }, () => {
      _updateMarketingCampaignStatus(schoolId, campaignId, status)
        .then(() => this.setState({ loading: false }))
        .then(() => this.props.onLoadInfo());
    });
  };

  onPause = (row) => this.updateStatusTo('Paused', row);

  onActive = (row) => this.updateStatusTo('Active', row);

  onArchive = (row) => this.updateStatusTo('Archived', row);

  onAction = (action) => {
    const { school_id: schoolId, router, row } = this.props;

    switch (action) {
      case 'edit':
        if (row.type === 'advanced') {
          // TODO open advanced campaign
        } else {
          router.replace(`/${getPathAdmin()}/schools/${schoolId}/campaigns/dashboard/${row.id}`);
        }

        break;
      case 'pause':
        this.onPause(row);
        break;
      case 'active':
        this.onActive(row);
        break;
      case 'archive':
        this.onArchive(row);
        break;
      case 'duplicate':
        this.setState({ showDuplicate: true, newName: `${row.name}- copy` });
        break;
      case 'rename':
        this.setState({ showRename: true, newName: row.name });
        break;
      case 'changeType':
        this.setState({ showChangeType: true, newType: row.type });
        break;
      default:
        break;
    }
  };

  renderChangeTypeCampaing = () => {
    const { showChangeType } = this.state;
    const { row } = this.props;

    const onChangeType = (e) => {
      e.preventDefault();

      const { school_id: schoolId, row } = this.props;
      const { newType } = this.state;

      _changeTypeMarketingCampaign(schoolId, row.id, newType).then(() =>
        this.setState({ showChangeType: false, newType: '' }, () => this.props.onLoadInfo())
      );
    };

    return (
      <Modal isVisible={showChangeType} className="duplicate-campaign-modal">
        <div className="modal-header w-100 ">
          <div className="header-title-breadcrums">
            <h3>
              Campaign - [{row.name}] - {toCampaingTypeLabel(row.platform, row.type)}
            </h3>
          </div>
          <div className="header-close-icon">
            <div>
              <img
                className="cursor-pointer"
                src="https://scholamatch-static.s3-us-west-2.amazonaws.com/close.svg"
                width="16"
                onClick={() => this.setState({ showChangeType: false })}
              />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="form-group">
            <label>Type</label>
            <select
              className="form-control"
              value={this.state.newType}
              onChange={(e) => this.setState({ newType: e.target.value })}>
              {campaingConstants.CAMPAIGN_TYPES.map((campaingType, i) => {
                return (
                  <option key={i} value={campaingType.type}>
                    {toCampaingTypeLabel(row.platform, campaingType.type)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" onClick={onChangeType} disabled={this.state.newType === row.type}>
            Change
          </Button>
        </div>
      </Modal>
    );
  };

  renderRename = () => {
    const { showRename } = this.state;
    const { row } = this.props;

    const onRename = (e) => {
      e.preventDefault();

      const { school_id: schoolId, row } = this.props;
      const { newName } = this.state;

      _renameMarketingCampaign(schoolId, row.id, newName).then(() =>
        this.setState({ showRename: false, newName: '' }, () => this.props.onLoadInfo())
      );
    };

    return (
      <Modal isVisible={showRename} className="duplicate-campaign-modal">
        <div className="modal-header w-100 ">
          <div className="header-title-breadcrums">
            <h3>Rename Campaign - [{row.name}]</h3>
          </div>
          <div className="header-close-icon">
            <div>
              <img
                className="cursor-pointer"
                src="https://scholamatch-static.s3-us-west-2.amazonaws.com/close.svg"
                width="16"
                onClick={() => this.setState({ showRename: false })}
              />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={this.state.newName}
              onChange={(e) => this.setState({ newName: e.target.value })}
            />
          </div>
          {this.state.newName === '' && (
            <div className="errors-alerts-onboarding">
              <span className="validation-message">Name is required</span>
            </div>
          )}
        </div>
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" onClick={onRename} disabled={this.state.newName === ''}>
            Rename
          </Button>
        </div>
      </Modal>
    );
  };

  renderDuplicate = () => {
    const { showDuplicate } = this.state;
    const { row } = this.props;
    const layout = LAYOUTS?.find((l) => l.id == row?.layout_id);

    const onDuplicate = (e) => {
      e.preventDefault();
      const { school_id: schoolId, row } = this.props;
      const { newName } = this.state;
      const params = {
        name: newName,
        platform: row.platform,
        type: row.type,
        layout_id: row.layout_id,
        marketing_template_id: row.marketing_template_id,
        params: row.params,
        audience_language: row.audience_language,
      };
      _addMarketingCampaign(schoolId, params).then(() =>
        this.setState({ showDuplicate: false, newName: '' }, () => this.props.onLoadInfo())
      );
    };

    return (
      <Modal isVisible={showDuplicate} className="duplicate-campaign-modal">
        <div className="modal-header w-100 ">
          <div className="header-title-breadcrums">
            <h3>Duplicate Campaign - [{row.name}]</h3>
          </div>
          <div className="header-close-icon">
            <div>
              <img
                className="cursor-pointer"
                src="https://scholamatch-static.s3-us-west-2.amazonaws.com/close.svg"
                width="16"
                onClick={() => this.setState({ showDuplicate: false })}
              />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={this.state.newName}
              onChange={(e) => this.setState({ newName: e.target.value })}
            />
          </div>
          {this.state.newName === '' && (
            <div className="errors-alerts-onboarding">
              <span className="validation-message">Name is required</span>
            </div>
          )}
        </div>
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" onClick={onDuplicate} disabled={this.state.newName === ''}>
            Duplicate
          </Button>
        </div>
      </Modal>
    );
  };

  render() {
    const { row } = this.props;

    return (
      <>
        <div style={{ position: 'relative' }}>
          <div className="d-flex align-items-center" ref={this.toggleMenu}>
            <img
              onClick={() => this.setState({ showActionMenu: true })}
              className="cursor-pointer ml-2"
              src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/icon-manage-school.svg"
              alt="schola"
            />
          </div>
          {this.state.showActionMenu && (
            <div className="actions-content-menu">
              <ul>
                <li className="item cursor-pointer" onClick={() => this.onAction('edit')}>
                  <img src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-edit-light.svg" />
                  Edit
                </li>
                {row.status == 'Active' && (
                  <li className="item cursor-pointer" onClick={() => this.onAction('pause')}>
                    <img src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-pause-light.svg" />
                    Pause
                  </li>
                )}
                {row.status == 'Paused' && (
                  <li className="item cursor-pointer" onClick={() => this.onAction('active')}>
                    <img src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-pause-light.svg" />
                    Activate
                  </li>
                )}
                <li className="item cursor-pointer" onClick={() => this.onAction('duplicate')}>
                  <i className="fa fa-copy" />
                  Duplicate
                </li>
                <li className="item cursor-pointer" onClick={() => this.onAction('rename')}>
                  <img src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-edit-light.svg" />
                  Rename
                </li>
                {row.type !== 'advanced' && (
                  <li className="item cursor-pointer" onClick={() => this.onAction('changeType')}>
                    <img src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-edit-light.svg" />
                    Change Type
                  </li>
                )}
                {/* <li className="item cursor-pointer">
                                <i className="fa fa-download"></i>
                                Download Analytics
                            </li> */}
                {row.status != 'Archived' && (
                  <>
                    <hr />
                    <li className="item cursor-pointer" onClick={() => this.onAction('archive')}>
                      <img src="https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/icon-lead-archive-gray.svg" />
                      Archive
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
        {this.renderDuplicate()}
        {this.renderRename()}
        {this.renderChangeTypeCampaing()}
      </>
    );
  }
}
