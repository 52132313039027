import { t } from 'components/translation/Translation';

export const check = 'check';

const base = 'https://scholamatch-static.s3.us-west-2.amazonaws.com/landing/schools';

const planNames = [
  { id: 'scholaBasics', name: 'ScholaBasics™', color: '#F5FAEB', logo: `${base}/ScholaBasics.svg` },
  { id: 'scholaPlus', name: 'ScholaPlus™', color: '#E6F5FA', logo: `${base}/ScholaPlus.svg` },
  { id: 'recruiterPro', name: 'ScholaRecruiter Pro™', color: '#E7F0F9', logo: `${base}/RecruiterPro.svg` },
];

const viewLeads = [
  t('home:plansTable.viewLeadsBasics'),
  t('home:plansTable.viewLeadsPlus'),
  t('home:plansTable.viewLeadsPro'),
];
const liveSearch = [null, t('home:plansTable.liveSearchPlus'), t('home:plansTable.liveSearchPro')];
const messagingList = [check, check, t('home:plansTable.messagingListPro')];
const campaingsList = [null, t('home:plansTable.campaingsListPlus'), t('home:plansTable.campaingsListPro')];
const videoList = [null, check, t('home:plansTable.videoListPro')];
const featuredList = [null, t('home:plansTable.featuredListPlus'), t('home:plansTable.featuredListPro')];
const tourList = [null, check, check];
const leadGenList = [null, 'ScholaNetwork™', t('home:plansTable.leadGenListPro')];

export const plans = {
  names: planNames,
  features: [
    { feature: t('home:plansTable.viewLeadsTitle'), list: viewLeads },
    { feature: t('home:plansTable.liveSearchTitle'), list: liveSearch },
    { feature: t('home:plansTable.messagingListTitle'), list: messagingList },
    { feature: t('home:plansTable.campaingsListTitle'), list: campaingsList },
    { feature: t('home:plansTable.videoListTitle'), list: videoList },
    { feature: t('home:plansTable.featuredListTitle'), list: featuredList },
    { feature: t('home:plansTable.tourListTitle'), list: tourList },
    { feature: t('home:plansTable.leadGenListTitle'), list: leadGenList },
  ],
};
