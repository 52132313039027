import React, { useEffect, useState } from 'react';
import { useToggle } from 'hooks/useToggle';
import { useDispatch } from 'react-redux';
import { getAutocompleteSchoolsV2 } from 'redux/school/school-actions';
import { Buttonv2 as Button } from 'schola-components';
import { Location16 } from '@carbon/icons-react';
import { t } from 'components/translation/Translation';
import styles from '../waitlist-form.module.scss';
import HtmlParser from 'html-react-parser';

export const SchoolSearcher = ({ handleValue, handleNewSchool }) => {
  const dispatch = useDispatch();

  const [schoolName, setSchoolName] = useState('');
  const [haveSelectedSchool, setHaveSelectedSchool] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);
  const [isListOpen, toggleList] = useToggle(false);

  const onChangeText = async (event) => {
    const { value } = event.target;

    const query = value.replace(/[&\/\\#,+()$~%.'":*!?<>{}]/g, '');

    setSchoolName(query);
    handleValue('school_name', query);

    if (!query.length) return setResultSearch([]);

    const res = await dispatch(getAutocompleteSchoolsV2({ name: query }));
    setResultSearch(res.json);
  };

  const onSelectSchool = (school) => {
    setHaveSelectedSchool();
    setSchoolName(school.name);

    handleValue('school_id', school.id);
    toggleList();
  };

  useEffect(() => {
    handleValue('school_name', schoolName);
  }, [schoolName]);

  const handleAddNewSchool = () => {
    handleNewSchool();
    toggleList();
  };

  const highlightItems = (match) => {
    if (match) {
      const regex = new RegExp(schoolName, 'gi');
      return match.replace(regex, '<strong>$&</strong>');
    }
  };

  return (
    <div className={`${styles.searchSchool}`}>
      <label htmlFor="school" className={styles.inputSearch}>
        <legend className={styles.inputSearch_label}>{t('home:waitlist.schoolName')}</legend>
        <input
          id="school"
          name="school"
          value={schoolName}
          onChange={onChangeText}
          onFocus={() => {
            !isListOpen && toggleList();
          }}
          onBlur={() => {
            haveSelectedSchool ? toggleList() : null;
          }}
        />
      </label>

      {isListOpen && (
        <div className={styles.dropContainer}>
          {schoolName.length > 3 && resultSearch.length > 0 && (
            <ul className={styles.listSearch}>
              {resultSearch.map((school) => (
                <li
                  key={school.id}
                  tabIndex="0"
                  onClick={() => onSelectSchool(school)}
                  onKeyDown={(e) => e.key === 'Enter' && onSelectSchool(school)}>
                  <div className={styles.schoolItem}>
                    <Location16 />
                    <div>
                      <p className="body-regular">{HtmlParser(highlightItems(school.name))}</p>
                      <span className="body-small">
                        {school.address}, {school.city}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {schoolName.length > 3 && resultSearch.length === 0 && (
            <div className={styles.notFound}>
              <img
                src="https://scholamatch-static.s3.us-west-2.amazonaws.com/banners/oops-illustration-schola.png"
                alt="Not School Found"
              />
              <h5>{t('home:waitlist.notFoundSchool')}</h5>
              <p className="body-small">{t('home:waitlist.notFoundDescription')}</p>
              <Button color="secondary" fullWidth onClick={handleAddNewSchool}>
                {t('home:waitlist.addSchool')} {schoolName}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
