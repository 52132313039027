const { allowedZipcodes } = require('./allowedZipcodes');

export const isInAreaZip = (zipcode, callback) => {
  const url = 'https://pages.schola.com/meetings/schola-solutions/schedule-demo';

  if (allowedZipcodes.includes(zipcode)) {
    window.open(url, '_blank').focus();
  } else {
    callback();
  }
};
