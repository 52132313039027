import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './button.scss';

const Button = ({
  className,
  children,
  color,
  disabled,
  fullWidth,
  fullWidthMobile,
  icon,
  onClick,
  tabIndex,
  size,
  theme,
  type,
}) => {
  const buttonClasses = {
    [`button-${size}`]: { size },
    [`button-${theme}-${color}`]: { color, theme },
    [`${className}`]: className,
    'button--disabled': disabled,
    'button--display--block-mobile': fullWidthMobile,
    'button--display--block': fullWidth,
    'button-icon': icon,
  };

  return (
    <button type={type} className={classNames(buttonClasses)} disabled={disabled} onClick={onClick} tabIndex={tabIndex}>
      {icon ? <span className="children-icon">{children}</span> : <span className="children">{children}</span>}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'ghost-primary',
    'ghost-secondary',
    'bluelegacy',
    'greenlegacy',
    'redlegacy',
    'red',
    'purple',
    'orange',
  ]),
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  tabIndex: PropTypes.number,
  theme: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  children: 'Label',
  color: 'primary',
  disabled: false,
  fullWidth: false,
  fullWidthMobile: false,
  icon: null,
  onClick: null,
  size: 'medium',
  type: 'submit',
  theme: 'contained',
};

export default Button;
