import client from '../client';
import { getResponseValue } from 'util/validate';

export const _getSchoolScheduleTourById = async (schoolId, tourId) => {
  return await client.get(`schools/${schoolId}/scheduletour/${tourId}`, {}, { credentials: 'omit' });
};

export const _updateSchoolTourLead = async (schoolId, tourId, payload) => {
  return await client.post(`schools/${schoolId}/scheduletour/${tourId}/lead`, payload, { credentials: 'omit' });
};

export const _getSchoolById = async (schoolId) => {
  return await client.get(`schools/${schoolId}`, {}, { credentials: 'omit' });
};

export const _getSchools = async (query) => {
  return await client.get('schools', query, { credentials: 'omit' });
};

export const _getSchoolSeats = async (schoolId) => {
  return await client.get(`schools/${schoolId}/seats`, {}, { credentials: 'omit' });
};

export const _updateSchoolSeats = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/seats/update`, payload, { credentials: 'omit' });
};

export const _updateSchoolData = async (schoolId, payload) => {
  return await client.patch(`schools/${schoolId}/updateData`, payload, { credentials: 'omit' });
};

export const _processACHTransaction = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/ach`, payload, { credentials: 'omit' });
};

export const _buyScholaBoostsACH = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/buy-scholaboosts/ach`, payload, { credentials: 'omit' });
};

export const _buyScholaBoostsExistMethod = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/buy-scholaboosts/exist-method`, payload, { credentials: 'omit' });
};

export const _getPlaidLinkToken = async (schoolId) => {
  return await client.get(`schools/${schoolId}/ach/token-link`, {}, { credentials: 'omit' });
};

export const _updateAvgPerPupil = async (schoolId, avgPerPupil) => {
  return await client.post(
    `schools/${schoolId}/avg-per-pupil`,
    { avg_per_pupil: avgPerPupil },
    { credentials: 'omit' }
  );
};

export const _getCampaignReport = async (schoolId, caseId) => {
  return await client.get(`schools/${schoolId}/campaign-report/${caseId}`, {}, { credentials: 'omit' });
};

export const _getAppReceivedCountBySchoolId = async (schoolId, startDate, endDate) => {
  return await client.get(
    `schools/${schoolId}/app-received-count`,
    { start_date: startDate, end_date: endDate },
    { credentials: 'omit' }
  );
};

export const _updateCampaignReport = async (schoolId, reportId, values) => {
  return await client.patch(`schools/${schoolId}/campaign-report/${reportId}`, { ...values }, { credentials: 'omit' });
};

export const _getCampaignReportMedia = async (schoolId) => {
  return await client.get(`schools/${schoolId}/campaign-report/media`, {}, { credentials: 'omit' });
};

export const _addCampaignReportMedia = async (schoolId, values) => {
  var payload = new FormData();
  payload.append('type', values.type);
  payload.append('school_id', schoolId);
  payload.append('file', values.file);
  return await client.post(`schools/${schoolId}/campaign-report/media`, payload, { credentials: 'omit' });
};

export const _deleteAdExampleImage = async (schoolId, mediaId) => {
  return await client.delete(`schools/${schoolId}/campaign-report/media/${mediaId}`, {}, { credentials: 'omit' });
};

export const _buyScholaBoosts = async (schoolId, data) => {
  return await client.post(`schools/${schoolId}/buy-scholaboosts`, data, { credentials: 'omit' });
};

export const _getSchoolUserNotifications = async (schoolId, userId) => {
  return await client.get(`schools/${schoolId}/users/${userId}/notifications`, {}, { credentials: 'omit' });
};

export const _updateSchoolUserNotifications = async (schoolId, userId, values) => {
  return await client.patch(`schools/${schoolId}/users/${userId}/notifications`, values, { credentials: 'omit' });
};

export const _deleteSchool = async (schoolId) => {
  return await client.delete(`schools/${schoolId}`, {}, { credentials: 'omit' });
};

export const _getMarketingTemplates = async () => {
  const response = await client.get(`marketing/templates`, {}, { credentials: 'omit' });
  return await getResponseValue(response);
};

export const _addMarketingCampaign = async (schoolId, payload) => {
  const response = await client.post(`school/${schoolId}/marketing/campaign`, payload, { credentials: 'omit' });
  return await getResponseValue(response);
};

export const _sendTestMarketingCampaign = async (schoolId, payload) => {
  const response = await client.post(`school/${schoolId}/marketing/campaign/send-test`, payload, {
    credentials: 'omit',
  });
  return await getResponseValue(response);
};

export const _getMarketingCampaign = async (schoolId, campaingId) => {
  const res = await client.get(`school/${schoolId}/marketing/campaign/${campaingId}`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _updateMarketingCampaignStatus = async (schoolId, campaingId, status) => {
  const res = await client.post(
    `school/${schoolId}/marketing/campaign/${campaingId}/set-status`,
    { status },
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};

export const _renameMarketingCampaign = async (schoolId, campaingId, name) => {
  const res = await client.post(
    `school/${schoolId}/marketing/campaign/${campaingId}/rename`,
    { name },
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};

export const _changeTypeMarketingCampaign = async (schoolid, campaingId, type) => {
  const res = await client.post(
    `school/${schoolid}/marketing/campaign/${campaingId}/change-type`,
    { type },
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};

export const _getMarketingCampaigns = async (payload) => {
  const { school_id: schoolId } = payload;
  delete payload.school_id;

  // let query = Object.keys(payload)
  //   .map((k) => {
  //     let value = payload[k];
  //     if (value instanceof Date) value = value.toISOString();
  //     if (k === 'filters') {
  //       console.log(value);
  //       return `${k}=[]`;
  //     }
  //     return `${k}=${value}`;
  //   })
  //   .join('&');

  // const res = await client.get(`school/${schoolId}/marketing/campaigns?${query}`, {}, { credentials: 'omit' });
  const res = await client.post(`school/${schoolId}/marketing/campaigns`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getScholaMarketingCampaigns = async (schoolId, page, pageSize) => {
  const res = await client.get(
    `school/${schoolId}/marketing/schola/campaigns?page=${page}&pageSize=${pageSize}`,
    {},
    {
      credentials: 'omit',
    }
  );
  return await getResponseValue(res);
};

export const _getMarketingTableCounts = async (schoolId, params) => {
  let filtering = params.filtering ? JSON.stringify(params.filtering) : '[]';
  const res = await client.get(
    `school/${schoolId}/marketing/tables/count?ids=${params.tab_ids}&filtering=${filtering}`,
    null,
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};

export const _uploadSchoolEmailCampaingImage = async (schoolId, campaignId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await client.post(`school/${schoolId}/marketing/email/campaign/${campaignId}/image`, formData, {
    credentials: 'omit',
  });
  return await getResponseValue(res);
};

export const _uploadSchoolEmailCampaingImageV2 = async (schoolId, campaignId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);
  formData.append('original', file.original);
  const res = await client.post(`school/${schoolId}/marketing/email/campaign/${campaignId}/image/upload`, formData, {
    credentials: 'omit',
  });
  return await getResponseValue(res);
};

export const _getGlobalCampaignReport = async () => {
  const res = await client.get(`admin/campaign/global-report`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _marketingCampaignTest = async () => {
  const res = await client.post(`marketing/campaign/test`, {}, { credentials: 'omit' });
  return res;
};

export const _getSchoolLeaders = async (schoolId) => {
  const res = await client.get(`schools/${schoolId}/leaders`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getSchoolUsers = async (id, onlyActive = true) => {
  const res = await client.get(`schools/${id}/users`, { onlyActive }, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _createSchoolUser = async (id, payload) => {
  const res = await client.post(`schools/${id}/users`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _deleteSchoolUser = async (schoolId, userId) => {
  const res = await client.delete(`schools/${schoolId}/users/${userId}`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _resendEmailSchoolUser = async (schoolId, email, user_id) => {
  return await client.post(`schools/${schoolId}/resendEmails`, { email, user_id });
};

export const _getSchoolDeparments = async (schoolId) => {
  const res = await client.get(`schools/${schoolId}/users/deparments`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _addSchoolDeparment = async (schoolId, name) => {
  const res = await client.post(`schools/${schoolId}/users/deparments`, { name }, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _updateUserSchoolStatus = async (schoolId, userId, status) => {
  const res = await client.post(
    `schools/${schoolId}/users/${userId}/update-status`,
    { status },
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};

export const _getApplicationUrlForTest = async (schoolId) => {
  const response = await client.post(
    `school/${schoolId}/marketing/campaign/get-application-url-for-test`,
    {},
    {
      credentials: 'omit',
    }
  );
  return await getResponseValue(response);
};

export const _getSchoolTours = async (schoolId, page = 1, pageSize = 10, includeArchived = 0) => {
  const res = await client.get(
    `schools/${schoolId}/scheduletour`,
    { page, pageSize, includeArchived },
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};

export const _getIntegrationSchools = async (schoolId) => {
  return await client.get(`schools/integration/${schoolId}`, {}, { credentials: 'omit' });
};
