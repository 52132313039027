import { useMemo } from 'react';

export const useProfileRate = (school, reviews) => {
  const requiredFields = [
    'name',
    'address',
    'city',
    'description',
    'grades',
    'headerImage',
    'hours',
    'images',
    'logo_image',
    'mission',
    'vision',
    'phone',
    'seo_description',
    'seo_title',
    'state',
    'states_served',
    'type',
    'zip',
    'tuition',
    'videos',
    'website',
    'number_of_students',
    'number_of_teachers',
    'enrollment_goal',
  ];

  const socialLinks = [
    'social_facebook',
    'social_google',
    'social_instagram',
    'social_linkedin',
    'social_snapchat',
    'social_twitter',
    'social_tiktok',
    'social_youtube',
  ];

  const progressProfile = useMemo(() => {
    if (school && reviews) {
      const fields = Object.entries(school)
        .map(([key, value]) => ({ key, value }))
        .filter((item) => requiredFields.includes(item.key));

      const socialRequired = [];
      socialLinks.map((link) => socialRequired.push(school[link]));

      const completion = getCompletedRateProfile({
        schoolValues: fields,
        socialLinks: socialRequired,
        tours: school.tour_times ? school.tour_times[0] : null,
        reviews,
      });

      return completion;
    }
  }, [school, reviews]);

  return progressProfile;
};

const getCompletedRateProfile = ({ schoolValues, socialLinks = [], tours, reviews }) => {
  let completed = 0;
  let required = schoolValues.length;

  const addRequiredKey = () => {
    required++;
    completed++;
  };

  /** If at least one value from socialLinks is valid will add to the completed and required count */
  if (socialLinks.some((link) => Boolean(link))) {
    addRequiredKey();
  }

  /** If at least one value from tourAvailable is valid will add to the completed and required count */
  if (tours) {
    const tourAvailable = tours.week_availability.split('').some((day) => day === '1');
    if (tourAvailable) {
      addRequiredKey();
    }
  }

  if (reviews) {
    const sitesAccepted = ['Facebook', 'Google', 'Great Schools'];
    const reviewsAccepted = reviews
      .filter((review) => sitesAccepted.includes(review.site))
      .some((review) => review.url);

    if (reviewsAccepted) {
      addRequiredKey();
    }
  }

  const missingValues = [];

  /** Each valid value from school object that is required will add to the completed count */
  schoolValues.forEach((item) => {
    if (item.value !== null && hasValue(item.value)) {
      return completed++;
    }
    missingValues.push(item.key);
  });

  return Math.ceil((completed * 100) / required);
};

const hasValue = (value) => {
  if (typeof value === 'string') return value.trim() !== '';
  if (typeof value === 'number') return value > 0;
  if (typeof value === 'boolean') return true;
  if (Array.isArray(value)) return value.length > 0;

  return value;
};
