import React, { useState } from 'react';
import { planName } from 'util/schoolUtils';
import ModalUpgradeRequired from 'components/modal-upgrade-plan/ModalUpgradeRequired';
import Button from 'components/button/v2/Button';

export const VALID_PLANS = ['recruiter', 'recruiterpro', 'recruiterpro-legacy', 'plus'];

const ICON =
  'https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-arrow-up-blue.svg';

const UpgradeScholaPlanLink = ({ school, reported, onUpgradeRequired }) => {
  const [stateReported, setStateReported] = useState(reported);

  let requiredUpgrade = !VALID_PLANS.includes(planName(school));

  if (!stateReported && school) {
    setTimeout(() => {
      setStateReported(true);
      onUpgradeRequired(requiredUpgrade);
    }, 50);
  }
  const [showModal, setShowModal] = useState(false);
  const showModalUpgradeModal = () => setShowModal(!showModal);

  return requiredUpgrade ? (
    <>
      <div className="text-primary-blue mt-2 d-flex align-items-center">
        <Button
          color="bluelegacy"
          theme="transparent"
          fontWeight="bold"
          type="button"
          className="px-0"
          onClick={showModalUpgradeModal}>
          <img className="mr-2" src={ICON} />
          Upgrade
        </Button>
      </div>
      <ModalUpgradeRequired
        school={school}
        isVisible={showModal}
        setShowModal={setShowModal}
        planToUpgrade="recruiter"
      />
    </>
  ) : null;
};
export default UpgradeScholaPlanLink;
