import { useState } from 'react';
import { MailOutlined, AppstoreOutlined, MessageOutlined } from '@ant-design/icons';
import { _addMarketingCampaign } from 'controllers/schools/schools_controller';
import { useStatus } from 'hooks/useStatus';
import { browserHistory } from 'react-router';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';

export const STAGES = {
  MODE: 'mode',
  PLATFORM: 'platform',
  TEMPLATE: 'template',
};
const MODES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced',
};
const CAMPAIGN_PLATFORM = {
  EMAIL: 'email',
  SMS: 'sms',
  HYBRID: 'hybrid',
};

export const useCampaignSettings = ({ schoolId, defaultParams, templates }) => {
  const [stage, setStage] = useState(STAGES.MODE);

  const [settings, setSettings] = useState({
    mode: null,
    platform: null,
    template: {},
  });

  const { status, onLoading, onSuccess, onError } = useStatus();

  const backToMode = () => {
    const backToStage = stage === STAGES.TEMPLATE ? STAGES.PLATFORM : STAGES.MODE;
    setStage(backToStage);
  };

  const handleMode = (mode) => {
    setStage(STAGES.PLATFORM);
    setSettings({ ...settings, mode });
  };

  const handleType = async (platform) => {
    setSettings({ ...settings, platform });

    if (settings.mode === MODES.SIMPLE) {
      setStage(STAGES.TEMPLATE);
    } else {
      // payload for ADVANCED campaigns
      const defaultTemplate = templates[0]; // take template id = 1 as default for advanced campaigns
      const campaignPayload = {
        name: `Untitled Advanced-${platform} Campaign`,
        platform,
        type: campaingConstants.CAMPAIGN_TYPE_ADVANCED,
        audience_language: 'all',
        layout_id: defaultTemplate.id, // default value
        params: [
          { key: 'edges', value: JSON.stringify([]) },
          { key: 'viewport', value: JSON.stringify({ x: -175, y: 126.75, zoom: 1.5 }) },
          // each nodes contain a list of params
          {
            key: 'nodes',
            value: JSON.stringify([
              {
                id: 'start',
                type: platform === 'sms' ? 'smsNode' : 'emailNode',
                position: { x: 250, y: 100 },
                isFirstNode: true,
                data: [{ key: '{{subject}}', value: 'Welcome to Our School!' }, ...defaultParams],
                // data: defaultTemplate.params || [],
              },
            ]),
          },
        ],
      };

      await createAndNavigate(schoolId, campaignPayload, `communication-hub/advanced`);
    }
  };

  const onTemplateSelect = async (template) => {
    setSettings({ ...settings, template });

    // payload for simple/legacy campaigns
    const campaignPayload = {
      name: `Untitled Simple ${settings.platform} Campaign`,
      platform: settings.platform,
      type: 'Regular Mass',
      layout_id: template.layout_id, // only for simple type
      marketing_template_id: template.id, // only for simple type
      audience_language: 'all',
      params: template.params || [],
    };

    await createAndNavigate(schoolId, campaignPayload, `campaigns/dashboard`);
  };

  const titles = {
    [STAGES.MODE]: 'Choose Campaign Mode',
    [STAGES.PLATFORM]: `Choose ${settings.mode} Campaign Type`,
    [STAGES.TEMPLATE]: `Choose ${settings.platform} Template`,
  };

  const optionsModes = [
    {
      id: 'simple',
      onClick: () => handleMode(MODES.SIMPLE),
      label: 'Simple',
      icon: MailOutlined,
      description: 'Basic single-channel campaigns for straightforward communication',
      colorIcon: 'blue',
    },
    {
      id: 'advanced',
      onClick: () => handleMode(MODES.ADVANCED),
      label: 'Advanced',
      icon: AppstoreOutlined,
      description: 'Design multi-channel campaigns with advanced conditions and automated workflows',
      colorIcon: 'purple',
    },
  ];

  const optionsSimple = [
    {
      id: 'email',
      onClick: () => handleType(CAMPAIGN_PLATFORM.EMAIL),
      label: 'Email',
      icon: MailOutlined,
      description: 'Send a single email campaign',
      colorIcon: 'blue',
    },
    {
      id: 'sms',
      onClick: () => handleType(CAMPAIGN_PLATFORM.SMS),
      label: 'SMS',
      icon: MessageOutlined,
      description: 'Send a single SMS campaign',
      colorIcon: 'green',
    },
  ];

  const optionsAdvanced = [
    {
      id: 'email',
      onClick: () => handleType(CAMPAIGN_PLATFORM.EMAIL),
      label: 'Email',
      icon: MailOutlined,
      description: 'Advanced email workflows with automation and conditions',
      colorIcon: 'blue',
    },
    {
      id: 'sms',
      onClick: () => handleType(CAMPAIGN_PLATFORM.SMS),
      label: 'SMS',
      icon: MessageOutlined,
      description: 'Complex sequences with branching logic',
      colorIcon: 'green',
    },
    {
      id: 'hybrid',
      onClick: () => handleType(CAMPAIGN_PLATFORM.HYBRID),
      label: 'Hybrid',
      icon: AppstoreOutlined,
      description: 'Multi-channel campaigns combining emails and SMS',
      colorIcon: 'purple',
    },
  ];

  return {
    stage,
    settings,
    backToMode,
    onTemplateSelect,
    // options:
    optionsModes,
    optionsSimple,
    optionsAdvanced,
    titles,
  };
};

const createAndNavigate = async (schoolId, campaignPayload, basePath) => {
  const response = await createCampaign(schoolId, campaignPayload);
  if (response.id) {
    browserHistory.push(`/admin/v2/schools/${schoolId}/${basePath}/${response.id}`);
  } else {
    console.log('createAndNavigate error');
  }
};

const createCampaign = async (schoolId, newCampaignParams) => {
  try {
    let newCampaign = await _addMarketingCampaign(schoolId, newCampaignParams);
    console.log('new', newCampaign);
    if (newCampaign) {
      return newCampaign;
    }
  } catch (error) {
    console.log(error);
  }
};
