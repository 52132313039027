import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Close16, ArrowLeft16 } from '@carbon/icons-react';
import classNames from 'classnames';
import './fullscreen-modal.scss';

export const FullScreenModal = ({ onClose, title, children, typeButton = 'close' }) => {
  const closeClasses = {
    buttonIcon: true,
    close: typeButton === 'close',
    back: typeButton === 'back',
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const icons = {
    close: <Close16 />,
    back: <ArrowLeft16 />,
  };

  return (
    <div className="modal_mobile">
      <div className="modal_mobile_header">
        <button onClick={onClose} aria-label="exit" className={classNames(closeClasses)}>
          {icons[typeButton]}
        </button>

        {title && <h3 className="modal_mobile_header_title">{title}</h3>}
      </div>
      <div data-testid="children" className="modal_mobile_children">
        {children}
      </div>
    </div>
  );
};

FullScreenModal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
};
