import React from 'react';

const Add = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 32 32">
      <path d="M32 18.2857143H18.2857143V32h-4.5714286V18.2857143H0v-4.5714286h13.7142857V0h4.5714286v13.7142857H32" />
    </svg>
  );
  /* eslint-enable */
};

export default Add;
