import React from 'react';

const Trash = ({ color }) => {
  const [colorIcon, setColorIcon] = React.useState(color);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="auto">
      <path
        fill={colorIcon}
        d="M257.5,445.1l-22.2,22.2c-9.4,9.4-24.6,9.4-33.9,0L7,273c-9.4-9.4-9.4-24.6,0-33.9L201.4,44.7
                c9.4-9.4,24.6-9.4,33.9,0l22.2,22.2c9.5,9.5,9.3,25-0.4,34.3L136.6,216H424c13.3,0,24,10.7,24,24v32c0,13.3-10.7,24-24,24H136.6
                l120.5,114.8C266.9,420.1,267.1,435.6,257.5,445.1L257.5,445.1z"
      />
    </svg>
  );
};

export default Trash;
