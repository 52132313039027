import React from 'react';
import { compose, createStore, applyMiddleware } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import { createDevTools } from 'redux-devtools';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';
import apiRequestMiddleware from 'redux/middlewares/api-request';
import reducers from 'redux/reducers.js';

export const DevTools = createDevTools(
  <DockMonitor toggleVisibilityKey="ctrl-h" changePositionKey="ctrl-q" defaultIsVisible={false}>
    <LogMonitor theme="tomorrow" />
  </DockMonitor>
);

let storeEnhancer = compose(responsiveStoreEnhancer, applyMiddleware(apiRequestMiddleware, thunk));

if (process.env.REACT_APP_ENV === 'development') {
  storeEnhancer = compose(storeEnhancer, DevTools.instrument());
}

export const store = storeEnhancer(createStore)(reducers);
