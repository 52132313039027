import React, { Component, Suspense } from 'react';
import { applyRouterMiddleware, browserHistory, Route, IndexRedirect, IndexRoute, Router } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { store } from 'redux/store.js';
import { isSuperadmin } from 'util/auth';

import Loading from './pages/landing-pages-page/Loading';
import Modal from './modal/Modal';

import OnboardingProvider from './pages/onboarding/context/onboardingContext';
import { DashboardSchool } from './pages/dashboard-school/dashboard-school';
import { CommunicationHub } from './pages/communication-hub';
import { AdvancedCampaigns, HistoricalCampaigns, FlowDesigner } from './pages/communication-hub/views';

const AccountSettingsPage = React.lazy(() => import('components/pages/account-settings/AccountSettingsPage'));
const AddSchoolPage = React.lazy(() => import('components/pages/add-school-page/AddSchoolPage'));
const AdminEditSchoolPage = React.lazy(() => import('components/pages/admin-edit-school-page/AdminEditSchoolPage'));
const SubscrptionManagerPage = React.lazy(() =>
  import('components/pages/subscription-manager/SubscriptionManagerPage')
);
const CampaignReport = React.lazy(() => import('components/pages/campaigns/reports/campaigns-report'));
const Campaigns = React.lazy(() => import('components/pages/campaigns/Campaigns'));
const CampaignDashboard = React.lazy(() => import('components/pages/campaigns/components/CampaignDashboard'));
const EditOrCreateLandingPagePage = React.lazy(() =>
  import('components/pages/edit-or-create-landing-page-page/EditOrCreateLandingPagePage')
);
const LandingPagesPage = React.lazy(() => import('components/pages/landing-pages-page/LandingPagesPage'));
const LoginPage = React.lazy(() => import('components/pages/login-page/LoginPage'));
const LiveSearchV2 = React.lazy(() => import('components/pages/live-search/V2/LiveSearch'));
const Messages = React.lazy(() => import('components/pages/messages/Messages'));
const NotFoundPage = React.lazy(() => import('components/pages/NotFoundPage'));
const Health = React.lazy(() => import('components/pages/Health'));
const NotificationCenter = React.lazy(() => import('components/pages/notification-center/PersonalSettings'));
const Parents = React.lazy(() => import('components/pages/students-parents/Parents'));
const RelationshipManager = React.lazy(() => import('components/pages/relationship-manager/RelationshipManager'));
const SchoolApplicationPage = React.lazy(() =>
  import('components/pages/school-application-page/SchoolApplicationPage')
);
const SchoolBillingPage = React.lazy(() => import('components/pages/school-billing-page/SchoolBillingPage'));
const SchoolFeaturesPage = React.lazy(() => import('components/pages/school-features-page/SchoolFeaturesPage'));
const SchoolImportPage = React.lazy(() => import('components/pages/school-import-page/SchoolImportPage'));
const SchoolLayout = React.lazy(() => import('components/layouts/SchoolLayout'));
const SchoolToursPage = React.lazy(() => import('components/pages/school-tours-page/SchoolToursPage'));
const SchoolListPage = React.lazy(() => import('components/pages/school-list-page/SchoolListPage'));
const SchoolProfilePage = React.lazy(() => import('components/pages/school-profile-page/SchoolProfilePage'));
const SchoolUsersPage = React.lazy(() => import('components/pages/school-users-page/SchoolUsersPage'));
const UserManagement = React.lazy(() => import('components/pages/school-users-page/v2/UserManagement'));
const SingleSchoolSidebar = React.lazy(() => import('components/sidebar/SingleSchoolSidebar'));
const SpendsRecords = React.lazy(() => import('components/pages/spends-records/SpendsRecords'));
const StudentParent = React.lazy(() => import('components/pages/students-parents/SudentParent'));
// const StyleGuide = React.lazy(() => import('components/pages/styleguide/StyleGuide'))
const ViewApplicationPage = React.lazy(() => import('components/pages/view-application-page/ViewApplicationPage'));
const ViewApplicationSidebar = React.lazy(() => import('components/sidebar/ViewApplicationSidebar'));
const HomeLayout = React.lazy(() => import('components/layouts/HomeLayout'));
const LandingPage = React.lazy(() => import('components/pages/landing-page/landing-page'));
const ContentLayout = React.lazy(() => import('components/layouts/ContentLayout'));
// const ClaimSchoolPagev2 = React.lazy(() => import('components/pages/claim-school/v2/ClaimSchoolPagev2'))
const ClaimSchoolPagev3 = React.lazy(() => import('components/pages/claim-school/v2/ClaimSchoolPagev3'));
const ClaimFormEmbedded = React.lazy(() => import('components/pages/claim-school/v2/ClaimFormEmbedded'));
const AboutCampaignEstimatorPage = React.lazy(() =>
  import('components/pages/about-campaign-estimator-page/AboutCampaignEstimatorPage')
);
const SchoolCampaignEstimatorPage = React.lazy(() =>
  import('components/pages/school-campaign-estimator-page/SchoolCampaignEstimatorPage')
);
const EditApplicationPage = React.lazy(() => import('components/pages/edit-application-page/EditApplicationPage'));
const WhitelabelLayout = React.lazy(() => import('components/pages/edit-application-page/WhitelabelLayout'));
const Terms = React.lazy(() => import('components/pages/Terms'));
const PrivacyPolicy = React.lazy(() => import('components/pages/PrivacyPolicy'));
const PricingPage = React.lazy(() => import('components/pages/pricing/PricingPage'));
const PricingPremiumPage = React.lazy(() => import('components/pages/pricing/PricingPremiumPage'));
const PricingSuperAdminPage = React.lazy(() => import('components/pages/pricing/PricingSuperAdminPage'));
const AuthenticatedLayoutv2 = React.lazy(() => import('components/layouts/AuthenticatedLayoutv2'));
const AuthenticatedLayoutv3 = React.lazy(() => import('components/layouts/AuthenticatedLayoutv3'));
const Dashboardnew = React.lazy(() => import('components/pages/dashboard/v2/Dashboard'));
const Dashboardv3 = React.lazy(() => import('components/pages/dashboard/v3/Dashboard'));
const Dashboardv4 = React.lazy(() => import('components/pages/dashboard/v4/Dashboard'));
const DashboardBilling = React.lazy(() => import('components/pages/dashboard/v2/billing'));
const Onboarding = React.lazy(() => import('components/pages/onboarding/onboarding'));
const LetsStart = React.lazy(() => import('components/pages/onboarding/v2/components/screens/LetsStart'));
const WhatsTheMision = React.lazy(() => import('components/pages/onboarding/v2/components/screens/WhatsTheMision'));
const WhatLearningModels = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/WhatLearningModels')
);
const BriefllyDescribeSchoolUnique = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/BriefllyDescribeSchoolUnique')
);
const HowAreYouBuildingRelations = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/HowAreYouBuildingRelations')
);
const WeAreAlmostThere = React.lazy(() => import('components/pages/onboarding/v2/components/screens/WeAreAlmostThere'));
const AddYourSocialMedia = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/AddYourSocialMedia')
);
const AddPriorityTheGrades = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/AddPriorityTheGrades')
);
const CurrentEnrollment = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/CurrentEnrollment')
);
const GoalsEnrollment = React.lazy(() => import('components/pages/onboarding/v2/components/screens/GoalsEnrollment'));
const ApplicationDeadlineDate = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/ApplicationDeadlineDate')
);
const ZipcodesTarget = React.lazy(() => import('components/pages/onboarding/v2/components/screens/ZipcodesTarget'));
const ZipcodesTransport = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/ZipcodesTransport')
);
const OfferTransport = React.lazy(() => import('components/pages/onboarding/v2/components/screens/OfferTransport'));
const EnrollmentSteps = React.lazy(() => import('components/pages/onboarding/v2/components/screens/EnrollmentSteps'));
const EnrollmentProcessDigitized = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/EnrollmentProcessDigitized')
);
const CurrentlyEnrollmentSystem = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/CurrentlyEnrollmentSystem')
);
const FeaturingVideos = React.lazy(() => import('components/pages/onboarding/v2/components/screens/FeaturingVideos'));
const ScheduleCalendly = React.lazy(() => import('components/pages/onboarding/v2/components/screens/ScheduleCalendly'));
const UploadAdds = React.lazy(() => import('components/pages/onboarding/v2/components/screens/UploadAdds'));
const UploadSchoolLogo = React.lazy(() => import('components/pages/onboarding/v2/components/screens/UploadSchoolLogo'));
const UploadEnrollmentFiles = React.lazy(() =>
  import('components/pages/onboarding/v2/components/screens/UploadEnrollmentFiles')
);
const QuestionarieAboutYourSchool = React.lazy(() => import('components/pages/questionnaires/about-your-school'));
const QuestionarieEnrollment = React.lazy(() => import('components/pages/questionnaires/enrollment'));
const MarketingMaterials = React.lazy(() => import('components/pages/questionnaires/marketing-materials'));
const SocialMedia = React.lazy(() => import('components/pages/questionnaires/social-media'));
const ToursEvents = React.lazy(() => import('components/pages/questionnaires/tours-events'));
const RetoolPage = React.lazy(() => import('components/pages/retool-page/RetoolPage'));
const Recruitment = React.lazy(() => import('components/pages/recruitment-questionnaire/Recruitment'));
const ManagerUser = React.lazy(() => import('components/pages/manager-user/Manager'));
const ParentPortalBoardName = React.lazy(() => import('components/pages/manager-user/ParentPortalBoardName'));
const SchoolImagesAudit = React.lazy(() => import('components/pages/school-images-audit/SchoolImagesAudit'));
const GlobalCampaingReport = React.lazy(() => import('components/pages/campaigns/global/GlobalCampaignReport'));
const RecruiterProQuestions = React.lazy(() =>
  import('components/pages/recruiter-pro-questions/RecruiterProQuestions')
);

const SchoolBillingPagev2 = React.lazy(() => import('components/pages/school-billing-page/v2/SchoolBillingPage'));

const BlogHome = React.lazy(() => import('components/pages/blog/BlogHome'));
const BlogCategory = React.lazy(() => import('components/pages/blog/BlogCategory'));
const BlogSearchResult = React.lazy(() => import('components/pages/blog/BlogSearchResult'));
const SingleBlog = React.lazy(() => import('components/pages/blog/SingleBlog'));

const Assignments = React.lazy(() => import('components/pages/assignments/assignmentsPage'));

const Reports = React.lazy(() => import('components/pages/reports/ReportsPage'));
const SchoolReports = React.lazy(() => import('components/pages/school-reports/SchoolReportsPage'));
const WebflowPage = React.lazy(() => import('components/pages/webflow/WebflowPage'));

const updatePage = () => {};

const requireAuthToken = (nextState, replace) => {
  if (!store.getState().auth.token) {
    replace({ pathname: '/login' });
  }
};

const requireSuperadmin = (nextState, replace) => {
  const token = store.getState().auth.token;
  if (!token) {
    replace({ pathname: '/login' });
  } else if (!isSuperadmin(token)) {
    console.log('no superadmin');
    replace({ pathname: '/' });
  }
};

const redirectValidAuth = (nextState, replace) => {
  if (store.getState().auth.token) {
    replace({ pathname: '/admin' });
  }
};

class Routes extends Component {
  render() {
    return (
      <Suspense
        fallback={
          <Modal isVisible className="modal-loading">
            <div className="img-loading-search" style={{ display: 'block' }}>
              <img
                src="https://scholamatch-static.s3-us-west-2.amazonaws.com/LoadingAnimation200Opt.gif"
                alt="schola loading"
              />
            </div>
          </Modal>
        }>
        <OnboardingProvider>
          <Router history={browserHistory} render={applyRouterMiddleware(useScroll())} onUpdate={updatePage}>
            <Route path="/">
              <IndexRoute component={WebflowPage} />
              {/* Embedded */}
              <Route path="/claimform" component={ClaimFormEmbedded} />
              <Route path="/claimform/:schoolId" component={ClaimFormEmbedded} />
            </Route>
            <Route path="/" component={HomeLayout}>
              <IndexRoute component={LandingPage} />
              <Route path="/claim" component={LandingPage} />
              <Route path="/claim/:schoolId" component={LandingPage} />
              {/* <Route path="landing-v2-back" component={ClaimSchoolPagev2} /> */}
              <Route path="/landing-page-legacy" component={ClaimSchoolPagev3} />
              <Route path="/landing-page-legacy/claim/:schoolId" component={ClaimSchoolPagev3} />
              <Route path="terms" component={Terms} />
              <Route path="privacy" component={PrivacyPolicy} />

              <Route path="/blog" component={BlogHome} />
              <Route path="/blog/category/:slug" component={BlogCategory} />
              <Route path="/blog/results/:search" component={BlogSearchResult} />
              <Route path="/blog/:slug" component={SingleBlog} />
            </Route>
            <Route path="/onboarding/lets-start" component={LetsStart} />
            <Route path="/onboarding/whats-the-mision" component={WhatsTheMision} />
            <Route path="/onboarding/what-learning-models" component={WhatLearningModels} />
            <Route path="/onboarding/brief-describe-school-unique" component={BriefllyDescribeSchoolUnique} />
            <Route path="/onboarding/add-your-social-media" component={AddYourSocialMedia} />
            <Route path="/onboarding/how-are-you-building-relations" component={HowAreYouBuildingRelations} />
            <Route path="/onboarding/were-almost-there" component={WeAreAlmostThere} />
            {/* Level 2 */}
            <Route path="/onboarding/add-priority-the-grades" component={AddPriorityTheGrades} />
            <Route path="/onboarding/current-enrollment" component={CurrentEnrollment} />
            <Route path="/onboarding/goals-enrollment" component={GoalsEnrollment} />
            <Route path="/onboarding/application-date" component={ApplicationDeadlineDate} />
            <Route path="/onboarding/zipcodes-target" component={ZipcodesTarget} />
            <Route path="/onboarding/zipcodes-transport" component={ZipcodesTransport} />
            <Route path="/onboarding/offer-transport" component={OfferTransport} />
            {/* Level 3 */}
            <Route path="/onboarding/share-featuringVideos" component={FeaturingVideos} />
            <Route path="/onboarding/upload-adds" component={UploadAdds} />
            <Route path="/onboarding/upload-school-logo" component={UploadSchoolLogo} />

            {/* Level 4 */}
            <Route path="/onboarding/enrollment-steps" component={EnrollmentSteps} />
            <Route path="/onboarding/enrollment-digitized" component={EnrollmentProcessDigitized} />
            <Route path="/onboarding/currently-enrollmentSystem" component={CurrentlyEnrollmentSystem} />
            <Route path="/onboarding/upload-enrollment-files" component={UploadEnrollmentFiles} />

            {/* Level 5 */}
            <Route path="/onboarding/schedule-calendly" component={ScheduleCalendly} />

            <Route path="/login" component={LoginPage} onEnter={redirectValidAuth} />
            <Route component={ContentLayout}>
              <Route path="/about-campaign-estimator" component={AboutCampaignEstimatorPage} />
            </Route>
            <Route components={WhitelabelLayout}>
              <Route path="/application/school/:id/token(/:token)/last/:last" component={EditApplicationPage} />
            </Route>
            <Route path="/adminv2/schools/:id" component={AuthenticatedLayoutv2} onEnter={requireAuthToken}>
              <IndexRoute component={Dashboardnew} />
              <Route path="dashboard" component={Dashboardv3} />
              <Route path="dashboard/tracking" component={Dashboardv4} />
              <Route path="dashboardv2" component={Dashboardnew} />
              <Route path="manager-user/boards" component={ManagerUser} />
              <Route path="manager-user/boards/:board_id" component={ParentPortalBoardName} />
              <Route path="dashboard-billing" component={DashboardBilling} />
              <Route path="dashboard-billing-v2" component={SchoolBillingPagev2} />
              <Route path="recruitment" component={Recruitment} />
              <Route path="live-search" component={LiveSearchV2} />
              <Route path="notification-center" component={NotificationCenter} />
              <Route path="relationship-manager" component={RelationshipManager} />
              <Route path="relationship-managerv2" component={RetoolPage} onEnter={requireSuperadmin} />
              <Route path="campaign-breakdown" component={SpendsRecords} />
              <Route path="campaign-report" component={CampaignReport} />
              <Route path="campaigns" component={Campaigns} />
              <Route path="campaigns/dashboard/:campaign_id" component={CampaignDashboard} />
              <Route path="student-parent" component={StudentParent} />
              <Route path="parents" component={Parents} />
              <Route path="user-management" component={UserManagement} />
              <Route path="messages" component={Messages} />
              <Route path="messages/:lead_id" component={Messages} />
              <Route path="reports" component={SchoolReports} />
            </Route>
            <Route path="/admin" component={AuthenticatedLayoutv2} onEnter={requireAuthToken}>
              <IndexRedirect to="schools" />
              <Route path="schools">
                <IndexRoute component={SchoolListPage} />
                <Route path="add" component={AddSchoolPage} onEnter={requireSuperadmin} />
              </Route>
              <Route path="import" component={SchoolImportPage} onEnter={requireSuperadmin} />
              <Route path="create-landing-page" component={EditOrCreateLandingPagePage} onEnter={requireSuperadmin} />
              <Route path="images-audit" component={SchoolImagesAudit} onEnter={requireSuperadmin} />
              <Route path="global-campaign-report" component={GlobalCampaingReport} onEnter={requireSuperadmin} />
              <Route
                path="edit-landing-page/:location"
                component={EditOrCreateLandingPagePage}
                onEnter={requireSuperadmin}
              />
              <Route path="landing-pages" component={LandingPagesPage} onEnter={requireSuperadmin} />
              <Route path="pricing-premium" component={PricingPremiumPage} />
              <Route path="pricing-superadmin" component={PricingSuperAdminPage} />
              <Route path="account" component={AccountSettingsPage} />
              <Route path="account/:id" component={AccountSettingsPage} />
              <Route path="about-your-school" component={QuestionarieAboutYourSchool} />
              <Route path="enrollment" component={QuestionarieEnrollment} />
              <Route path="marketing-materials" component={MarketingMaterials} />
              <Route path="social-media" component={SocialMedia} />
              <Route path="tours-events" component={ToursEvents} />
              <Route path="assignments" component={Assignments} onEnter={requireSuperadmin} />
              <Route path="reports" component={Reports} onEnter={requireSuperadmin} />

              <Route path="schools/:id" components={{ children: SchoolLayout, sidebar: SingleSchoolSidebar }}>
                <Route path="admin" component={AdminEditSchoolPage} onEnter={requireSuperadmin} />
                <Route path="subscription" component={SubscrptionManagerPage} onEnter={requireSuperadmin} />
                <Route path="profile" component={SchoolProfilePage} />
                <Route
                  path="application/:appid"
                  components={{ children: ViewApplicationPage, sidebar: ViewApplicationSidebar }}
                />
                <Route path="application" component={SchoolApplicationPage} />
                <Route path="features" component={SchoolFeaturesPage} />
                <Route path="leads" component={RelationshipManager} />
                <Route path="campaign-estimator" component={SchoolCampaignEstimatorPage} />
                <Route path="users" component={SchoolUsersPage} />
                <Route path="billing" component={SchoolBillingPage} />
                {/* <Route path="pricing" component={PricingPage} /> */}
                <Route path="tours" component={SchoolToursPage} />
                <Route path="onboarding" component={Onboarding} />
                <Route path="retool" component={RetoolPage} onEnter={requireSuperadmin} />
                <Route path="profile" component={RetoolPage} onEnter={requireSuperadmin} />
                <Route path="recruiter-pro-questions" component={RecruiterProQuestions} />
              </Route>
            </Route>
            <Route path="/admin/v2/" component={AuthenticatedLayoutv3} onEnter={requireAuthToken}>
              <Route path="schools/:id" onEnter={requireAuthToken}>
                <IndexRoute component={DashboardSchool} />
                <Route path="dashboard" component={DashboardSchool} />
                <Route path="command-center" component={Dashboardv3} />

                {/* Student Management */}
                <Route path="relationship-manager" component={RetoolPage} />
                <Route path="live-search" component={LiveSearchV2} />
                {/* Communication Hub */}
                <Route path="messages" component={Messages} />
                <Route path="messages/:lead_id" component={Messages} />
                <Route path="campaigns" component={Campaigns} />
                <Route path="campaigns/dashboard/:campaign_id" component={CampaignDashboard} />
                <Route path="campaign-report" component={CampaignReport} />
                {/* Recruitment Tools */}
                <Route path="recruitment-tools" component={RetoolPage} />
                {/* Lottery */}
                <Route path="lottery" component={RetoolPage} />
                {/* Reports */}
                <Route path="reports" component={SchoolReports} />
                {/* Tour & Events */}
                <Route path="tours" component={SchoolToursPage} />
                {/* Communication Hub */}
                <Route path="communication-hub" component={CommunicationHub}>
                  <Route path="messages" component={Messages} />
                  <Route path="messages/:lead_id" component={Messages} />
                  <Route path="campaigns-center" component={AdvancedCampaigns} />
                  <Route path="historical" component={HistoricalCampaigns} />
                </Route>
                <Route path="communication-hub/advanced/:campaign_id" component={FlowDesigner} />

                <Route path="user-management" component={UserManagement} />
                <Route path="notification-center" component={NotificationCenter} />
              </Route>
              <Route path="schools/:id" components={{ children: SchoolLayout, sidebar: SingleSchoolSidebar }}>
                <Route path="profile" component={RetoolPage} />
                <Route path="retool" component={RetoolPage} />
                <Route path="admin" component={AdminEditSchoolPage} onEnter={requireSuperadmin} />
                <Route path="subscription" component={SubscrptionManagerPage} onEnter={requireSuperadmin} />
                <Route path="application" component={SchoolApplicationPage} />
              </Route>
            </Route>
            <Route path="health" component={Health} />
            <Route path="*" component={NotFoundPage} />
          </Router>
        </OnboardingProvider>
      </Suspense>
    );
  }
}
export default Routes;
