import { Typography, Flex, Collapse } from 'antd';
import { IconCircle, Button } from '..';
import styles from './template-selector.module.scss';

export const TemplateSelector = ({ templates, onTemplateSelect }) => {
  return (
    <Flex vertical gap="middle">
      {/* <Collapse accordion bordered={false} items={templates} onChange={onTemplateSelect} /> */}
      {templates.map((template) => (
        <Flex key={template.id} justify="space-between" align="center">
          <Flex gap="middle" align="center">
            {/* <IconCircle Icon={template.icon} size="small" color={template.colorIcon} /> */}
            <img src={template.icon} className={styles.icon} alt="campaign icon" />
            <Typography.Title level={5}>{template.name}</Typography.Title>
          </Flex>
          <Button type="default" onClick={() => onTemplateSelect(template)}>
            Select
          </Button>
        </Flex>
      ))}
    </Flex>
  );
};
