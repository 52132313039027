import React, { useMemo } from 'react';
import { Container } from '..';
import styles from './total-status-count.module.scss';
import classNames from 'classnames';

export const TotalStatusCount = ({ title, count, isPerformaceLoading, link }) => {
  const { newValue } = useMemo(() => {
    if (count > 0) {
      return { newValue: `+ ${count}` };
    }

    return { newValue: count };
  }, [count]);

  const valueStyles = {
    [styles.positive]: count > 0,
  };

  return (
    <Container title={title} link={link}>
      <Container.Body className={styles.content}>
        {isPerformaceLoading ? '-' : <span className={classNames(styles.newValue, valueStyles)}>{newValue || 0}</span>}
      </Container.Body>
    </Container>
  );
};
