import React, { useMemo } from 'react';
import { Container, LabelInfo } from '..';
import styles from './pipeline-leads.module.scss';

const data = [
  {
    status: 'Incoming',
    total: 15,
  },
  {
    status: 'Application Sent',
    total: 6,
  },
  {
    status: 'Application Received',
    total: 6,
  },
  {
    status: 'Waitlisted',
    total: 15,
  },
  {
    status: 'Accepted',
    total: 10,
  },
];
const colors = {
  new: 'linear-gradient(90deg, rgba(238, 66, 102, .90) 0%, rgba(246, 103, 133, .90) 100%)',
  'application-sent': 'linear-gradient(90deg, rgba(170, 127, 194, .90) 0%, rgba(186, 160, 201, .90) 100%)',
  'application-received': 'linear-gradient(90deg, rgba(0, 153, 204, .90) 0%, rgba(44, 172, 214, .90) 100%)',
  waitlisted: 'linear-gradient(90deg, rgba(123, 202, 210, .90) 0%, rgba(157, 216, 222, .90) 100%)',
  accepted: 'linear-gradient(90deg, rgba(153, 204, 0, .90) 0%, rgba(172, 216, 39, .90) 100%)',
};

const statusLabels = {
  new: 'Incoming',
  'application-sent': 'App Sent',
  'application-received': 'App Received',
  waitlisted: 'Waitlisted',
  accepted: 'Accepted',
};

export const PipelineLeads = ({ leadsCount, className, link }) => {
  const getMaxValue = (data) => {
    return data.reduce((maxTotal, item) => {
      return Math.max(maxTotal, Number(item.total));
    }, Number(data[0].total));
  };

  const maxValue = useMemo(() => getMaxValue(leadsCount), []);

  const getBarValue = (currentValue) => {
    return Math.ceil((currentValue * 100) / maxValue);
  };

  const bars = useMemo(() => {
    return leadsCount
      .filter(({ total }) => Number(total) > 0)
      .map((item) => ({
        ...item,
        backgroudColor: colors[item.status],
        percentage: getBarValue(Number(item.total)),
      }));
  }, []);

  const isContainingData = useMemo(() => {
    let valid = false;
    for (const item of leadsCount) {
      if (item.total > 0) {
        valid = true;
        break;
      }
    }
    return valid;
  }, []);

  return (
    <Container
      title="Current Student Pipeline"
      className={className}
      link={link}
      extraLabel="View in Relationship Manager">
      <Container.Body className={styles.wrapper}>
        {!isContainingData && <LabelInfo />}

        <div className={styles.barsContainer}>
          {bars.map((bar, idx) => (
            <div
              key={idx}
              className={styles.custombar}
              style={{ height: `${bar.percentage}%`, background: bar.backgroudColor }}>
              <span className={styles.number}>{bar.total}</span>
              <span className={styles.label}>{statusLabels[bar.status]}</span>
            </div>
          ))}
        </div>

        {/* <p className={styles.note}>
          * To search, apply filters, or view Archived leads, visit your{' '}
          <Link className={styles.note_link} to={link}>
            Relationship Manager.
          </Link>
        </p> */}
      </Container.Body>
    </Container>
  );
};
