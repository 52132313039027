import React from 'react';

const Plus = ({ color }) => {
  const [colorIcon, setColorIcon] = React.useState(color);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 449" height="20">
      <path
        fill={colorIcon}
        d="M416,177H272V33c0-17.7-14.3-32-32-32h-32c-17.7,0-32,14.3-32,32v144H32c-17.7,0-32,14.3-32,32v32c0,17.7,14.3,32,32,32h144v144 c0,17.7,14.3,32,32,32h32c17.7,0,32-14.3,32-32V273h144c17.7,0,32-14.3,32-32v-32 C448,191.3,433.7,177,416,177z"
      />
    </svg>
  );
};

export default Plus;
