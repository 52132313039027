import { _getLeadsStatusCount } from 'controllers/leads/leads_controller';
import { useStatus } from 'hooks/useStatus';
import { useEffect, useState } from 'react';

export const useLeadsCount = (schoolId) => {
  const [leadsCount, setLeadsCount] = useState({
    byStatus: {},
    total: 0,
    totalApplications: 0,
  });

  const { onLoading, onSuccess, status } = useStatus();

  useEffect(() => {
    onLoading();

    getLeadsCount(schoolId).then((leads) => {
      setLeadsCount({
        ...leadsCount,
        byStatus: leads,
        total: getTotalLeads(leads),
        totalApplications: totalApplications(leads),
      });

      onSuccess();
    });
  }, []);

  return { leadsCount, leadStatus: status };
};

const getLeadsCount = async (schoolId) => {
  const res = await _getLeadsStatusCount(schoolId);
  const leadsCount = await res.json();
  return leadsCount;
};

const getTotalLeads = (statusLeads) => {
  return statusLeads.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total), 0);
};

const totalApplications = (leads) => {
  return leads.find(({ status }) => status === 'application-received').total;
};
