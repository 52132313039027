import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import auth from './auth/auth-reducers';
import feature from './feature/feature-reducers';
import landingPage from './landing-page/landing-page-reducers';
import lead from './lead/lead-reducers';
import school from './school/school-reducers';
import user from './user/user-reducers';
import campaignEstimation from './campaign-estimation/campaign-estimation-reducers';
import dashboard, { messagesCountReducer, schoolReducer } from './dashboard/dashboard-reducers';
import payment from './payment/payment-reducers';
import note from './note/note-reducers';
import onboarding from './onboarding/onboarding-reducers';
import aboutYourSchool from './about-your-school/about-your-school-reducers';
import { settingsReducer } from './settings/settings-reducers';
import campaignReducer from './campaigns/campaigns-reducer';

const reducers = combineReducers({
  auth,
  browser: createResponsiveStateReducer({
    tabletPortrait: 599,
    tabletLandscape: 899,
    desktop: 1199,
  }),
  feature,
  landingPage,
  lead,
  school,
  schoolData: schoolReducer,
  user,
  campaignEstimation,
  dashboard,
  settings: settingsReducer,
  payment,
  note,
  onboarding,
  aboutYourSchool,
  messagesCount: messagesCountReducer,
  campaigns: campaignReducer,
});

export default reducers;
