import React, { useMemo, useState } from 'react';
import { useForm } from 'hooks/useForm';
import { Buttonv2 as Button } from 'schola-components';
import { validateWaitlist } from '../utils';
import styles from './waitlist-form.module.scss';
import { ContactInformation } from './contact-information/contact-information';
import { NewSchoolInformation } from './new-school-information/new-school-information';
import { SchoolSearcher } from './school-searcher/school-searcher';
import { t } from 'components/translation/Translation';
import { getProfile } from 'util/auth';
import { joinPlusWaitlist } from 'controllers/waitlist/waitlist-controller';
import { useStatus } from 'hooks/useStatus';
import { statusType } from '../waitlist-schola-plus';

export const WaitlistForm = ({ onBack, onSuccessWaitList, schoolId, showSchoolSearcher = false, initialStep }) => {
  const profile = useMemo(() => getProfile(), []);

  const name = profile?.name.split(' ');
  const firstName = (name && name[0]) || '';
  const lastName = (name && name[1]) || '';

  const [isNewSchool, setIsNewSchool] = useState(false);
  const handleNewSchool = () => setIsNewSchool(true);

  const handleGrades = (grades) => {
    // Convert to string
    handleValue('school_grades', grades.toString());
  };

  const initialState = {
    school_id: schoolId,
    school_name: '',
    first_name: firstName,
    last_name: lastName,
    job_title: '',
    email: profile?.email || '',
    phone: '',

    school_grades: '',
    school_address: '',
    school_type: '',
    appsite: 'schools_admin',
  };

  const { onLoading, onSuccess, onError, status } = useStatus();

  const onSubmit = async () => {
    onLoading();

    try {
      const res = await joinPlusWaitlist(values);
      // console.log(res)

      const data = await res.json();
      // console.log(data)

      if (res.ok) {
        onSuccess();
        onSuccessWaitList();
      }
    } catch (error) {
      console.log(error);
      onError();
    }
  };

  const { values, handleChange, handleSubmit, errors, handleValue } = useForm(
    initialState,
    validateWaitlist,
    onSubmit,
    showSchoolSearcher
  );

  const contactInputs = [
    {
      component: 'text',
      type: 'text',
      label: t('home:waitlist.name'),
      id: 'first_name',
      name: 'first_name',
      value: values.first_name,
      error: errors.first_name,
    },
    {
      component: 'text',
      type: 'text',
      label: 'Last Name',
      id: 'last_name',
      name: 'last_name',
      value: values.last_name,
      error: errors.last_name,
    },
    {
      component: 'dropdown',
      label: t('home:waitlist.role'),
      id: 'job_title',
      name: 'job_title',
      value: values.job_title,
      error: errors.job_title,
    },
    {
      component: 'text',
      type: 'email',
      label: t('home:waitlist.email'),
      id: 'email',
      name: 'email',
      value: values.email,
      error: errors.email,
    },
    {
      component: 'text',
      type: 'tel',
      label: t('home:waitlist.phone'),
      id: 'phone',
      name: 'phone',
      value: values.phone,
    },
  ];

  const newSchoolInputs = [
    {
      component: 'text',
      label: t('home:waitlist.address'),
      id: 'school_address',
      name: 'school_address',
      value: values.school_address,
      error: errors.school_address,
    },
    {
      component: 'dropdown',
      label: t('home:waitlist.type'),
      id: 'school_type',
      name: 'school_type',
      value: values.school_type,
      error: errors.school_type,
    },
    {
      component: 'selector',
      label: t('home:waitlist.gradeOptions'),
      id: 'school_grades',
      name: 'school_grades',
      value: values.school_grades,
      error: errors.school_grades,
    },
  ];

  return (
    <section className={styles.formLayout}>
      <form className={styles.formWaitlist}>
        <h5 className="spacing-my-24">{t('home:waitlist.schoolInfo')}</h5>
        <p className="spacing-mb-24 body-small">{t('home:waitlist.schoolDescription')}</p>

        {showSchoolSearcher && (
          <div className="spacing-mb-32">
            <SchoolSearcher handleValue={handleValue} handleNewSchool={handleNewSchool} />

            {errors?.school_name && <div className={styles.errorLabel}>{errors.school_name}</div>}
          </div>
        )}

        {isNewSchool && (
          <NewSchoolInformation
            handleChange={handleChange}
            handleGrades={handleGrades}
            newSchoolInputs={newSchoolInputs}
          />
        )}

        <ContactInformation contactInputs={contactInputs} handleChange={handleChange} />
      </form>

      {initialStep === statusType.zipcode ? (
        <div className={styles.formLayout_buttons}>
          <Button onClick={onBack} color="secondary" disabled={status.loading}>
            {t('home:waitlist.back')}
          </Button>
          <Button onClick={handleSubmit} disabled={status.loading}>
            {t('home:waitlist.join')}
          </Button>
        </div>
      ) : (
        <Button onClick={handleSubmit} disabled={status.loading} fullWidth>
          {t('home:waitlist.join')}
        </Button>
      )}
    </section>
  );
};
