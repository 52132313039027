import { Flex, Card } from 'antd';
import { MailOutlined, MessageOutlined, FieldTimeOutlined, BranchesOutlined } from '@ant-design/icons';
import styles from '../flow-designer.module.scss';

const nodeTypes = [
  { type: 'emailNode', label: 'Send Email', icon: MailOutlined },
  { type: 'smsNode', label: 'Send SMS', icon: MessageOutlined },
  { type: 'timerNode', label: 'Wait', icon: FieldTimeOutlined },
  { type: 'conditionNode', label: 'Condition', icon: BranchesOutlined },
];

export const SelectorNode = ({ onNodeClick }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Card title="Campaign Nodes" className={styles.nodeSelector}>
      <Flex vertical gap="small">
        {nodeTypes.map((node) => {
          const Icon = node.icon;
          return (
            <div
              key={node.type}
              type="default"
              onClick={() => onNodeClick(node.type)}
              className={styles.nodeButton}
              draggable
              onDragStart={(e) => onDragStart(e, node.type)}>
              <Icon style={{ color: '#0099cc' }} />
              <span>{node.label}</span>
            </div>
          );
        })}
      </Flex>
    </Card>
  );
};
