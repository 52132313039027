import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './select.module.scss';

export const AntSelect = ({ value, onChange, placeholder, children, className, id }) => {
  return (
    <Select
      id={id || ''}
      className={`${styles.select} ${className || ''}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      suffixIcon={<DownOutlined className={styles.arrow} />}
      popupClassName={styles.dropdown}>
      {children}
    </Select>
  );
};

export const AntSelectItem = Select.Option;
