import React from 'react';
import { Container, LabelNotification } from '..';
import { useSelector } from 'react-redux';
import { useUpcomingTours } from '../../hooks';

export const NotificationsCenter = ({ schoolId, children, toursLink, messagesLink }) => {
  const messagesCount = useSelector((state) => state.messagesCount);

  const { upcomingTours, status } = useUpcomingTours(schoolId);

  return (
    <Container>
      <Container.Body>
        {children}
        <LabelNotification label="Upcoming Tours" count={status.loading ? '-' : upcomingTours} link={toursLink} />
        <LabelNotification label="Unread Messages" count={messagesCount?.messageCount.unread} link={messagesLink} />
      </Container.Body>
    </Container>
  );
};
