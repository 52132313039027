import React, { useEffect, useState } from 'react';
import Button from 'components/button/Button';
import { Phone16 } from '@carbon/icons-react';
import './section-preview.scss';
import { PlansModal } from 'components/upgrade/plans-modal';
import { useToggle } from 'hooks/useToggle';
import { WaitlistScholaPlus } from 'components/waitlist-schols-plus/waitlist-schola-plus';
import { allowedZipcodes } from 'components/waitlist-schols-plus/utils';
import { PlansTable } from 'components/plans-table/plans-table';

export const SectionPreview = ({ title, description, image, plan = 'ScholaPlus™', schoolId, zipcode }) => {
  const [isScholaPlusOpen, toggleScholaPlus] = useToggle(false);

  const [isInZone, setIsInZone] = useState(false);
  useEffect(() => {
    const zipInZone = allowedZipcodes.includes(zipcode);

    if (zipInZone) {
      setIsInZone(true);
    }
  }, []);

  const [isPlansOpen, togglePlans] = useToggle(false);

  return (
    <>
      <div className="sectionPreview">
        <h1 className="sectionPreview_title">{title}</h1>
        <p className="sectionPreview_description">{description}</p>
        <a href="https://pages.schola.com/meetings/schola-solutions/schedule-demo" target="_blank" rel="noreferrer">
          <Button className="primary" fullWidth>
            <Phone16 /> Upgrade to {plan}
          </Button>
        </a>

        {/* Temporarily hidden */}
        {/* <div className="sectionPreview_buttons">
          {isInZone ? (
            <>
              <a href="https://pages.schola.com/meetings/schola-solutions/schedule-demo" target="_blank" rel="noreferrer">
                <Button className="primary" fullWidth>
                  <Phone16 /> Upgrade to {plan}
                </Button>
              </a>
              <Button color="secondary" fullWidth onClick={togglePlans}>
                More Schola® Plans
              </Button>
            </>
          ) : (
            <Button fullWidth onClick={togglePlans} className="spacing-mb-16">
              Upgrade
            </Button>
          )}
        </div> */}

        {image && (
          <img style={{ maxWidth: 'fit-content' }} className="sectionPreview_image" src={image} alt="demonstration" />
        )}
      </div>

      {/* {isPlansOpen && (
        <PlansModal onClose={togglePlans}>
          <PlansTable zipcode={zipcode} schoolId={schoolId} />
        </PlansModal>
      )} */}

      {isScholaPlusOpen && (
        <WaitlistScholaPlus
          isScholaPlusOpen={isScholaPlusOpen}
          toggleScholaPlus={toggleScholaPlus}
          schoolId={schoolId}
        />
      )}
    </>
  );
};
