import { useState } from 'react';
import onboardingState from '../onboardingState';

const useOnboardingState = () => {
  const [state, setState] = useState(onboardingState);

  const addState = (data) => {
    // setState({
    //   ...state,
    //   mision: data
    // })
  };

  const handlers = {
    addMision: (data) => {
      setState({
        ...state,
        mision: data,
      });
    },

    addCurrentEnrollment: (data) => {
      setState({
        ...state,
        currentEnrollment: data,
      });
    },

    addGoalsEnrollment: (data) => {
      setState({
        ...state,
        goalsEnrollment: data,
      });
    },
  };

  const handleSubmit = () => {};

  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // Functions to handle progress bar
  const progressHandlers = {
    increase: () => {
      const increaseValue = state.progress + 5;

      setState({
        ...state,
        progress: increaseValue,
      });
    },

    decrease: () => {
      const decreaseValue = state.progress - 5;

      setState({
        ...state,
        progress: decreaseValue,
      });
    },
  };

  return {
    state,
    setState,
    addState,
    handleInput,
    handlers,
    handleSubmit,
    progressHandlers,
  };
};

export default useOnboardingState;
