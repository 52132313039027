import { useMemo } from 'react';
import { Flex, Card } from 'antd';
import { MailOutlined, MessageOutlined, FieldTimeOutlined, BranchesOutlined } from '@ant-design/icons';
import styles from '../flow-designer.module.scss';

const nodeTypes = [
  { id: 'email', type: 'emailNode', label: 'Send Email', icon: MailOutlined },
  { id: 'sms', type: 'smsNode', label: 'Send SMS', icon: MessageOutlined },
];
const nodeBase = [
  { id: 'timer', type: 'timerNode', label: 'Wait', icon: FieldTimeOutlined },
  { id: 'condition', type: 'conditionNode', label: 'Condition', icon: BranchesOutlined },
];

export const SelectorNode = ({ campaignType, onNodeClick }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const nodeOptions = useMemo(() => {
    if (campaignType === 'hybrid') return nodeTypes;

    return nodeTypes.filter((node) => campaignType === node.id);
  }, [campaignType]);

  return (
    <Card title="Campaign Nodes" className={styles.nodeSelector}>
      <Flex vertical gap="small">
        {nodeOptions.map((node) => {
          const Icon = node.icon;
          return (
            <div
              key={node.type}
              type="default"
              onClick={() => onNodeClick(node.type)}
              className={styles.nodeButton}
              draggable
              onDragStart={(e) => onDragStart(e, node.type)}>
              <Icon style={{ color: '#0099cc' }} />
              <span>{node.label}</span>
            </div>
          );
        })}
        {nodeBase.map((node) => {
          const Icon = node.icon;
          return (
            <div
              key={node.type}
              type="default"
              onClick={() => onNodeClick(node.type)}
              className={styles.nodeButton}
              draggable
              onDragStart={(e) => onDragStart(e, node.type)}>
              <Icon style={{ color: '#0099cc' }} />
              <span>{node.label}</span>
            </div>
          );
        })}
      </Flex>
    </Card>
  );
};
