import client from '../client';

export const ADD_NOTE = 'ADD_NOTE';
export const GET_NOTES = 'GET_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';

export function getNotes(type, typeid) {
  return {
    type: GET_NOTES,
    promise: client.get(`notes/${type}/${typeid}`, {}, { credentials: 'omit' }),
  };
}

export function addNote(payload) {
  return {
    type: ADD_NOTE,
    promise: client.post(`note`, payload, { credentials: 'omit' }),
  };
}

export function updateNote(noteId, payload) {
  return {
    type: UPDATE_NOTE,
    promise: client.post(`note/${noteId}`, payload, { credentials: 'omit' }),
  };
}

export function deleteNote(noteId) {
  return {
    type: DELETE_NOTE,
    promise: client.delete(`note/${noteId}`, {}, { credentials: 'omit' }),
  };
}
