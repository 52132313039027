import React, { Component } from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';

class Modal extends Component {
  componentDidMount() {
    if (this.props.isVisible) {
      this.setBodyOverflow(true);
    }
  }

  componentWillUpdate(nextProps) {
    if (!this.props.isVisible && nextProps.isVisible) {
      this.setBodyOverflow(true);
    } else if (this.props.isVisible && !nextProps.isVisible) {
      this.setBodyOverflow(false);
    }
  }

  componentWillUnmount() {
    this.setBodyOverflow(false);
  }

  setBodyOverflow(isVisible) {
    if (isVisible) {
      document.body.classList.add('body-lock');
    } else {
      document.body.classList.remove('body-lock');
    }
  }

  render() {
    const modalClasses = {
      modal: true,
      'modal--visible': this.props.isVisible,
      [`${this.props.className}`]: this.props.className,
    };
    const cssContentClasses = {
      modal__content: true,
      [`${this.props.cssContent}`]: this.props.cssContent,
    };
    return (
      <div className={classNames(modalClasses)}>
        <div className={classNames(cssContentClasses)}>
          <div className="modal__header">
            <span className="modal__title">{this.props.title ? this.props.title : ''}</span>
            <span className="modal__close" onClick={this.props.onClose}>
              <Icon icon="Remove" size="small" />
            </span>
          </div>
          <div className="modal__children">{this.props.children}</div>
        </div>
        <div className="modal__overlay" onClick={this.props.onClose} />
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  cssContent: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  className: null,
  isVisible: false,
  onClose: null,
  cssContent: '',
};

export default Modal;
