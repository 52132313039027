import React from 'react';

const Details = () => {
  /* eslint-disable max-len */
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="26"
      height="26"
      viewBox="0 0 26 26">
      <image
        id="image0"
        width="26"
        height="26"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaBAMAAABbZFH9AAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAIVBMVEUAAAAAmdMAmtMAmdMA m9IAmdMAmtMAmtMAmtMAmtP///99xIcdAAAACXRSTlMAS97dSuLg4+RHLK1GAAAAAWJLR0QKaND0 VgAAAAlwSFlzAAAN1wAADdcBQiibeAAAAAd0SU1FB+ICCQIoDbD4CroAAAA1SURBVBjTY2DUnAkD kx0YWGciQAID5wQGGAAyKeDNZACbiJU3NE3ByWNHCsEABsZOOGeGAwDeTkrUdcDjXwAAACV0RVh0 ZGF0ZTpjcmVhdGUAMjAxOC0wMi0wOVQwMjo0MDoxMy0wNzowMAy8do4AAAAldEVYdGRhdGU6bW9k aWZ5ADIwMTgtMDItMDlUMDI6NDA6MTMtMDc6MDB94c4yAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5r c2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=="
      />
    </svg>
  );
  /* eslint-enable */
};

export default Details;
