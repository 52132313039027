export default {
  SCHEDULE_KEY: '{{schedule}}',
  TRIGGER_KEY: '{{trigger}}',
  SENT_TO_KEY: '{{sent_to}}',
  SEGMENTATION: '{{segmentation}}',
  SUBJECT_KEY: '{{subject}}',
  NAME_KEY: '{{from_name}}',
  EMAIL_KEY: '{{from_email}}',
  LOGO_KEY: '{{logo}}',
  SUB_STAGE_KEY: '{{substage}}',
  LEAD_STATUS_ID_KEY: '{{lead_status_id}}',
  SIGNATURE_TYPE_KEY: '{{signature_type}}',
  SIGNATURE_NAME_KEY: '{{signature_name}}',
  SIGNATURE_LABEL_KEY: '{{signature_label}}',

  SENT_TO_ALL_VALUE: 'all',

  RECURRING_EMAIL_CAMPAIGN: 'Recurring Automated Email',

  TRIGGER_NONE_VALUE: 'none',
  TRIGGER_BIRTHDAY_VALUE: 'birthday',
  TRIGGER_ADD_SCHOLA_SYSTEM_VALUE: 'add_schola_system',

  RANGE_RECURRENCE_OPT_NO_END_DATE_VALUE: 'no_end_date',
  RANGE_RECURRENCE_OPT_END_BY_VALUE: 'end_by',

  DEFAULT_TIMEZONE: '(GMT+00:00) Casablanca',

  RECURRENCE_WEEKLY_VALUE: 'weekly',
  RECURRENCE_MONTHLY_VALUE: 'monthly',
  RECURRENCE_YEARLY_VALUE: 'yearly',

  FORMAT_DATE: 'YYYY-MM-DD',
  FORMAT_TIME: 'hh:mm A',
  FORMAT_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',

  DEFAULT_START_TIME: '12:00 AM',

  PLATAFORM_SMS: 'sms',
  PLATAFORM_EMAIL: 'email',

  DEFAULT_EMAIL: 'example@email.com',

  CAMPAIGN_TYPE_REGULAR_MASS_EMAIL: 'Regular Mass Email',
  CAMPAIGN_TYPE_REGULAR_MASS: 'Regular Mass',
  CAMPAIGN_TYPE_RECURRING_AUTOMATED_EMAIL: 'Recurring Automated Email',
  CAMPAIGN_TYPE_RECURRING_AUTOMATED: 'Recurring Automated',

  SIGNATURE_TYPE_KEY_DEFAULT: 'custom',
  SIGNATURE_TYPE_KEY_CUSTOM_VALUE: 'custom',

  CAMPAIGN_STATUS_DRAFT: 'Draft',

  CAMPAIGN_TYPES: [
    {
      type: 'Regular Mass',
      labelEmail: 'One Time Single Bulk Email',
      labelSms: 'One Time Single Bulk Text Message',
      description: 'This is a bulk message sent to many contacts at once, only one time.',
    },
    {
      type: 'Recurring Automated',
      labelEmail: 'Recurring Single Bulk Email',
      labelSms: 'Recurring Single Bulk Text Message',
      description:
        'These are messages you’d like to send out automatically on a regular schedule based on predetermined rules or trigger. This takes repetitive tasks off your to-do list to free up your time for other valuable tasks.',
    },
  ],
};
