import { _getUserLastLogin } from 'controllers/users/users_controller';
import { useEffect, useState } from 'react';

export const useGetLastLogin = (userId) => {
  const [lastLogin, setLastLogin] = useState(null);
  useEffect(() => {
    getLastLoginUser(userId).then((lastLoginTime) => setLastLogin(lastLoginTime));
  }, []);

  return lastLogin;
};

const getLastLoginUser = async (userId) => {
  const res = await _getUserLastLogin(userId);
  const { login_time: lastLoginTime } = await res.json();

  return lastLoginTime;
};
