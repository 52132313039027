import React, { useEffect, useState } from 'react';
import { Card } from 'components/card/card';
import { Modal } from 'antd';
import classNames from 'classnames';
import HistoricalCampaignTable from '../../components/historical-campaign/historical-campaign-table';
import HistoricalCampaignForm from '../../components/historical-campaign/historical-campaign-form';
import { _addLeadSource, _updateLeadSourceCost, _addLeadSourceCost } from 'controllers/leads/leads_controller';
import { otherCampaingLoadData, otherLoadExtras } from 'components/pages/campaigns/utils/DataSources';
import { dateToString } from 'components/pages/campaigns/utils/campaign_utils';
import { getProfile } from 'util/auth';
import ToastNotification from 'components/toast-notification/ToastNotification';
import styles from './historical-campaigns.module.scss';

export const HistoricalCampaigns = (props) => {
  const [historicalCampaigns, setHistoricalCampaigns] = useState([]);
  const [campaignSourceID, setCampaignSourceID] = useState('');
  const [totalSpend, setTotalSpend] = useState(0);
  const [studentsRecruited, setStudentsRecruited] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [editingCampaign, setEditingCampaign] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [sources, setSources] = useState([]);
  const [successUpdated, setSuccessUpdated] = useState(false);
  const [errorUpdated, setErrorUdpated] = useState(false);
  const [successCreated, setSuccessCreated] = useState(false);
  const [errorCreated, setErrorCreated] = useState(false);
  const [successDeleted, setSuccessDeleted] = useState(false);

  const onLoadData = async () => {
    let _params = {
      page: 1,
      pageSize: 10,
      source_id: '',
      start_date: dateToString(startDate) || '',
      end_date: dateToString(endDate) || '',
      school_id: props.params.id,
    };
    try {
      const { data } = await otherCampaingLoadData(_params);
      setHistoricalCampaigns(data);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };

  const onLoadSources = async () => {
    const _sources = await otherLoadExtras({
      all: true,
      school_id: props.params.id,
    });
    setSources(_sources);
  };

  useEffect(() => {
    onLoadData();
    onLoadSources();
  }, []);

  useEffect(() => {
    if (!isEditDialogOpen) {
      onLoadData();
    }
  }, [isEditDialogOpen]);

  const onSave = (values) => {
    const { sub } = getProfile();
    const payload = {
      sources_id: values.campaignSourceID,
      amount: values.totalSpend,
      students_recruited: Number(values.studentsRecruited),
      start_date: values.startDate ? values.startDate : undefined,
      end_date: values.endDate ? values.endDate : undefined,
      school_id: props.params.id,
      comments: '',
      created_by: sub,
    };
    // eslint-disable-next-line no-debugger
    /* debugger; */
    if (editingCampaign) {
      delete payload.created_by;
      _updateLeadSourceCost(editingCampaign.id, payload)
        .then(() => {
          onLoadData();
          setHistoricalCampaigns((prevCampaigns) =>
            prevCampaigns.map((campaign) =>
              campaign.id === editingCampaign.id ? { ...campaign, ...payload } : campaign
            )
          );
          setSuccessUpdated(true);
          clearAll();
          setIsEditDialogOpen(false);
          // eslint-disable-next-line no-debugger
          /* debugger; */
        })
        .catch((error) => {
          console.error('Error updating campaign:', error);
          setErrorUdpated(true);
        });
    } else {
      _addLeadSourceCost(payload)
        .then((newCampaign) => {
          onLoadData();
          setHistoricalCampaigns((prevCampaigns) => [...prevCampaigns, newCampaign]);
          setSuccessCreated(true);
        })
        .catch((error) => {
          console.error('Error adding campaign:', error);
          setErrorCreated(true);
        });
    }
  };

  const handleDelete = (id) => {
    setHistoricalCampaigns(historicalCampaigns.filter((campaign) => campaign.id !== id));
    setSuccessDeleted(true);
  };

  const handleEdit = (campaign, source) => {
    console.log('campaign:', campaign);
    setEditingCampaign(campaign);
    setIsEditDialogOpen(true);
  };

  const clearAll = () => {
    setCampaignSourceID('');
    setTotalSpend('');
    setStudentsRecruited('');
    setStartDate('');
    setEndDate('');
    setEditingCampaign(null);
  };

  const onCloseModal = () => {
    clearAll();
    setIsEditDialogOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <Card className={classNames(styles.container, 'spacing-mb-24')}>
        <HistoricalCampaignForm
          sources={sources}
          setCampaignSourceID={setCampaignSourceID}
          totalSpend={totalSpend}
          setTotalSpend={setTotalSpend}
          studentsRecruited={studentsRecruited}
          setStudentsRecruited={setStudentsRecruited}
          onSave={onSave}
          editingCampaign={editingCampaign}
        />
      </Card>
      {historicalCampaigns.length > 0 && (
        <>
          <div className={styles.headingContainer}>
            <h4 className={classNames(styles.heading)}>Historical Campaigns</h4>
          </div>
          <Card className={styles.container}>
            <HistoricalCampaignTable
              campaignSource={sources.sources}
              campaigns={historicalCampaigns}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          </Card>
        </>
      )}
      <Modal
        open={isEditDialogOpen}
        onCancel={() => onCloseModal()}
        className={styles.modal}
        footer={null}
        okText="Udpate Campaign">
        <HistoricalCampaignForm
          editingCampaign={editingCampaign}
          setEditingCampaign={setEditingCampaign}
          campaignSourceID={campaignSourceID}
          sources={sources}
          setCampaignSourceID={setCampaignSourceID}
          setTotalSpend={setTotalSpend}
          setStudentsRecruited={setStudentsRecruited}
          onSave={onSave}
        />
      </Modal>
      {successUpdated && (
        <ToastNotification
          type="success"
          message="The historical campaign has been updated successfully."
          tyme={3000}
        />
      )}
      {errorUpdated && (
        <ToastNotification type="error" message="There was an error updating the campaign." time={1000} />
      )}
      {successCreated && (
        <ToastNotification type="success" message="The historical campaign has been added successfully." time={1000} />
      )}
      {errorCreated && <ToastNotification type="error" message="There was an error adding the campaign." time={1000} />}
      {successDeleted && (
        <ToastNotification
          type="success"
          message="The historical campaign has been deleted successfully."
          time={1000}
        />
      )}
    </div>
  );
};
