import { _getSchoolTours } from 'controllers/schools/schools_controller';
import { useStatus } from 'hooks/useStatus';
import { useEffect, useState } from 'react';

export const useUpcomingTours = (schoolId) => {
  const [upcomingTours, setUpcomingTours] = useState(null);

  const { onLoading, onSuccess, status } = useStatus();

  useEffect(() => {
    onLoading();

    getTours(schoolId).then((tours) => {
      setUpcomingTours(getUpcomingTours(tours.results).length);

      onSuccess();
    });
  }, []);

  return { upcomingTours, status };
};

const getTours = async (schoolId) => {
  const tours = await _getSchoolTours(schoolId);
  return tours;
};

const getUpcomingTours = (tours) => {
  const today = new Date();

  return tours.filter((tour) => tour.tour_status === 'active').filter((tour) => new Date(tour.tour_date) >= today);
};
