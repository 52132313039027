import { Tabs } from 'antd';

export const SwitchPreview = ({ onSwitchView }) => {
  const onChange = (key) => {
    onSwitchView(key);
  };
  const items = [
    {
      key: 'edit',
      label: 'Edit',
    },
    {
      key: 'preview',
      label: 'Preview',
    },
  ];

  return <Tabs defaultActiveKey="edit" size="large" items={items} onChange={onChange} />;
};
