import React from 'react';
import { InputText, Dropdown } from 'schola-components';
import gradesArray from 'util/grades-array';
import { SchoolTypes } from 'util/school-types';
import { Selector } from '../selector/selector';
import styles from '../waitlist-form.module.scss';

export const NewSchoolInformation = ({ newSchoolInputs, handleChange, handleGrades }) => {
  const typeOptions = [{ value: null, label: '-' }, ...SchoolTypes];
  return newSchoolInputs.map((input, idx) => {
    if (input.component === 'text') {
      return (
        <InputText
          key={input.id}
          className={`spacing-mb-32 ${styles.labelAdapter}`}
          label={input.label}
          id={input.id}
          name={input.name}
          value={input.value}
          onChange={handleChange}
          error={input?.error}
          errorMessage={input?.error}
        />
      );
    }
    if (input.component === 'dropdown') {
      return (
        <div className="spacing-mb-32" key={input.id}>
          <Dropdown
            label={input.label}
            id={input.id}
            name={input.name}
            value={input.value}
            onChange={handleChange}
            options={typeOptions}
          />
          {input?.error && <div className={styles.errorLabel}>{input.error}</div>}
        </div>
      );
    }

    return (
      <div key={input.id}>
        <label className={styles.inputSearch_label}>Grade Options</label>
        <Selector options={gradesArray} handler={handleGrades} />

        {input?.error && <div className={styles.errorLabel}>{input.error}</div>}
      </div>
    );
  });
};
