import { useToast } from './useToast';

export const useCampaignActions = () => {
  const { toast } = useToast();

  const notifyCampaignsUpdated = () => {
    const event = new Event('campaignsUpdated');
    window.dispatchEvent(event);
  };

  const handleArchive = (campaign) => {
    const savedCampaigns = localStorage.getItem('campaigns');
    const allCampaigns = savedCampaigns ? JSON.parse(savedCampaigns) : [];

    const updatedCampaigns = allCampaigns.map((c) => (c.id === campaign.id ? { ...c, status: 'Inactive' } : c));

    localStorage.setItem('campaigns', JSON.stringify(updatedCampaigns));
    notifyCampaignsUpdated();
    toast({
      title: 'Campaign archived',
      description: 'Campaign has been marked as inactive',
    });
  };

  const handleDelete = (campaign) => {
    const savedCampaigns = localStorage.getItem('campaigns');
    const allCampaigns = savedCampaigns ? JSON.parse(savedCampaigns) : [];

    const updatedCampaigns = allCampaigns.filter((c) => c.id !== campaign.id);

    localStorage.setItem('campaigns', JSON.stringify(updatedCampaigns));
    notifyCampaignsUpdated();
    toast({
      title: 'Campaign deleted',
      description: 'Campaign has been permanently removed',
    });
  };

  return {
    handleArchive,
    handleDelete,
  };
};
