import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import localstorage from 'store2';

const getDecodedToken = (token) => {
  let decoded;

  try {
    decoded = jwtDecode(token);
  } catch (error) {
    decoded = {};
  }

  return decoded;
};

export function _isSuperadmin() {
  const token = localstorage.get('idToken');
  return isSuperadmin(token);
}

export function isSuperadmin(token) {
  const groups = get(getDecodedToken(token), 'app_metadata.authorization.groups');
  return Array.isArray(groups) && groups.indexOf('superadmin') >= 0;
}

export function getProfile(token) {
  let authToken = token || localstorage.get('idToken');

  if (!authToken) {
    return null;
  }

  const profile = getDecodedToken(authToken);

  profile.isAuth0User = startsWith(profile.sub, 'auth0|');

  return profile;
}

export function isScholaOffshore(token) {
  const groups = get(getDecodedToken(token), 'app_metadata.authorization.groups');
  return Array.isArray(groups) && groups.indexOf('scholaoffshore') >= 0;
}
