import React from 'react';

const PlaceColor = () => {
  /* eslint-disable max-len */
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g id="svg_1">
          <ellipse fill="#000000" opacity="0.25" cx="63.33424" cy="106.51483" rx="47.51" ry="12" id="svg_2" />
        </g>
        <g id="svg_3">
          <path
            fill="#EC407A"
            d="m62.6875,6.51042c-17.70295,0 -32.02083,15.44133 -32.02083,34.53333c0,25.9 32.02083,64.13333 32.02083,64.13333s32.02083,-38.23333 32.02083,-64.13333c0,-19.092 -14.31788,-34.53333 -32.02083,-34.53333zm0,46.86666c-6.31268,0 -11.43601,-5.52533 -11.43601,-12.33333c0,-6.808 5.12333,-12.33333 11.43601,-12.33333c6.31268,0 11.43601,5.52533 11.43601,12.33333c0,6.808 -5.12333,12.33333 -11.43601,12.33333z"
            id="svg_5"
          />
        </g>
        <g id="svg_4">
          <circle
            fill="#000000"
            opacity="0.0"
            cx="46.30141"
            cy="99.73436"
            r="14.91"
            transform="rotate(-45 -17.6985836029053,50.03436660766601) "
            id="svg_8"
          />
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default PlaceColor;
