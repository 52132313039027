import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';
import { ADD_NOTE, GET_NOTES, UPDATE_NOTE, DELETE_NOTE } from './note-actions';

const reducers = combineReducers({
  getNotes: getApiReducer(GET_NOTES),
  addNote: getApiReducer(ADD_NOTE),
  updateNote: getApiReducer(UPDATE_NOTE),
  deleteNote: getApiReducer(DELETE_NOTE),
});

export default reducers;
