import client from '../client';

export const GET_ONBOARDING = 'GET_ONBOARDING';
export const INSERT_UPDATE_ONBOARDING = 'INSERT_UPDATE_ONBOARDING';

export function getOnboarding(schoolId) {
  return {
    type: GET_ONBOARDING,
    promise: client.get(`schools/${schoolId}/onboarding`, {}, { credentials: 'omit' }),
  };
}

export function insertOrUpdateOnboarding(schoolId, payload) {
  return {
    type: INSERT_UPDATE_ONBOARDING,
    promise: client.post(`schools/${schoolId}/onboarding`, payload, { credentials: 'omit' }),
  };
}
