import { Select } from './select-param';

const CTA_OPTS = [
  { label: 'Custom', value: 'custom' },
  { label: 'Request Info', value: 'request_info' },
  { label: 'Schedule a Tour', value: 'schedule_tour' },
  { label: 'Apply Now', value: 'apply_now' },
  { label: 'Enroll Now', value: 'enroll_now' },
  { label: 'None', value: 'none' },
];

export const CTAcampaign = ({ getParamValue, onChangeParams, layout }) => {
  const { CTA_TYPE_KEY, CTA_CUSTOM_BTN_TEXT_KEY, CTA_CUSTOM_PROTO_KEY, CTA_CUSTOM_URL_KEY } = layout.keys;

  return (
    <div>
      <div className="form-row">
        <Select
          id="ctaType"
          label="Type of Call to Action"
          options={CTA_OPTS}
          value={getParamValue(CTA_TYPE_KEY)}
          fieldParam={CTA_TYPE_KEY}
          onChangeParams={onChangeParams}
        />
      </div>
      <div className="form-row mb-24">
        {getParamValue(CTA_TYPE_KEY) == 'custom' && (
          <>
            <div className="form-group col-12 col-md-6">
              <label>Custom CTA Button</label>
              <input
                type="text"
                value={getParamValue(CTA_CUSTOM_BTN_TEXT_KEY)}
                onChange={(e) => onChangeParams({ key: CTA_CUSTOM_BTN_TEXT_KEY, value: e.target.value })}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label>Custom URL Link</label>
              <div className="input-group mt-0">
                <div className="input-group-prepend">
                  <select
                    className="form-control"
                    onChange={(e) => onChangeParams({ key: CTA_CUSTOM_PROTO_KEY, value: e.target.value })}>
                    <option value="http://">http://</option>
                    <option value="https://">https://</option>
                  </select>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={getParamValue(CTA_CUSTOM_URL_KEY)}
                  onChange={(e) => onChangeParams({ key: CTA_CUSTOM_URL_KEY, value: e.target.value })}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
