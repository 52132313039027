import { CLOSE_CONTACT, OPEN_CONTACT, OPEN_IMPORT, CLOSE_IMPORT } from './settings-actions';

const initialState = { isContactModal: false, isImportModalShowing: false };

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONTACT:
      return {
        ...state,
        isContactModal: true,
      };
    case CLOSE_CONTACT:
      return {
        ...state,
        isContactModal: false,
      };

    case OPEN_IMPORT:
      return {
        ...state,
        isImportModalShowing: true,
      };

    case CLOSE_IMPORT:
      return {
        ...state,
        isImportModalShowing: false,
      };

    default:
      return state;
  }
};
