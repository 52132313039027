import { ReactFlowProvider, ReactFlow, Controls, Background } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import styles from './flow-designer.module.scss';
import { NodeCondition, NodeEmail, NodeSms, NodeTimer, SelectorNode } from './components';
import { useFlowDesigner } from './hooks';

export const FlowDesigner = () => {
  return (
    <section className={styles.container}>
      <ReactFlowProvider>
        <Flow />
      </ReactFlowProvider>
    </section>
  );
};

const getInitialNodes = (campaignType) => {
  const initialNode = {
    id: 'start',
    type: campaignType === 'sms' ? 'smsNode' : 'emailNode',
    position: { x: 250, y: 100 },
    data: {
      content: campaignType === 'sms' ? 'Enter your SMS message...' : '',
      subject: campaignType === 'sms' ? undefined : 'Welcome to Our School!',
      fromName: campaignType === 'sms' ? undefined : 'School Name',
      fromEmail: campaignType === 'sms' ? undefined : 'school@example.com',
      params: [],
      isFirstNode: true,
    },
  };

  return [initialNode];
};

export const initialEdges = [];

const Flow = () => {
  const nodeTypes = {
    emailNode: NodeEmail,
    smsNode: NodeSms,
    timerNode: NodeTimer,
    conditionNode: NodeCondition,
  };

  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    setRfInstance,
    onConnect,
    onDragOver,
    onDrop,
    onNodeClick,
    onSave,
  } = useFlowDesigner(getInitialNodes());

  return (
    <>
      <SelectorNode onNodeClick={onNodeClick} />
      <button onClick={onSave}>save</button>
      <div className={styles.canva}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onInit={setRfInstance}
          fitView
          fitViewOptions={{
            padding: 0.2,
            minZoom: 0.5,
            maxZoom: 1.5,
            duration: 500,
          }}
          minZoom={0.2}
          maxZoom={3}
          defaultViewport={{ x: 0, y: 0, zoom: 0.85 }}
          nodeTypes={nodeTypes}>
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </>
  );
};
