import React from 'react';

const Vocational = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 34 29">
      <path
        d="M.93106567 27.5465H33.3744269V6.95954878H.93106567zm26.49298833-.0002195V6.95932927M6.8818791 27.5462805V6.95932927m15.0524463.0002195V1.40918294h-9.5631582V6.9595488"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#8AC340"
        strokeWidth="1.2023"
      />
    </svg>
  );
  /* eslint-enable */
};

export default Vocational;
