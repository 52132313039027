import React from 'react';
import { ExportOutlined } from '@ant-design/icons';
import styles from './label-notification.module.scss';
import { Link } from 'react-router';

export const LabelNotification = ({ label = '', count = 0, link }) => {
  return (
    <div className={styles.layout}>
      <Link to={link} className={styles.link}>
        <div className={styles.label}>
          <p>{label}</p>
          <span className={styles.notification}>{count}</span>
        </div>
      </Link>

      <Link to={link}>
        <ExportOutlined />
      </Link>
    </div>
  );
};
