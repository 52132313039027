import { Modal, Typography } from 'antd';
import { Button, SettingSelector, TemplateSelector } from '..';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { STAGES, useCampaignSettings } from '../../hooks/useCampaignSettings';
import styles from './campaign-creation-settings.module.scss';
import { useTemplates } from '../../hooks/useCampaignTemplates';
import { useSelector } from 'react-redux';
import CampaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { LAYOUTS } from 'components/pages/campaigns/components/email/Layouts';

const { Title } = Typography;

export const CampaignCreationSettings = ({ schoolId, isVisible = true, toggleVisible }) => {
  const { currentSchool } = useSelector((state) => state.school);
  const defaultParams = defineDefaultParamsBySchool(currentSchool);

  const templates = useTemplates();

  const { stage, settings, optionsModes, optionsAdvanced, optionsSimple, onTemplateSelect, backToMode, titles } =
    useCampaignSettings({ schoolId, templates, defaultParams });

  const options = {
    mode: optionsModes,
    simple: optionsSimple,
    advanced: optionsAdvanced,
  };

  return (
    <Modal open={isVisible} onCancel={toggleVisible} footer={null}>
      <div className={styles.header}>
        <Button
          type="ghost"
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={backToMode}
          className={styles.backButton}
        />
        <Title level={4}>{titles[stage]}</Title>
      </div>

      {stage !== STAGES.TEMPLATE ? (
        <SettingSelector options={stage === STAGES.MODE ? options.mode : options[settings.mode]} />
      ) : (
        <TemplateSelector templates={templates} onTemplateSelect={onTemplateSelect} />
      )}
    </Modal>
  );
};

const defineDefaultParamsBySchool = (school) => {
  const { NAME_KEY } = CampaingConstants;
  const [layout] = LAYOUTS;
  const { SCHOOL_LOGO_KEY, MEDIA_FACEBOOK_KEY, MEDIA_INSTAGRAM_KEY, MEDIA_TWITTER_KEY, MEDIA_YOUTUBE_KEY } =
    layout.keys;

  const { name, logo_image, social_facebook, social_instagram, social_twitter, social_youtube } = school;

  return [
    { key: NAME_KEY, value: name || '' },
    {
      key: SCHOOL_LOGO_KEY,
      value:
        logo_image ||
        'https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/school-logo.png',
    },
    { key: MEDIA_FACEBOOK_KEY, value: social_facebook || '' },
    { key: MEDIA_INSTAGRAM_KEY, value: social_instagram || '' },
    { key: MEDIA_TWITTER_KEY, value: social_twitter || '' },
    { key: MEDIA_YOUTUBE_KEY, value: social_youtube || '' },
  ];
};
