import { useMemo } from 'react';

const { sub, format } = require('date-fns');

export const getFormat = (date) => format(date, 'yyyy/MM/dd');

export const useRanges = (lastLogin) => {
  const today = new Date();
  const rangeDateRaw = (range) => getFormat(sub(today, range));
  const defaultRanges = useMemo(
    () => [
      { id: 'lastLogin', value: lastLogin && getFormat(new Date(lastLogin)), label: 'Since Last Login' },
      { id: 'lastWeek', value: rangeDateRaw({ weeks: 1 }), label: 'Last Week' },
      { id: 'twoWeeks', value: rangeDateRaw({ weeks: 2 }), label: 'Last 2 Weeks' },
      { id: 'lastMonth', value: rangeDateRaw({ months: 1 }), label: 'Last Month' },
      { id: 'threeMonths', value: rangeDateRaw({ months: 3 }), label: 'Last 3 Months' },
      { id: 'sixMonths', value: rangeDateRaw({ months: 6 }), label: 'Last 6 Months' },
      { id: 'lastYear', value: rangeDateRaw({ months: 12 }), label: 'Last Year' },
    ],
    [lastLogin]
  );

  return { defaultRanges, todayDate: getFormat(today) };
};
