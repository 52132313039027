import client from '../client';
import { getResponseValue } from 'util/validate';

export const _getOverviewData = async (params) => {
  const { schoolId, startDate, endDate } = params;
  let payload = {
    startDate,
    endDate,
  };
  const res = await client.get(`school/${schoolId}/dashboard/overviewData`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getApplicationsProgressData = async (params) => {
  const { schoolId, startDate, endDate } = params;
  let payload = {
    startDate,
    endDate,
  };
  const res = await client.get(`school/${schoolId}/dashboard/applicationsProgressData`, payload, {
    credentials: 'omit',
  });
  return await getResponseValue(res);
};

export const _getCurrentLeadPipelineData = async (params) => {
  const { schoolId, startDate, endDate } = params;
  let payload = {
    startDate,
    endDate,
  };
  const res = await client.get(`school/${schoolId}/dashboard/currentLeadPipelineData`, payload, {
    credentials: 'omit',
  });
  return await getResponseValue(res);
};

/**
 * @param {number} schoolId
 * @param {{ sinceDate: 'MM/DD/YYYY' }} payload
 */
export const _getTimelineByMonth = async (schoolId, payload) => {
  const res = await client.post(`school/${schoolId}/dashboard/leadsTimeline`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

/**
 * @param {number} schoolId
 * @param {{
 *  "today": 'YYYY/MM/DD',
 *  "rangeDate": 'YYYY/MM/DD',
 *  "previousRangeDate": 'YYYY/MM/DD'
 * }} payload
 */
export const _getPerformanceByStatus = async (schoolId, payload) => {
  const res = await client.post(`school/${schoolId}/dashboard/performanceByStatus`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

/**
 * @param {number} schoolId: number
 * @param {{ sinceDate: 'YYYY/MM/DD' }} payload
 */
export const _getSPOTMessages = async (schoolId, payload) => {
  const res = await client.post(`school/${schoolId}/dashboard/spotMessages`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

/**
 * @param {number} schoolId: number
 * @param {{ from: 'YYYY/MM/DD', to: 'YYYY/MM/DD' }} payload
 */
export const _getCallsSPOT = async (schoolId, payload) => {
  const res = await client.get(
    `communications/calls/${schoolId}?from=${payload.from}&to=${payload.to}`,
    {},
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};
