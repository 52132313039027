import React from 'react';

const Private = () => {
  /* eslint-disable max-len */
  return (
    <svg width="32" height="30" viewBox="0 0 32 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16,23.5799724 L24.3436703,27.9039732 L22.7501711,18.7455925 L29.5003422,12.2595913 L20.1718352,10.9234016 L16,2.59083159 L11.8281648,10.9234016 L2.4996578,12.2595913 L9.2498289,18.7455925 L7.65632966,27.9039732 L16,23.5799724 Z M6.11145618,30 L8,19.145898 L0,11.4589803 L11.0557281,9.8753882 L16,0 L20.9442719,9.8753882 L32,11.4589803 L24,19.145898 L25.8885438,30 L16,24.8753882 L6.11145618,30 Z"
        fill="#8AC340"
      />
    </svg>
  );
  /* eslint-enable */
};

export default Private;
