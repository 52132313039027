import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Icon = (props) => {
  const IconComponent = require(`./icons/${props.icon}`).default;

  return (
    <span
      className={classNames([
        'icon',
        `icon--${props.color}`,
        `icon--rotate-${props.rotate}`,
        props.size ? `icon--${props.size}` : null,
        props.className,
      ])}>
      <IconComponent />
    </span>
  );
};

Icon.displayName = 'Icon';
Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'xsmall', 'small', 'medium', 'large', 'xlarge']),
  rotate: PropTypes.oneOf([0, 45, 90, 180, 270]),
  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light', 'mist', 'black', 'white', 'green']),
  className: PropTypes.string,
};
Icon.defaultProps = {
  size: null,
  rotate: 0,
  color: 'black',
  className: null,
};

export default Icon;
