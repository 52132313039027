import React from 'react';
import styles from './see-profile.module.scss';
import Button from 'components/button/v2/Button';
import { Link } from 'react-router';

export const SeeProfile = ({ editProfile, liveProfile }) => {
  return (
    <div className={styles.background}>
      <div className="spacing-mb-16">
        <span className={styles.text}>
          <b> Don't Miss Out on Potential Leads</b> <br />
          Complete and update your profile today!
        </span>
      </div>

      <div className={styles.buttons}>
        <Link to={editProfile}>
          <button className={`${styles.button} ${styles.upgrade}`} id="primaryProfile" type="button">
            Update Profile
          </button>
        </Link>
        <a className={`${styles.button} ${styles.viewProfile}`} href={liveProfile} target="_blank" rel="noreferrer">
          View Profile
        </a>
      </div>
    </div>
  );
};
