import React, { useState } from 'react';
import Modal from 'components/modal/Modal';
import LinkButton from 'components/button/v2/LinkButton';

const ModalUpgradeRequired = ({ school, isVisible, setShowModal, planToUpgrade }) => {
  const [selectedPlan, setSelectedPlan] = useState(planToUpgrade);
  const handleShowModal = () => setShowModal(false);

  const handlePlans = (plan) => {
    if (plan !== 'connect' && planToUpgrade) {
      setSelectedPlan(plan);
    } else if (plan === 'connect' && !planToUpgrade) {
      setSelectedPlan(plan);
    }
  };

  return (
    <Modal
      className="upgrade-schola-plan-modal"
      // isVisible={ isVisible }
      isVisible={false}
      onClose={handleShowModal}
      title="Upgrade Your Schola® Plan">
      <div className="row">
        <div className="d-flex justify-content-between align-items-center flex-colum flex-md-row w-100">
          <div
            className={
              selectedPlan === 'connect' && !planToUpgrade
                ? 'plan-container connect plan-container-selected'
                : 'plan-container disable-connect'
            }
            onClick={() => handlePlans('connect')}>
            <div className={planToUpgrade ? 'upgrade-to-recruitment' : null}>
              <p className="plan-price text-center">$5,000/year</p>
              <p className="plan-name text-center">ScholaConnect</p>
              <p className="plan-modal-description">
                Increase the number of qualified potential students or leads on a monthly basis.
              </p>
              <p className="plan-message btn btn-block py-2">Everything from ScholaBasics</p>
              <div className="d-flex justify-content-between my-2">
                <div className="or-line" />
                <p className="text-lightblack text-center plan-plus">PLUS</p>
                <div className="or-line" />
              </div>
              <div className="plan-vignetes d-flex justify-content-start align-items-center">
                <div className="vignete">
                  <i className="fa fa-circle" aria-hidden="true" />
                </div>
                <div className="vignete-description text-left">Guaranteed Student Connections</div>
              </div>
              <div className="plan-vignetes d-flex justify-content-start align-items-center">
                <div className="vignete">
                  <i className="fa fa-circle" aria-hidden="true" />
                </div>
                <div className="vignete-description text-left"> Featured School</div>
              </div>
              <div className="plan-vignetes d-flex justify-content-start align-items-center">
                <div className="vignete">
                  <i className="fa fa-circle" aria-hidden="true" />
                </div>
                <div className="vignete-description text-left">Customizable Application</div>
              </div>
            </div>
          </div>
          <div
            className={
              selectedPlan === 'recruiter' ? 'plan-container recruiter plan-container-selected' : 'plan-container'
            }
            onClick={() => handlePlans('recruiter')}>
            <p className="plan-price text-center">$7,500/year</p>
            <p className="plan-name text-center">ScholaRecruiter</p>
            <p className="plan-modal-description">
              Automate and streamline the recruitment & enrollment process to maximize student enrollment rate.
            </p>
            <p className="plan-message btn btn-block py-2">Everything from ScholaBasics</p>
            <div className="d-flex justify-content-between my-2">
              <div className="or-line" />
              <p className="text-lightblack text-center plan-plus">PLUS</p>
              <div className="or-line" />
            </div>
            <div className="plan-vignetes d-flex justify-content-start align-items-center">
              <div className="vignete">
                <i className="fa fa-circle" aria-hidden="true" />
              </div>
              <div className="vignete-description text-left">Full Relationship Manager (CRM)</div>
            </div>
            <div className="plan-vignetes d-flex justify-content-start align-items-center">
              <div className="vignete">
                <i className="fa fa-circle" aria-hidden="true" />
              </div>
              <div className="vignete-description text-left"> Tour Scheduler</div>
            </div>
            <div className="plan-vignetes d-flex justify-content-start align-items-center">
              <div className="vignete">
                <i className="fa fa-circle" aria-hidden="true" />
              </div>
              <div className="vignete-description text-left">Website Integration</div>
            </div>
          </div>
          <div
            className={selectedPlan === 'pro' ? 'plan-container pro plan-container-selected' : 'plan-container'}
            onClick={() => handlePlans('pro')}>
            <p className="plan-price text-center">$15,000/year</p>
            <p className="plan-name text-center">ScholaRecruiter Pro</p>
            <p className="plan-modal-description">
              Let Schola® help you manage your leads. Schola® takes on the student nurturing and follow-up process.
            </p>
            <p className="plan-message btn btn-block py-2">Everything from ScholaBasics</p>
            <div className="d-flex justify-content-between my-2">
              <div className="or-line" />
              <p className="text-lightblack text-center plan-plus">PLUS</p>
              <div className="or-line" />
            </div>
            <div className="plan-vignetes d-flex justify-content-start align-items-center">
              <div className="vignete">
                <i className="fa fa-circle" aria-hidden="true" />
              </div>
              <div className="vignete-description text-left"> Enrollment Advisor Parent Follow Up</div>
            </div>
            <div className="plan-vignetes d-flex justify-content-start align-items-center">
              <div className="vignete">
                <i className="fa fa-circle" aria-hidden="true" />
              </div>
              <div className="vignete-description text-left">Spanish Speaking Parent Support</div>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="buttons-upgrade d-flex justify-content-center mt-4 w-50">
            <LinkButton
              to={`/adminv2/schools/${school?.id}/dashboard-billing?selected=${selectedPlan}`}
              color="secondary"
              fullWidth
              onClick={() => {}}>
              Learn more
            </LinkButton>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="row px-1">
            <div className="col-12 panel-blue p-4">
              <div className="d-flex justify-content-md-between align-items-end flex-column flex-md-row justify-content-start">
                <div className="schoolabost-on">
                  <h5>ScholaBoosts</h5>
                  <br />
                  <p>Increase your number of qualified leads with ScholaBoost.</p>
                  <br />
                  <p>
                    At any time and with any plan, add ScholaBoost and increase the number of prospective students you
                    reach. Put your optimized campaign on turbo, You determine your monthly marketing spend to be in
                    complete control of your recruitment results.
                  </p>
                </div>
                {/* <div className="link-ineedthis">
                                <p>Do I need this?</p>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ModalUpgradeRequired;
