import { useToggle } from 'hooks/useToggle';
import React, { createContext } from 'react';
import useOnboardingState from '../hooks/useOnboardingState';

export const OnboargindContext = createContext({});

const OnboardingProvider = ({ children }) => {
  const onboardingState = useOnboardingState();

  const [isClaimOpen, toggleClaim] = useToggle(false);
  const claimFormHandler = { isClaimOpen, toggleClaim };

  return (
    <OnboargindContext.Provider value={{ onboardingState, claimFormHandler }}>{children}</OnboargindContext.Provider>
  );
};

export default OnboardingProvider;
