import { useState } from 'react';
import { MailOutlined, AppstoreOutlined, MessageOutlined } from '@ant-design/icons';

export const STAGES = {
  MODE: 'mode',
  PLATFORM: 'platform',
  TEMPLATE: 'template',
};
const MODES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced',
};
const CAMPAIGN_PLATFORM = {
  EMAIL: 'email',
  SMS: 'sms',
  HYBRID: 'hybrid',
};

export const useCampaignSettings = () => {
  const [stage, setStage] = useState(STAGES.MODE);

  const [settings, setSettings] = useState({
    mode: null,
    platform: null,
    template: {},
  });

  const backToMode = () => {
    const backToStage = stage === STAGES.TEMPLATE ? STAGES.PLATFORM : STAGES.MODE;
    setStage(backToStage);
  };

  const handleMode = (mode) => {
    setStage(STAGES.PLATFORM);
    setSettings({ ...settings, mode });
  };

  const handleType = (platform) => {
    setStage(STAGES.TEMPLATE);
    setSettings({ ...settings, platform });
  };

  const onTemplateSelect = (template) => {
    setSettings({ ...settings, template });
    const campaignPayload = {
      name: `Untitled ${settings.platform} Campaign`,
      platform: settings.platform,
      type: 'Regular Mass',
      layout_id: settings.template.layout_id,
      marketing_template_id: settings.template.id,
      audience_language: 'all',
      params: settings.template.params || [],
    };
    // let campaign = await _addMarketingCampaign(schoolId, newCampaignParams);
    console.log(campaignPayload);
  };

  const titles = {
    [STAGES.MODE]: 'Choose Campaign Mode',
    [STAGES.PLATFORM]: `Choose ${settings.mode} Campaign Type`,
    [STAGES.TEMPLATE]: `Choose ${settings.platform} Template`,
  };

  const optionsModes = [
    {
      id: 'simple',
      onClick: () => handleMode(MODES.SIMPLE),
      label: 'Simple',
      icon: MailOutlined,
      description: 'Basic single-channel campaigns for straightforward communication',
      colorIcon: 'blue',
    },
    {
      id: 'advanced',
      onClick: () => handleMode(MODES.ADVANCED),
      label: 'Advanced',
      icon: AppstoreOutlined,
      description: 'Design multi-channel campaigns with advanced conditions and automated workflows',
      colorIcon: 'purple',
    },
  ];

  const optionsSimple = [
    {
      id: 'email',
      onClick: () => handleType(CAMPAIGN_PLATFORM.EMAIL),
      label: 'Email',
      icon: MailOutlined,
      description: 'Send a single email campaign',
      colorIcon: 'blue',
    },
    {
      id: 'sms',
      onClick: () => handleType(CAMPAIGN_PLATFORM.SMS),
      label: 'SMS',
      icon: MessageOutlined,
      description: 'Send a single SMS campaign',
      colorIcon: 'green',
    },
  ];

  const optionsAdvanced = [
    {
      id: 'email',
      onClick: () => handleType(CAMPAIGN_PLATFORM.EMAIL),
      label: 'Email',
      icon: MailOutlined,
      description: 'Advanced email workflows with automation and conditions',
      colorIcon: 'blue',
    },
    {
      id: 'sms',
      onClick: () => handleType(CAMPAIGN_PLATFORM.SMS),
      label: 'SMS',
      icon: MessageOutlined,
      description: 'Complex sequences with branching logic',
      colorIcon: 'green',
    },
    {
      id: 'hybrid',
      onClick: () => handleType(CAMPAIGN_PLATFORM.HYBRID),
      label: 'Hybrid',
      icon: AppstoreOutlined,
      description: 'Multi-channel campaigns combining emails and SMS',
      colorIcon: 'purple',
    },
  ];

  return {
    stage,
    settings,
    backToMode,
    onTemplateSelect,
    // options:
    optionsModes,
    optionsSimple,
    optionsAdvanced,
    titles,
  };
};
