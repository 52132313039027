import React from 'react';
import { format } from 'date-fns';
import styles from './welcome-box.module.scss';

export const WelcomeBox = ({ name, lastLoginUser, isLastLoginSelected, children }) => {
  // const farmatedLastLogin = useMemo(() => {
  //   lastLoginUser && format(new Date(lastLoginUser), 'MMM d, yyyy')
  // }, [lastLoginUser]);
  return (
    <div className={styles.welcomeBox}>
      <h2>Welcome back, {name}</h2>
      {isLastLoginSelected ? (
        <p>
          Here's the latest and greatest since your last login on{' '}
          {lastLoginUser && format(new Date(lastLoginUser), 'MMM d, yyyy')}.
        </p>
      ) : (
        <p>Here's the latest and greatest during the selected time period:</p>
      )}

      {children}

      <div className={styles.illustrationWrapper} />
    </div>
  );
};
