export const allowedZipcodes = [
  '90220',
  '90221',
  '90248',
  '90222',
  '90262',
  '90280',
  '90201',
  '90255',
  '90301',
  '90305',
  '90047',
  '90002',
  '90248',
  '90061',
  '90043',
  '90011',
  '90037',
  '90062',
  '90016',
  '90012',
  '90017',
  '90071',
  '90014',
  '90015',
  '90079',
  '90013',
  '90021',
  '90033',
  '90026',
  '90057',
  '90005',
  '90006',
  '90018',
  '90007',
  '90089',
  '90037',
  '90062',
  '90212',
  '90211',
  '90035',
  '90034',
  '90019',
  '91202',
  '91201',
  '91502',
  '91506',
  '91505',
  '91606',
  '91601',
  '91602',
  '91607',
  '91605',
  '91352',
  '91367',
  '91307',
  '91304',
  '91303',
  '91306',
  '91371',
  '91364',
  '91356',
  '91335',
  '91324',
  '91330',
  '91326',
  '91344',
  '91325',
  '91406',
  '91316',
  '91436',
  '91403',
  '91411',
  '91405',
  '91402',
  '91434',
  '91345',
  '91331',
  '91401',
  '10468',
  '10458',
  '10460',
  '10472',
  '10473',
  '10453',
  '10457',
  '10452',
  '10456',
  '10459',
  '10451',
  '10455',
  '10454',
  '10474',
  '10034',
  '10040',
  '10033',
  '10032',
  '10031',
  '10039',
  '10030',
  '10037',
  '10027',
  '10115',
  '10026',
  '10025',
  '10024',
  '10023',
  '10069',
  '10019',
  '10029',
  '10035',
  '10128',
  '10028',
  '10075',
  '10021',
  '10065',
  '10022',
  '10019',
  '10153',
  '10103',
  '10036',
  '10017',
  '10018',
  '10016',
  '10001',
  '10119',
  '10199',
  '10011',
  '10010',
  '10003',
  '10009',
  '10014',
  '10012',
  '10013',
  '10002',
  '10278',
  '10007',
  '10282',
  '10279',
  '10282',
  '10280',
  '10006',
  '10271',
  '10038',
  '10005',
  '11224',
  '11235',
  '11223',
  '11214',
  '11425',
  '11209',
  '11228',
  '11229',
  '11204',
  '11219',
  '11220',
  '11230',
  '11210',
  '11234',
  '11218',
  '11232',
  '11226',
  '11236',
  '11203',
  '11225',
  '11215',
  '11231',
  '11217',
  '11238',
  '11216',
  '11213',
  '11212',
  '11233',
  '11205',
  '11206',
  '11221',
  '11231',
  '11201',
  '11207',
  '11239',
  '11211',
  '11222',
  '11237',
  '11207',
  '11239',
  '11208',
  '11414',
  '11420',
  '11417',
  '11416',
  '11419',
  '11418',
  '11421',
  '11415',
  '11424',
  '11385',
  '11375',
  '11379',
  '11378',
  '11374',
  '11373',
  '11368',
  '11369',
  '11371',
  '11372',
  '11370',
  '11377',
  '11104',
  '11101',
  '11109',
  '11106',
  '11102',
  '11103',
  '11105',
];
