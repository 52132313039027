import React from 'react';
import { browserHistory } from 'react-router';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useCampaignActions } from 'hooks/useCampaignActions';
import { TableHeader, CampaignStatus, CampaignActions, TablePagination } from './components';
import { getPathAdmin } from 'util/getPathAdmin';
import styles from './campaigns-table.module.scss';
import Button from 'components/button/Button';
import EmailRowAction from 'components/pages/campaigns/components/email/EmailRowAction';

export const CampaignsTable = ({ campaigns, setPagination, setSort, school_id, onChange, totalCampaigns }) => {
  if (!campaigns || campaigns.length === 0) {
    return null;
  }

  const formatDateToLocal = (dateString) => {
    if (dateString === null) {
      return null;
    }
    const date = new Date(dateString);
    return dayjs(date).format('MM-DD-YYYY hh:mm A');
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <TableHeader />
        <tbody>
          {campaigns.map((campaign) => (
            <tr key={campaign.id} className={styles.tableRow}>
              <td className={`${styles.tableCell} ${styles.primary}`}>
                <Button
                  color="ghost"
                  onClick={() =>
                    browserHistory.push(`/${getPathAdmin()}/schools/${school_id}/campaigns/dashboard/${campaign.id}`)
                  }
                  className="text-left">
                  {campaign.name}
                </Button>
              </td>
              <td className={`${styles.tableCell} ${styles.capitalize}`}>{campaign.platform}</td>
              <td className={`${styles.tableCell} ${styles.capitalize}`}>{campaign.mode}</td>
              <td className={styles.tableCell}>{campaign.audience}</td>
              <td className={styles.tableCell}>{campaign.executions}</td>
              <td className={styles.tableCell}>{campaign.sentTo}</td>
              <td className={styles.tableCell}>{formatDateToLocal(campaign.lastEdited)}</td>
              <td className={styles.tableCell}>{formatDateToLocal(campaign.lastSent)}</td>
              <td className={styles.tableCell}>
                <CampaignStatus status={campaign.status} />
              </td>
              <td className={styles.tableCell}>
                <EmailRowAction
                  row={campaign.row}
                  school_id={school_id}
                  router={browserHistory}
                  onLoadInfo={() => {
                    onChange();
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <TablePagination totalItems={totalCampaigns} setPagination={setPagination} setSort={setSort} />
    </div>
  );
};

CampaignsTable.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      mode: PropTypes.string.isRequired,
      audience: PropTypes.string.isRequired,
      executions: PropTypes.string.isRequired,
      sentTo: PropTypes.string.isRequired,
      lastEdited: PropTypes.string,
      lastSent: PropTypes.string,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};
