import client from '../client';

export const BATCH_EDIT_LEADS = 'BATCH_EDIT_LEADS';
export const EXPORT_APPLICATIONS = 'EXPORT_APPLICATIONS';
export const GET_COMPLETED_APPLICATION = 'GET_COMPLETED_APPLICATION';
export const GET_LEADS = 'GET_LEADS';
export const GET_POTENTIAL_LEADS = 'GET_POTENTIAL_LEADS';
export const SEND_APPLICATIONS = 'SEND_APPLICATIONS';
export const UPDATE_LEADS_NOTE = 'UPDATE_LEADS_NOTE';
export const GET_LEADS_COUNTS = 'GET_LEADS_COUNTS';
export const GET_LEADS_HISTORY = 'GET_LEADS_HISTORY';
export const CREATE_LEAD = 'CREATE_LEAD';
export const GET_LEAD_FIELDS = 'GET_LEAD_FIELDS';
export const SET_LEAD_FIELDS = 'SET_LEAD_FIELDS';
export const GET_LEAD_SOURCES = 'GET_LEAD_SOURCES';
export const ADD_LEAD_SOURCE = 'ADD_LEAD_SOURCE';
export const GET_LEAD_STATUSES = 'GET_LEAD_STATUSES';
export const ADD_LEAD_STATUS = 'ADD_LEAD_STATUS';
export const GET_EXPORT_TYPE_APPLICATIONS = 'GET_EXPORT_TYPE_APPLICATIONS';
export const GET_LONG_URL = 'GET_LONG_URL';
export const CREATE_SF_LEAD = 'CREATE_SF_LEAD';
export const GET_LEAD_REASONS = 'GET_LEAD_REASONS';
export const CREATE_LEAD_REASON = 'CREATE_LEAD_REASON';
export const DELETE_LEADS = 'DELETE_LEADS';
export const GET_COUNT_LATEST_NEW_LEADS = 'GET_COUNT_LATEST_NEW_LEADS';

export function getLeads(
  schoolId,
  status = 'new',
  page = 1,
  pageSize = 10,
  text = '',
  year = '',
  grade = '',
  fieldSort = '',
  fieldDirection = ''
) {
  return {
    type: GET_LEADS,
    promise: client.get(`schools/${schoolId}/leads`, {
      status,
      page,
      pageSize,
      text,
      year,
      grade,
      fieldSort,
      fieldDirection,
    }),
  };
}

export function getPotentialLeads(schoolId, page = 1, pageSize = 10, text = '', fieldSort = '', fieldDirection = '') {
  return {
    type: GET_POTENTIAL_LEADS,
    promise: client.get(`schools/${schoolId}/potential-leads`, { page, pageSize, text, fieldSort, fieldDirection }),
  };
}

export function batchEditLeads(schoolId, leads, values, createdBy) {
  return {
    type: BATCH_EDIT_LEADS,
    promise: client.patch(`schools/${schoolId}/leads/batch`, { leads, values, createdBy }),
  };
}

export function sendApplications(schoolId, leads, emailRequired = true, loggedId) {
  return {
    type: SEND_APPLICATIONS,
    promise: client.post(`schools/${schoolId}/leads/send-apps`, { leads, emailRequired, logged_id: loggedId }),
  };
}

export function getCompletedApplication(id) {
  return {
    type: GET_COMPLETED_APPLICATION,
    promise: client.get(`completed-applications/${id}`),
  };
}

export function updateLeadNotes(schoolId, leadId, note) {
  return {
    type: UPDATE_LEADS_NOTE,
    promise: client.post(`schools/${schoolId}/${leadId}/note`, { note }),
  };
}

export function getLeadsStatusCount(schoolId) {
  return {
    type: GET_LEADS_COUNTS,
    promise: client.get(`schools/${schoolId}/leads/counts`),
  };
}

export function getLeadsHistory(schoolId) {
  return {
    type: GET_LEADS_HISTORY,
    promise: client.get(`schools/${schoolId}/leads/history`),
  };
}

export function createLead(payload) {
  return {
    type: CREATE_LEAD,
    promise: client.post('leads', payload),
  };
}

export function getLeadFields(schoolId) {
  return {
    type: GET_LEAD_FIELDS,
    promise: client.get(`schools/${schoolId}/leadfields`),
  };
}

export function setLeadFields(schoolId, payload) {
  return {
    type: SET_LEAD_FIELDS,
    promise: client.post(`schools/${schoolId}/leadfields`, payload),
  };
}

export function getLeadSources(schoolId) {
  return {
    type: GET_LEAD_SOURCES,
    promise: client.get(`schools/${schoolId}/leadsources`),
  };
}

export function addLeadSource(schoolId, payload) {
  return {
    type: ADD_LEAD_SOURCE,
    promise: client.post(`schools/${schoolId}/leadsource`, payload),
  };
}

export function getLeadStatuses(schoolId) {
  return {
    type: GET_LEAD_STATUSES,
    promise: client.get(`schools/${schoolId}/leadstatuses`),
  };
}

export function addLeadStatus(schoolId, payload) {
  return {
    type: ADD_LEAD_STATUS,
    promise: client.post(`schools/${schoolId}/leadstatus`, payload),
  };
}

export function getExportTypeApplicationsForSchool(schoolId) {
  return {
    type: GET_EXPORT_TYPE_APPLICATIONS,
    promise: client.get(`schools/${schoolId}/leads/export/type-applications`),
  };
}

export function getLongUrl(shortid) {
  return {
    type: GET_LONG_URL,
    promise: client.get(`url/${shortid}`),
  };
}

export function createSFLead(oType, data) {
  var payload = new FormData();
  payload.append('name', data.name);
  payload.append('email', data.email);
  payload.append('phone', data.phone);
  payload.append('school', data.school);
  return {
    type: CREATE_SF_LEAD,
    promise: client.post(`sf/${oType}/leads`, payload),
  };
}

export function getLeadReasons(schoolId) {
  return {
    type: GET_LEAD_REASONS,
    promise: client.get(`reason/school/${schoolId}/lead`),
  };
}

export function createLeadReason(schoolId, name) {
  var payload = new FormData();
  payload.append('type', 'lead');
  payload.append('schoolId', schoolId);
  payload.append('name', name);
  return {
    type: CREATE_LEAD_REASON,
    promise: client.post(`reason`, payload, { credentials: 'omit' }),
  };
}

export function deleteLeads(schoolId, leadIds, deletedBy) {
  return {
    type: DELETE_LEADS,
    promise: client.post(`schools/${schoolId}/delete-leads`, { leadIds, deletedBy }, { credentials: 'omit' }),
  };
}

export function getCountLatestNewLeads(schoolId, sinceDate) {
  return {
    type: GET_COUNT_LATEST_NEW_LEADS,
    promise: client.post(
      `schools/${schoolId}/lead/count-latest-new-leads`,
      { since_date: sinceDate },
      { credentials: 'omit' }
    ),
  };
}
