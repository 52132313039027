import React from 'react';
import styles from './container.module.scss';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router';

export const Container = ({ children, title, className, link, extraLabel }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {title && (
        <Header title={title}>
          {link && (
            <Link to={link} className={styles.link}>
              {extraLabel && <span className={styles.label}>{extraLabel}</span>}
              <ExportOutlined style={{ color: 'rgba(0, 107, 143, 1)' }} />
            </Link>
          )}
        </Header>
      )}
      {children}
    </div>
  );
};

const Header = ({ title, children }) => {
  return (
    <div className={styles.header}>
      <span className={styles.title}>{title}</span>
      {children}
    </div>
  );
};

const Body = ({ children, className, withoutTopPadding = false }) => {
  return (
    <div style={withoutTopPadding ? { paddingTop: 0 } : {}} className={`${styles.body} ${className}`}>
      {children}
    </div>
  );
};

Container.Header = Header;
Container.Body = Body;
