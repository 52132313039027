import React from 'react';

const PaperAirplane = () => {
  return (
    <svg width="307" height="195" viewBox="-4 -4 307 195" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M74.2213 111.9928l73.2875 74.3215c1.192 1.2087 3.019 1.1103 4.0797-.2212L298.0205 2.277c1.0615-1.3325.554-2.2483-1.1316-2.0476L2.0262 35.321c-1.6854.2005-2.0855 1.3435-.893 2.5528l51.364 52.0887L268.671 15.192c1.5996-.5718-194.4497 96.8008-194.4497 96.8008z"
          stroke="#4A4A4A"
          strokeWidth="7.6"
          fill="#F7F9FC"
        />
        <g stroke="#4A4A4A" strokeWidth="7.6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M52.5775 89.5954l9.746 62.383 31.0336-20.7943" />
          <path d="M72.839 111.93l-9.233 39.535" />
        </g>
        <path fill="#D4DCE9" d="M56.962 94.8902l6.1673 41.837 5.2863-23.144 148.4554-76.553" />
        <path fill="#9AA2AE" d="M69.3038 142.899l18.038-12.3434L75 118.212" />
      </g>
    </svg>
  );
};

export default PaperAirplane;
