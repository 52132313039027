import React from 'react';

const Private = () => {
  /* eslint-disable max-len */
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="#8AC340">
        <path d="M9.19354839,26.7500003 L7.94354839,28.0000025 L7.94354619,26.7500003 L9.19354839,26.7500003 Z M1.25,26.7500003 L7.94354619,26.7500003 L7.94352416,14.1971589 C7.94351781,10.5797125 10.869553,7.65733728 14.4882274,7.66689644 C18.1611854,7.67659901 21.0887126,10.6176597 21.088719,14.2301368 L21.088741,26.7500003 L27.7862078,26.7500003 L27.7862078,14.0000001 C27.7862078,6.95607372 22.078879,1.25 15.0284126,1.25 L14.0077953,1.25 C6.96109282,1.25 1.25,6.9574359 1.25,14.0000001 L1.25,26.7500003 Z M0,28.0000003 L0,14.0000001 C0,6.26631351 6.27150357,0 14.0077953,0 L15.0284126,0 C22.7715313,0 29.0362078,6.26801356 29.0362078,14.0000001 L29.0362078,28.0000003 L19.8387432,28.0000003 L19.838719,14.230139 C19.8387139,11.3075764 17.4696645,8.92477663 14.5472992,8.91705685 C11.5560286,8.90915505 9.19351903,11.2732273 9.19352416,14.1971567 L9.19354839,28.0000003 L0,28.0000003 Z" />
        <path d="M.5 21.25L8.91935 21.25 8.91935 20 .5 20M20.5 21.25L28.75806 21.25 28.75806 20 20.5 20" />
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default Private;
