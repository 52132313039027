import React from 'react';

const Hamburger = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 24 24">
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  );
  /* eslint-enable */
};

export default Hamburger;
