import { Form, Input, Typography } from 'antd';

export const InputCampaign = ({ id, label, value, onChange }) => {
  return (
    <div className="" style={{ width: '100%' }}>
      <label htmlFor={id}>{label}</label>
      <Input
        id={id}
        value={value}
        onChange={(e) => onChange({ key: id, value: e.target.value })}
        styles={{ width: '100%' }}
      />
    </div>
  );
};

const { Title, Paragraph } = Typography;

export const SectionTitle = ({ children }) => (
  <Title level={5} style={{ fontSize: '1rem' }}>
    {children}
  </Title>
);
