import React from 'react';

const AddPhoto = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 33 29">
      <path d="M4.17391304 4.14285714V0h2.7826087v4.14285714h4.17391306V6.9047619H6.95652174v4.1428571h-2.7826087V6.9047619H0V4.14285714h4.17391304zM8.3478261 12.4285714V8.2857143h4.173913V4.14285713h9.7391305l2.5460868 2.76190476h4.4104349C30.7478265 6.9047619 32 8.14761916 32 9.66666666V26.2380952C32 27.7571433 30.7478265 29 29.2173913 29H6.95652174c-1.53043466 0-2.7826087-1.2428567-2.7826087-2.7619048V12.4285714H8.3478261zm9.7391304 12.4285715c3.8400003 0 6.9565218-3.0933331 6.9565218-6.9047619 0-3.811429-3.1165215-6.904762-6.9565218-6.904762-3.8400004 0-6.9565217 3.093333-6.9565217 6.904762 0 3.8114288 3.1165213 6.9047619 6.9565217 6.9047619zm-4.4521736-6.9047619c0 2.4442862 1.9895655 4.4190485 4.4521736 4.4190485 2.462608 0 4.4521749-1.9747623 4.4521749-4.4190473s-1.9895656-4.4190473-4.4521736-4.4190473c-2.462608 0-4.4521736 1.9747623-4.4521736 4.4190473l-.0000013-.0000012z" />
    </svg>
  );
  /* eslint-enable */
};

export default AddPhoto;
