import { tString } from 'components/translation/Translation';

export const roles = [
  { label: '-', value: '' },
  { label: tString('home:waitlist.roles.principal'), value: 'Principal' },
  { label: tString('home:waitlist.roles.vicePrincipal'), value: 'Vice Principal' },
  { label: tString('home:waitlist.roles.assistantPrincipal'), value: 'Assistant Principal' },
  { label: tString('home:waitlist.roles.officeManager'), value: 'Office Manager' },
  { label: tString('home:waitlist.roles.operations'), value: 'Director of Operations' },
  { label: tString('home:waitlist.roles.outreach'), value: 'Parent Coordinator/Outreach' },
  { label: tString('home:waitlist.roles.marketing'), value: 'Marketing and Communications' },
  { label: tString('home:waitlist.roles.superintendent'), value: 'Superintendent' },
  { label: tString('home:waitlist.roles.administrative'), value: 'Administrative Assistant' },
  { label: tString('home:waitlist.roles.enrollmentCoordinator'), value: 'Enrollment and Recruitment Coordinator' },
  { label: tString('home:waitlist.roles.teacher'), value: 'Teacher' },
  { label: tString('home:waitlist.roles.other'), value: 'Other' },
];
