import { store } from '../store';

export const OPEN_CAMPAIGN_MODAL = 'OPEN_CAMPAIGN_MODAL';
export const CLOSE_CAMPAIGN_MODAL = 'CLOSE_CAMPAIGN_MODAL';

// actions
export const onOpenEditModal = (nodeId) => store.dispatch({ type: OPEN_CAMPAIGN_MODAL, payload: nodeId });
export const onCloseEditModal = () => store.dispatch({ type: CLOSE_CAMPAIGN_MODAL });

const initialState = {
  isModalOpen: false,
  nodeId: null,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CAMPAIGN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        nodeId: action.payload,
      };
    case CLOSE_CAMPAIGN_MODAL:
      return {
        ...state,
        isModalOpen: false,
        nodeId: null,
      };
    default:
      return state;
  }
};

export default campaignReducer;
