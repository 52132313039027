import React from 'react';

const Charter = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 36 35">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#8AC340"
        strokeWidth="1.2023">
        <path d="M5.35689642 18.2533293v15.6314634H30.7980696V18.2533293" />
        <path d="M34.5566925 20.246939l-16.4794298-9.0790244L1.59783284 20.246939M11.3113448 5.39979268v9.33695122m13.5324967-.0001098V5.39968293m1.9990988 1.02907317l-8.7656776-4.82817073L9.31158507 6.4287561M30.7982899 22.9157683H5.35711672M24.8438415 8.41380488H11.3109042m3.4971286 25.47098782h6.2983344V22.9157683h-6.2983344zm-6.2994358 0h6.2983343V22.9157683H8.508597z" />
        <path d="M21.1052657 33.8847927H27.4036V22.9157683h-6.2983343z" />
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default Charter;
