import React from 'react';
import PropTypes from 'prop-types';
import styles from './last-login-box.module.scss';
import classNames from 'classnames';

export const LastLoginBox = ({ children, lastLoginDate, backgroundColor }) => {
  const background = {
    [styles.turquoise]: backgroundColor === 'turquoise',
    [styles.blue]: backgroundColor === 'blue',
    [styles.green]: backgroundColor === 'green',
  };

  return (
    <div className={classNames(styles.box, background)}>
      <span className={styles.box_title}>{children}</span>
      <p>Since Last Login on {lastLoginDate}</p>
    </div>
  );
};

LastLoginBox.prototype = {
  backgroundColor: PropTypes.oneOf(['turquoise', 'blue', 'green']),
};
