import { useEffect, useMemo, useState } from 'react';
import { sub, format } from 'date-fns';

export const useRangeDates = (staticRanges) => {
  const [range, setRange] = useState({
    id: null,
    value: null,
    label: '',
  });

  //* Set the initial range by default only for the first mount */
  useEffect(() => {
    handleRange(staticRanges[0]);
  }, [staticRanges]);

  //* handler function to update the main state */
  const handleRange = ({ value, label, id }) => {
    setRange({
      id,
      value,
      label,
    });
  };

  return { range, handleRange };
};

//* * Receive a date and return the subtracted time from today */
function getRangeDate({ sinceDateRange }) {
  const today = new Date();
  const rangeDateRaw = sub(today, sinceDateRange);
  // const previousRangeDate = { months: sinceDateRange.months * 2 };

  // const previousPeriod = getFormat(sub(today, previousRangeDate));

  // const datesComparative = getDisplayDates(today, rangeDateRaw);

  return { rangeDate: getFormat(rangeDateRaw), previousPeriod: null, datesComparative: null };
}

// export const getFormat = (date) => format(date, 'yyyy/MM/dd');

const getDisplayDates = (today, previousDate) => {
  const formatDate = 'MMM d, yyyy';
  const from = format(previousDate, formatDate);
  const to = format(today, formatDate);
  return `${from} - ${to}`;
};
