import React from 'react';
import PropTypes from 'prop-types';
import styles from './campaign-status.module.scss';

export const CampaignStatus = ({ status }) => {
  return (
    <span
      className={`${styles.status} ${
        status === 'Active' ? styles.active : status === 'Inactive' ? styles.inactive : styles.pending
      }`}>
      {status}
    </span>
  );
};

CampaignStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
