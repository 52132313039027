import React, { useState } from 'react';
import { Modal, InputText, Buttonv2 as Button } from 'schola-components';
import { allowedZipcodes } from './utils/allowedZipcodes';
import { WaitlistForm } from './waitlist-form/waitlist-form';
import { CheckmarkFilled32 } from '@carbon/icons-react';
import { t } from 'components/translation/Translation';
import styles from './waitlist-schola-plus.module.scss';
import { isInAreaZip } from './utils';

export const statusType = {
  previousWaitlist: 'previousWaitlist',
  zipcode: 'zipcode',
  formWaitlist: 'formWaitlist',
  success: 'success',
};

export const WaitlistScholaPlus = ({
  isScholaPlusOpen,
  toggleScholaPlus,
  schoolId,
  initialStep = statusType.previousWaitlist,
  showSchoolSearcher = false,
}) => {
  const url = 'https://pages.schola.com/meetings/schola-solutions/schedule-demo';

  const [statusState, setStatusState] = useState(initialStep);

  const [zipcodeState, setZipcodeState] = useState('');

  const handleZipcode = ({ target }) => {
    const { value } = target;

    if (value.length <= 5) setZipcodeState(value);

    if (value.length === 5) isInArea(value);
  };

  const isInArea = (zipcode) => {
    allowedZipcodes.includes(zipcode) ? window.open(url, '_blank').focus() : setStatusState(statusType.formWaitlist);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    isInAreaZip(zipcodeState, () => {
      setStatusState(statusType.formWaitlist);
    });
  };

  const modalTitles = {
    zipcode: t('home:waitlist.zipcodeTitle'),
    previousWaitlist: t('home:waitlist.joinTitle'),
    formWaitlist: t('home:waitlist.joinTitle'),
    success: null,
  };

  const onBack = () => setStatusState(statusType.zipcode);
  const onGoForm = () => setStatusState(statusType.formWaitlist);
  const onSuccessWaitList = () => setStatusState(statusType.success);

  return (
    <Modal onClose={toggleScholaPlus} isVisible={isScholaPlusOpen} title={modalTitles[statusState]}>
      {statusState === statusType.previousWaitlist && (
        <div className={styles.previousContent}>
          <p className="spacing-my-24">
            ScholaPlus™ Plan is now available only in NY and LA, join the waitlist to get notified when it becomes
            available in your city.
          </p>
          <div className={styles.zipcode_buttons}>
            <Button color="secondary" onClick={toggleScholaPlus}>
              {t('home:waitlist.cancel')}
            </Button>
            <Button onClick={onGoForm}>{t('home:waitlist.confirm')}</Button>
          </div>
        </div>
      )}
      {statusState === statusType.zipcode && (
        <form className={styles.zipcode} id="zipcode" onSubmit={handleSubmit}>
          <InputText autoFocus type="number" onChange={handleZipcode} value={zipcodeState} className="spacing-my-24" />

          <div className={styles.zipcode_buttons}>
            <Button color="secondary" onClick={toggleScholaPlus} fullWidth>
              {t('home:waitlist.cancel')}
            </Button>
            <Button type="submit" fullWidth disabled={zipcodeState.length < 5}>
              {t('home:waitlist.confirm')}
            </Button>
          </div>
        </form>
      )}

      {statusState === statusType.formWaitlist && (
        <WaitlistForm
          onBack={onBack}
          initialStep={initialStep}
          onSuccessWaitList={onSuccessWaitList}
          schoolId={schoolId}
          showSchoolSearcher={showSchoolSearcher}
        />
      )}

      {statusState === statusType.success && (
        <div className={styles.success}>
          <CheckmarkFilled32 fill="#0099CC" />
          <h3 className="spacing-mt-24">{t('home:waitlist.successTitle')}</h3>
          <p className="body-medium spacing-my-24">{t('home:waitlist.successDescription')}</p>
          <Button onClick={toggleScholaPlus} fullWidth>
            Ok
          </Button>
        </div>
      )}
    </Modal>
  );
};
