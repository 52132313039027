import { _getMarketingCampaigns } from 'controllers/schools/schools_controller';
import { useStatus } from 'hooks/useStatus';
import { useEffect, useState } from 'react';

export const useCampaignStatus = ({ schoolId }) => {
  const [campaigns, setCampaigns] = useState([]);
  const { onLoading, onSuccess, onError, status } = useStatus();

  useEffect(() => {
    onLoading();

    getCampaigns(schoolId)
      .then((data) => {
        setCampaigns(data);
        onSuccess();
      })
      .catch((err) => {
        onError();
        console.log('Failed campaigns, ', err.message);
      });

    return () => {};
  }, []);

  return { campaigns, isLoading: status.loading, isSuccess: status.success };
};

const getCampaigns = async (schoolId) => {
  let payload = {
    school_id: schoolId,
    page: 1,
    pageSize: 10,
    platform: 'email',
    archived: false,
  };

  const campaigns = await _getMarketingCampaigns(payload);
  return campaigns;
};
