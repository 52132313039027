import React from 'react';
import { Dropdown, Menu } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const DropdownMenu = React.forwardRef(({ children, trigger }, ref) => {
  // Separar el trigger y el contenido del menú
  const [triggerElement, menuContent] = React.Children.toArray(children);

  return (
    <Dropdown ref={ref} menu={{ items: menuContent.props.children }} trigger={['click']}>
      {triggerElement}
    </Dropdown>
  );
});

const DropdownMenuTrigger = ({ children, asChild, ...props }) => <div {...props}>{children}</div>;

const DropdownMenuGroup = Menu.ItemGroup;
const DropdownMenuPortal = ({ children }) => children;
const DropdownMenuSub = Menu.SubMenu;
const DropdownMenuRadioGroup = Menu.ItemGroup;

const DropdownMenuSubTrigger = React.forwardRef(({ inset, children, ...props }, ref) => (
  <Menu.SubMenu ref={ref} title={children} icon={<RightOutlined />} {...props} />
));

DropdownMenuSubTrigger.displayName = 'DropdownMenuSubTrigger';

const DropdownMenuSubContent = React.forwardRef(({ children, ...props }, ref) => (
  <Menu ref={ref} {...props}>
    {children}
  </Menu>
));

DropdownMenuSubContent.displayName = 'DropdownMenuSubContent';

const DropdownMenuContent = React.forwardRef(({ sideOffset = 4, children, ...props }, ref) => (
  <Menu ref={ref} {...props}>
    {children}
  </Menu>
));

DropdownMenuContent.displayName = 'DropdownMenuContent';

const DropdownMenuItem = React.forwardRef(({ inset, children, ...props }, ref) => (
  <Menu.Item key={props} ref={ref} {...props}>
    {children}
  </Menu.Item>
));

DropdownMenuItem.displayName = 'DropdownMenuItem';

const DropdownMenuCheckboxItem = React.forwardRef(({ children, checked, ...props }, ref) => (
  <Menu.Item ref={ref} {...props}>
    <span style={{ marginRight: 8 }}>{checked ? '✓' : null}</span>
    {children}
  </Menu.Item>
));

DropdownMenuCheckboxItem.displayName = 'DropdownMenuCheckboxItem';

const DropdownMenuRadioItem = React.forwardRef(({ children, checked, ...props }, ref) => (
  <Menu.Item ref={ref} {...props}>
    <span style={{ marginRight: 8 }}>{checked ? '●' : '○'}</span>
    {children}
  </Menu.Item>
));

DropdownMenuRadioItem.displayName = 'DropdownMenuRadioItem';

const DropdownMenuLabel = React.forwardRef(({ inset, children, ...props }, ref) => (
  <Menu.ItemGroup ref={ref} title={children} {...props} />
));

DropdownMenuLabel.displayName = 'DropdownMenuLabel';

const DropdownMenuSeparator = React.forwardRef((props, ref) => <Menu.Divider ref={ref} {...props} />);

DropdownMenuSeparator.displayName = 'DropdownMenuSeparator';

const DropdownMenuShortcut = ({ className, ...props }) => <span style={{ float: 'right', color: '#999' }} {...props} />;

DropdownMenuShortcut.displayName = 'DropdownMenuShortcut';

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
};
