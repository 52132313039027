import React, { useEffect, useState } from 'react';
import { Container, LabelInfo } from '..';
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import styles from './performance-timeline.module.scss';
import { statusColor } from 'constants/status-color';

const isDataToDisplay = (data = []) => {
  const isValid = (value) => Number(value) > 0;

  let valid = false;

  for (const item of data) {
    if (isValid(item.Leads) || isValid(item.Applications) || isValid(item.Enrollments)) {
      valid = true;
      break;
    }
  }

  return valid;
};

export const PerformanceTimeline = ({ timeline, className, link }) => {
  const [isContainingData, setContain] = useState(true);
  useEffect(() => {
    const timelineContainValues = isDataToDisplay(timeline);
    !timelineContainValues ? setContain(true) : setContain(false);
  }, [timeline]);

  return (
    <Container title="Performance Timeline" className={className} link={link} extraLabel="View in Reports">
      <Container.Body className={styles.container}>
        {isContainingData && <LabelInfo classNameLabel={styles.noDataLabel} />}
        <div className={styles.timeline}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={timeline} margin={{ top: 10, right: 30, left: -20, bottom: 0 }}>
              <defs>
                <linearGradient id="colorLeads" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="15%" stopColor={statusColor.new} stopOpacity={0.2} />
                  <stop offset="100%" stopColor={statusColor.new} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorApps" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="15%" stopColor={statusColor['application-received']} stopOpacity={0.2} />
                  <stop offset="100%" stopColor={statusColor['application-received']} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorEnrollment" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="15%" stopColor={statusColor.accepted} stopOpacity={0.2} />
                  <stop offset="100%" stopColor={statusColor.accepted} stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" tickLine={false} tick={{ fontSize: '12px' }} />
              <YAxis type="number" tickLine={false} tick={{ fontSize: '12px' }} />
              {!isContainingData && <Tooltip />}
              <Legend
                align="right"
                layout="vertical"
                verticalAlign="middle"
                iconSize={8}
                iconType="circle"
                wrapperStyle={{ right: '9px', top: '12px' }}
              />
              <Area type="linear" dataKey="Leads" stroke={statusColor.new} fillOpacity={1} fill="url(#colorLeads)" />
              <Area
                type="linear"
                dataKey="Applications"
                stroke={statusColor['application-received']}
                fillOpacity={1}
                fill="url(#colorApps)"
              />
              <Area
                type="linear"
                dataKey="Enrollments"
                stroke={statusColor.accepted}
                fillOpacity={1}
                fill="url(#colorEnrollment)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Container.Body>
    </Container>
  );
};
