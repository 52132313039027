const SCHOOL_LOGO_KEY = '{{logo}}';
const HEADER_TEXT_KEY = '{{header_text}}';
const MESSAGE_TEXT_KEY = '{{message_text}}';
const PRIMARY_COLOR_KEY = '{{primary_color}}';
const SECONDARY_COLOR_KEY = '{{secondary_color}}';
const MEDIA_FACEBOOK_KEY = '{{facebook}}';
const MEDIA_INSTAGRAM_KEY = '{{instagram}}';
const MEDIA_TWITTER_KEY = '{{twitter}}';
const MEDIA_YOUTUBE_KEY = '{{youtube}}';
const EMAIL_IMAGE_1_KEY = '{{email_image}}';

const SUBJECT_KEY = '{{subject}}';
const NAME_KEY = '{{from_name}}';
const EMAIL_KEY = '{{from_email}}';

const SCHEDULE_KEY = '{{schedule}}';
const TRIGGER_KEY = '{{trigger}}';
const SENT_TO_KEY = '{{sent_to}}';

const MESSAGE_2_KEY = '{{message_2_text}}';
const MESSAGE_3_KEY = '{{message_3_text}}';
const EMAIL_IMAGE_2_KEY = '{{email_image_2}}';
const EMAIL_IMAGE_3_KEY = '{{email_image_3}}';

const HEADER_KEY = '{{header_text}}';
const MESSAGE_KEY = '{{message_text}}';
const CTA_TYPE_KEY = '{{call_to_action_type}}';
const CTA_CUSTOM_BTN_TEXT_KEY = '{{cta_custom_btn_text}}';
const CTA_CUSTOM_URL_KEY = '{{cta_custom_url_link}}';
const CTA_CUSTOM_PROTO_KEY = '{{cta_custom_protocole_key';
const SMS_URL_LINK_KEY = '{{sms_url_link}}';

const SIGNATURE_TYPE_KEY = '{{signature_type}}';
const SIGNATURE_LABEL_KEY = '{{signature_label}}';
const SIGNATURE_NAME_KEY = '{{signature_name}}';

const REQUIRED = [
  SUBJECT_KEY,
  NAME_KEY,
  EMAIL_KEY,
  HEADER_KEY,
  MESSAGE_KEY,
  CTA_TYPE_KEY,
  CTA_CUSTOM_BTN_TEXT_KEY,
  CTA_CUSTOM_PROTO_KEY,
  CTA_CUSTOM_URL_KEY,
  SMS_URL_LINK_KEY,
  SCHEDULE_KEY,
  TRIGGER_KEY,
  SENT_TO_KEY,
  SIGNATURE_TYPE_KEY,
  SIGNATURE_LABEL_KEY,
  SIGNATURE_NAME_KEY,
];
const REQUIRED_KEYS = {
  SUBJECT_KEY,
  NAME_KEY,
  EMAIL_KEY,
  HEADER_KEY,
  MESSAGE_KEY,
  CTA_TYPE_KEY,
  CTA_CUSTOM_BTN_TEXT_KEY,
  CTA_CUSTOM_URL_KEY,
  CTA_CUSTOM_PROTO_KEY,
  SMS_URL_LINK_KEY,
  SCHEDULE_KEY,
  TRIGGER_KEY,
  SENT_TO_KEY,
  SIGNATURE_TYPE_KEY,
  SIGNATURE_LABEL_KEY,
  SIGNATURE_NAME_KEY,
};

export const LAYOUTS = [
  {
    id: 1,
    name: '1 Column',
    icon: 'https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/1+column.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    params: [
      ...REQUIRED,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
    ],
    keys: {
      ...REQUIRED_KEYS,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
    },
  },
  {
    id: 2,
    name: '2 Column',
    icon: 'https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/1_2+column.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    params: [
      ...REQUIRED,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      MESSAGE_2_KEY,
      EMAIL_IMAGE_2_KEY,
    ],
    keys: {
      ...REQUIRED_KEYS,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      MESSAGE_2_KEY,
      EMAIL_IMAGE_2_KEY,
    },
  },
  {
    id: 3,
    name: '2 Column',
    icon: 'https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/1_2_1+column.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    params: [
      ...REQUIRED,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      EMAIL_IMAGE_2_KEY,
    ],
    keys: {
      ...REQUIRED_KEYS,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      EMAIL_IMAGE_2_KEY,
    },
  },
  {
    id: 4,
    name: '3 Column',
    icon: 'https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/1_3+column.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    params: [
      ...REQUIRED,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      EMAIL_IMAGE_2_KEY,
      MESSAGE_2_KEY,
      EMAIL_IMAGE_3_KEY,
      MESSAGE_3_KEY,
    ],
    keys: {
      ...REQUIRED_KEYS,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      EMAIL_IMAGE_2_KEY,
      MESSAGE_2_KEY,
      EMAIL_IMAGE_3_KEY,
      MESSAGE_3_KEY,
    },
  },
  {
    id: 5,
    name: '3 Column',
    icon: 'https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/1_3_1+column.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    params: [
      ...REQUIRED,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      EMAIL_IMAGE_2_KEY,
      MESSAGE_2_KEY,
      EMAIL_IMAGE_3_KEY,
      MESSAGE_3_KEY,
    ],
    keys: {
      ...REQUIRED_KEYS,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
      EMAIL_IMAGE_1_KEY,
      EMAIL_IMAGE_2_KEY,
      EMAIL_IMAGE_3_KEY,
    },
  },
  {
    id: 6,
    name: '1 Column',
    icon: 'https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/Single+text.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    params: [
      ...REQUIRED,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
    ],
    keys: {
      ...REQUIRED_KEYS,
      SCHOOL_LOGO_KEY,
      HEADER_TEXT_KEY,
      MESSAGE_TEXT_KEY,
      PRIMARY_COLOR_KEY,
      SECONDARY_COLOR_KEY,
      MEDIA_FACEBOOK_KEY,
      MEDIA_INSTAGRAM_KEY,
      MEDIA_TWITTER_KEY,
      MEDIA_YOUTUBE_KEY,
    },
  },
];
