import { useEffect, useState } from 'react';
import { _getPerformanceByStatus } from 'controllers/dashboard/dashboard_controller';
import { getFormat } from '.';
import { add } from 'date-fns';
import { useStatus } from 'hooks/useStatus';

export const usePerformanceByStatus = ({ schoolId, rangeDates }) => {
  const [performanceLeads, setPerformanceByStatus] = useState(null);
  const { onLoading, onSuccess, onError, status } = useStatus();

  useEffect(() => {
    onLoading();
    if (rangeDates.value) {
      getPerformance({ schoolId, sinceDate: rangeDates.value, previousPeriod: rangeDates.previousPeriod })
        .then((data) => {
          onSuccess();
          setPerformanceByStatus(data);
        })
        .catch((err) => {
          console.log('performance leads error', err.message);
          onError();
        });
    }
  }, [rangeDates]);

  return { performanceLeads, isPerformaceLoading: status.loading, isPerformanceSucces: status.success };
};

const getPerformance = async ({ schoolId, sinceDate, previousPeriod }) => {
  const today = getFormat(add(new Date(), { days: 1 }));

  const data = await _getPerformanceByStatus(schoolId, {
    today,
    rangeDate: sinceDate,
    previousRangeDate: previousPeriod,
  });

  return data;
};
