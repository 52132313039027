import { useEffect, useState } from 'react';
import { useStatus } from 'hooks/useStatus';
import { _getSchoolSeats, _updateSchoolSeats } from 'controllers/schools/schools_controller';

export const useSchoolSeats = (schoolId) => {
  const { onLoading, onSuccess, onError, status } = useStatus();
  const [seats, setSeats] = useState([]);
  const [countSeats, setTotalSeats] = useState({
    goal: 0,
    current: 0,
  });

  useEffect(() => {
    onLoading();
    getSeats(schoolId)
      .then((data) => {
        const seatsTransformed = data.map((item) => ({
          id: item.id,
          grade: item.grade,
          availables: item.availables,
          currentBussy: item.total - item.availables,
          total: item.total,
          block_asm: item.block_asm,
        }));
        setSeats(seatsTransformed);
        onSuccess();

        const totalGoal = getTotalSeats(seatsTransformed);

        setTotalSeats({
          total: totalGoal.total,
          current: totalGoal.bussy,
        });
      })
      .catch((err) => console.log(err.message));
  }, []);

  const getTotalSeats = (seats) => {
    const total = seats.reduce(
      (acc, current) => {
        return { total: acc.total + current.total, bussy: acc.bussy + current.currentBussy };
      },
      { total: 0, bussy: 0 }
    );

    return total;
  };

  const handleSeats = ({ target }, type) => {
    const { name, value } = target;

    const findGradeIndex = seats?.findIndex((item) => item.grade === name);

    const newSeats = [...seats];
    if (type === 'total') {
      newSeats[findGradeIndex].total = Number(value);
    } else {
      newSeats[findGradeIndex].currentBussy = Number(value);

      newSeats[findGradeIndex].availables = newSeats[findGradeIndex].total - Number(value);
    }
    setSeats(newSeats);
  };

  const toggleBlockASM = (id) => {
    const findGradeIndex = seats?.findIndex((item) => item.id === id);
    const newSeats = [...seats];
    newSeats[findGradeIndex].block_asm = !newSeats[findGradeIndex].block_asm;
    setSeats(newSeats);
  };

  const onUpdateSeats = async () => {
    // updated copy of seats omiting currentBussy field from each grade to avoid endpoint call fail
    const updatedSeats = seats.map((item) => ({
      grade: item.grade,
      availables: item.availables,
      total: item.total,
      block_asm: item.block_asm,
    }));

    const payload = new FormData();

    payload.append('seatsAvailables', JSON.stringify(updatedSeats));

    await _updateSchoolSeats(schoolId, payload);

    const totalGoal = getTotalSeats(seats);
    setTotalSeats({
      total: totalGoal.total,
      current: totalGoal.bussy,
    });
  };

  return {
    seats,
    countSeats,
    isLoading: status.loading,
    isSuccess: status.success,
    handleSeats,
    onUpdateSeats,
    toggleBlockASM,
  };
};

const getSeats = async (schoolId) => {
  const data = await _getSchoolSeats(schoolId);
  const seats = await data.json();
  return seats;
};
