import stateArray from './states-array';

class SchoolUrl {
  modString(str) {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/ /g, '-');
  }

  build(school, options) {
    // "schools/:state/:city/:schoolType/:SchoolNameId"
    var state = stateArray.filter((s) => s.value.trim().toLowerCase() === school.state.trim().toLowerCase())[0];
    state = state ? this.modString(state.label) : school.state;
    var city = this.modString(school.city);
    var schoolType = this.modString(school.type);
    var schoolNameId = `${this.modString(school.name)}_${school.id}`;
    var others = '';
    if (options) {
      options.forEach((e) => {
        others += `${e.label}=${e.value}&`;
      });
    }
    others = (others === '' ? '' : '?') + others;
    return `/schools/${state}/${city}/${schoolType}/${schoolNameId}${others}`;
  }
}

export default new SchoolUrl();
