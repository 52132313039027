import { IconCircle } from '..';
import styles from './setting-selector.module.scss';

export const SettingSelector = ({ options }) => {
  return (
    <div className={styles.grid}>
      {options.map((option) => (
        <CardSelection
          key={option.id}
          onClick={option.onClick}
          Icon={option.icon}
          label={option.label}
          description={option.description}
          color={option.colorIcon}
        />
      ))}
    </div>
  );
};

const CardSelection = ({ onClick, Icon, label, description, color = 'blue' }) => {
  return (
    <button onClick={onClick} className={styles.button}>
      <IconCircle Icon={Icon} color={color} />
      <p className={styles.title}>{label}</p>
      <p className={styles.description}>{description}</p>
    </button>
  );
};
