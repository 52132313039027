import { Handle, Position, useReactFlow } from '@xyflow/react';
import { Flex, Typography, Button } from 'antd';
import { CopyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from '../flow-designer.module.scss';

export const NodeWrapper = ({ children, isFirstNode }) => {
  return (
    <div className={styles.nodeWrapper}>
      {!isFirstNode && <Handle type="target" position={Position.Top} className={styles.handle} />}
      {isFirstNode && (
        <div className={styles.initialBadgeContainer}>
          <span className={styles.initialBadge}>Initial</span>
        </div>
      )}
      {children}
      <Handle type="source" position={Position.Bottom} className={styles.handle} />
    </div>
  );
};

const NodeHeader = ({ id, Icon, children, deactiveEdit }) => {
  const { deleteElements, setNodes, getNode } = useReactFlow();

  const handleEdit = (id) => {
    const currentNode = getNode(id);
    console.log({ currentNode });
  };

  const handleDeleteNode = (id) => {
    console.log('delete:', id);
    deleteElements({ nodes: [{ id }] });
  };

  const handleDuplicate = (id) => {
    const currentNode = getNode(id);
    if (currentNode) {
      const newNode = {
        ...currentNode,
        id: `${currentNode.type}-${Date.now()}`,
        position: {
          x: currentNode.position.x + 120,
          y: currentNode.position.y + 120,
        },
      };
      setNodes((nodes) => [...nodes, newNode]);
    }
  };

  return (
    <Flex justify="space-between" align="center" className={styles.header}>
      <Flex align="center" gap="small">
        <Icon style={{ color: '#0099cc' }} />
        <Label>{children}</Label>
      </Flex>
      <Flex align="center">
        <Button onClick={() => handleDuplicate(id)} type="text" icon={<CopyOutlined />} />
        {!deactiveEdit && <Button onClick={() => handleEdit(id)} type="text" icon={<EditOutlined />} />}
        <Button onClick={() => handleDeleteNode(id)} type="text" icon={<DeleteOutlined />} />
      </Flex>
    </Flex>
  );
};

const Label = ({ children }) => {
  return (
    <Typography.Text strong style={{ fontSize: '1rem' }}>
      {children}
    </Typography.Text>
  );
};

NodeWrapper.Header = NodeHeader;
NodeWrapper.Label = Label;
