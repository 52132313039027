import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';
import { REQUEST_CONNECT_TRANSACTION, BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION } from './payment-actions';

const reducers = combineReducers({
  requestConnectTransaction: getApiReducer(REQUEST_CONNECT_TRANSACTION),
  requestBeFeaturedSchoolSubscription: getApiReducer(BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION),
});

export default reducers;
