import getApiReducer from '../util/get-api-reducer';
import Immutable from 'immutable';

import { GET_ESTIMATION, CLEAR_ESTIMATION } from './campaign-estimation-actions';

const defaultState = Immutable.Map({
  loaded: false,
  loading: false,
  data: null,
  error: false,
  validationErrors: Immutable.Map(),
});

const reducer = (state, action) => {
  if (action.type === CLEAR_ESTIMATION) {
    state = defaultState;
  } else {
    state = getApiReducer(GET_ESTIMATION)(state, action);
  }

  return state;
};

export default reducer;
