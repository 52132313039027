import { Typography, Flex, Collapse } from 'antd';
import { MailOutlined, AppstoreOutlined, MessageOutlined } from '@ant-design/icons';
import { IconCircle, Button } from '..';
import styles from './template-selector.module.scss';

const templates = [
  {
    id: 'custom',
    label: 'Custom',
    children:
      "Use our blank template to craft exactly the message you need for the desired result, whether it's for retention, reengagement, announcements, or something completely different.",
    icon: AppstoreOutlined,
    colorIcon: 'blue',
  },
  {
    id: 'lead-enrollment',
    label: 'Lead to Enrollment',
    description: 'First contact email for new leads',
    children:
      'Set a "first contact" email to be sent to all new leads telling them why you are contacting them, describing the school, and sharing information about how to enroll or contact you for more information.',
    icon: MailOutlined,
    colorIcon: 'purple',
  },
  {
    id: 're-enroll',
    label: 'Re-Enroll',
    description: 'Re-enrollment process notification',
    children:
      "Let current students know when it's time to begin the re-enrollment process, what steps need to be taken, and when deadlines are approaching.",
    icon: MessageOutlined,
    colorIcon: 'green',
  },
  {
    id: 'birthday',
    label: 'Birthday Nurturing',
    description: 'Birthday greetings for retention',
    children:
      'Send a special greeting to all of your leads on their birthday to show how much you value them being a part of your community.',
    icon: MailOutlined,
    colorIcon: 'blue',
  },
];
export const TemplateSelector = ({ templates, onTemplateSelect }) => {
  return (
    <div className={styles.container}>
      {/* <Collapse accordion bordered={false} items={templates} onChange={onTemplateSelect} /> */}
      {templates.map((template) => (
        <Flex key={template.id} justify="space-between" align="center">
          <Flex gap="middle" align="center">
            {/* <IconCircle Icon={template.icon} size="small" color={template.colorIcon} /> */}
            <img src={template.icon} className={styles.icon} alt="campaign icon" />
            <Typography.Title level={5}>{template.name}</Typography.Title>
          </Flex>
          <Button type="default" onClick={() => onTemplateSelect(template)}>
            Select
          </Button>
        </Flex>
      ))}
    </div>
  );
};
