import React from 'react';
import { Container } from '..';
import { MailOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import styles from './spot-performance.module.scss';

/**
 * @param {{spotMessageCount: {emailsCount: number, smsCount: number}, spotCalls: number, timeSaved: string, isLoading: boolean, className: string, disablePhoneCalls: boolean}} props
 */
export const SpotPerformance = ({
  spotMessageCount,
  spotCalls,
  timeSaved,
  disablePhoneCalls,
  isLoading,
  className,
}) => {
  const itemsList = [
    { icon: <MailOutlined />, label: 'Emails Sent', value: isLoading ? '-' : spotMessageCount?.emailsCount },
    { icon: <MessageOutlined />, label: 'SMS Sent', value: isLoading ? '-' : spotMessageCount?.smsCount },
    { icon: <PhoneOutlined />, label: 'Phone Calls', value: isLoading ? '-' : disablePhoneCalls ? 'N/A' : spotCalls },
  ];

  return (
    <Container title="SPOT Follow Up Activity" className={className}>
      <Container.Body className={styles.layout}>
        {isLoading ? (
          '-'
        ) : (
          <div className={styles.time}>
            <p>Time Saved</p>
            <p className={styles.hours}>{isLoading ? '-' : timeSaved}</p>
          </div>
        )}

        <ul className={styles.list}>
          {itemsList.map((item) => (
            <li key={item.label} className={styles.item}>
              {item.icon}
              <p>
                <b>{item.value}</b> <span className={styles.label}>{item.label}</span>
              </p>
            </li>
          ))}
        </ul>
      </Container.Body>
    </Container>
  );
};
