import React from 'react';

const Delete = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 25 32">
      <path d="M1.7857143 28.4444444C1.7857143 30.4000005 3.3928573 32 5.35714285 32H19.6428571c1.9642863 0 3.5714286-1.5999995 3.5714286-3.5555556V7.1111111H1.7857143v21.3333333zM25 1.77777778h-6.25L16.9642857 0H8.0357143L6.25 1.77777778H0v3.55555555h25V1.77777778z" />
    </svg>
  );
  /* eslint-enable */
};

export default Delete;
