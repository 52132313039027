import client from '../client';
import { getResponseValue } from 'util/validate';

export const _getLiveLeadCategories = async (schoolId, payload) => {
  let query = [];
  Object.keys(payload).forEach((k, i) => {
    let value = payload[k];
    if (Array.isArray(value) === true) {
      value.forEach((arrayValue) => {
        query.push(`${k}=${arrayValue}`);
      });
    } else {
      if (value === null || value === undefined) {
        value = '';
      }
      if (value instanceof Date) {
        value = value.toISOString();
      }
      query.push(`${k}=${value}`);
    }
  });

  query = query.join('&');

  const res = await client.get(
    `schools/${schoolId}/live-search/live-lead-categories?${query}`,
    {},
    { credentials: 'omit' }
  );
  return getResponseValue(res);
};

export const _getLiveLeadCategoriesDetails = async (schoolId, payload) => {
  let query = [];
  Object.keys(payload).forEach((k, i) => {
    let value = payload[k];
    if (Array.isArray(value) === true) {
      value.forEach((arrayValue) => {
        query.push(`${k}=${arrayValue}`);
      });
    } else {
      if (value === null || value === undefined) {
        value = '';
      }
      if (value instanceof Date) {
        value = value.toISOString();
      }
      query.push(`${k}=${value}`);
    }
  });
  query = query.join('&');
  const res = await client.get(
    `schools/${schoolId}/live-search/live-lead-categories-details?${query}`,
    {},
    { credentials: 'omit' }
  );
  return getResponseValue(res);
};
