import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './card.module.scss';

export const Card = React.forwardRef(({ className, ...props }, ref) => {
  return <div ref={ref} className={`${styles.card} ${className || ''}`} {...props} />;
});

Card.propTypes = {
  className: PropTypes.string,
};
