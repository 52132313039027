import React from 'react';

const Trash = ({ color }) => {
  const [colorIcon, setColorIcon] = React.useState(color);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20">
      <g>
        <path
          fill={colorIcon}
          d="M432,80h-82.4l-34-56.7C306.9,8.8,291.3,0,274.4,0H173.6c-16.9,0-32.5,8.8-41.2,23.3L98.4,80H16
                    C7.2,80,0,87.2,0,96v16c0,8.8,7.2,16,16,16h16v336c0,26.5,21.5,48,48,48h288c26.5,0,48-21.5,48-48V128h16c8.8,0,16-7.2,16-16V96
                    C448,87.2,440.8,80,432,80z M171.8,50.9c1.1-1.8,3-2.9,5.2-2.9h94c2.1,0,4.1,1.1,5.2,2.9L293.6,80H154.4L171.8,50.9z M368,464H80
                    V128h288V464z"
        />
        <path
          fill={colorIcon}
          d="M268,416h24c6.6,0,12-5.4,12-12V188c0-6.6-5.4-12-12-12h-24c-6.6,0-12,5.4-12,12v216
                    C256,410.6,261.4,416,268,416z"
        />
        <path
          fill={colorIcon}
          d="M156,416h24c6.6,0,12-5.4,12-12V188c0-6.6-5.4-12-12-12h-24c-6.6,0-12,5.4-12,12v216
                    C144,410.6,149.4,416,156,416z"
        />
      </g>
    </svg>
  );
};

export default Trash;
