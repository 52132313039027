import React from 'react';

const Private = () => {
  /* eslint-disable max-len */
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0,0 L32,0 L32,24.6094033 L13.1981567,24.6094033 L0,24.6094033 L0,0 Z M1.1797235,23.4321881 L30.8202765,23.4321881 L30.8202765,1.17721519 L1.1797235,1.17721519 L1.1797235,23.4321881 Z M28.308279,3.97310127 L3.98156682,3.97310127 L3.98156682,19.8374774 L28.308279,19.8374774 L28.308279,3.97310127 Z M2.80184332,21.0146926 L2.80184332,2.79588608 L29.4880025,2.79588608 L29.4880025,21.0146926 L2.80184332,21.0146926 Z M13.9329414,26.6274864 L19.1298268,26.6274864 L19.1298268,24.6094033 L13.9329414,24.6094033 L13.9329414,26.6274864 Z M20.3095503,23.4321881 L20.3095503,27.8047016 L12.7532179,27.8047016 L12.7532179,23.4321881 L20.3095503,23.4321881 Z M6.49356428,29.8227848 L25.5064357,29.8227848 L25.5064357,27.8047016 L6.49356428,27.8047016 L6.49356428,29.8227848 Z M26.6861592,26.6274864 L26.6861592,31 L5.31384078,31 L5.31384078,26.6274864 L26.6861592,26.6274864 Z"
        fill="#8AC340"
      />
    </svg>
  );
  /* eslint-enable */
};

export default Private;
