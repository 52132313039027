import React, { useState, useEffect } from 'react';
import HtmlParser from 'html-react-parser';
import classNames from 'classnames';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import styles from './toast-notifications.module.scss';

const ToastNotification = ({ time, type, message, onClose }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (time && time > 0) {
      const timer = setTimeout(() => handleOnClose(), time);
      return () => clearTimeout(timer);
    }
  }, [time]);

  const handleOnClose = () => {
    setShow(false);
    if (onClose) onClose();
  };

  const getNotificationLook = () => {
    let icon;
    switch (type) {
      case 'success':
        icon = <CheckCircleOutlined style={{ color: '#99cc33', fontSize: '1.5rem' }} />;
        break;
      case 'error':
        icon = <ExclamationCircleOutlined style={{ color: 'red', fontSize: '1.5rem' }} />;
        break;
      case 'warning':
        icon = <WarningOutlined style={{ color: '#f0c52e', fontSize: '1.5rem' }} />;
        break;
      case 'info':
      default:
        icon = <InfoCircleOutlined style={{ color: '#136ac3', fontSize: '1.5rem' }} />;
        break;
    }
    return icon;
  };

  if (!show) return <></>;

  const icon = getNotificationLook();

  return (
    <div className={classNames(styles.toastNotification)}>
      <div className={styles.toastContent}>
        <div className={classNames(styles.toastIcon)}>{icon}</div>
        <div className={styles.toastMessage}>
          <div className={styles.toastMessageTitle}>
            <p>{HtmlParser(message)}</p>
          </div>
          <div className={styles.toastDismiss} onClick={handleOnClose}>
            <CloseOutlined style={{ color: '#000000', fontSize: '.75rem', height: '.75rem' }} />
            <p>Dismiss</p>
          </div>
        </div>
      </div>
      <div className={classNames(styles.toastBottomLine, styles[type] || styles.info)} />
    </div>
  );
};

export default ToastNotification;
