import React from 'react';
import styles from './label-info.module.scss';

export const LabelInfo = ({ classNameLabel }) => {
  return (
    <div id="overLayerInfo" className={styles.over}>
      <span className={classNameLabel}>No Data to Display for Selected Range</span>
    </div>
  );
};
