import React from 'react';
import { Container } from '..';
import styles from './live-search-preview.module.scss';
import GoogleMapReact from 'google-map-react';
import { HomeFilled } from '@ant-design/icons';

export const LiveSearchPreview = ({ schoolData, className, leads, link }) => {
  return (
    <Container title="Live Parent Search" className={className} link={link}>
      {schoolData && <Map latitude={schoolData.latitude} longitude={schoolData.longitude} leads={leads} />}
    </Container>
  );
};

const Map = ({ latitude, longitude, leads }) => {
  if (!latitude || !longitude) {
    return null;
  }

  return (
    <div className={styles.mapContainer}>
      <GoogleMapReact center={{ lat: latitude, lng: longitude }} defaultZoom={13} options={{ scrollwheel: false }}>
        <PinSchool lat={latitude} lng={longitude} />
        {leads?.map((lead) => (
          <PinLead key={lead.id} lat={lead.latitude} lng={lead.longitude} />
        ))}
      </GoogleMapReact>
    </div>
  );
};

const PinSchool = () => (
  <div
    id="custom-marker"
    key="custom-marker"
    style={{
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      background: '#0099cc',
      display: 'grid',
      placeItems: 'center',
    }}>
    <HomeFilled style={{ color: 'white' }} />
  </div>
);

const PinLead = () => {
  const pin = 'https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/icon-pin-potencial-leads.svg';
  return (
    <div id="lead" style={{ width: '20px', height: '20px' }}>
      <img src={pin} alt="" />
    </div>
  );
};
