import { combineReducers } from 'redux';
import getApiReducer, { defaultState } from '../util/get-api-reducer';
import {
  DELETE_LANDING_PAGE_IMAGE,
  EDIT_OR_CREATE_LANDING_PAGE,
  GET_LANDING_PAGE,
  GET_LANDING_PAGES,
  UPLOAD_LANDING_PAGE_IMAGE,
} from './landing-page-actions';
import Immutable from 'immutable';

const defaultUploadState = Immutable.Map({
  header_image: defaultState,
  body_image: defaultState,
});
const uploadLandingPageImage = (state = defaultUploadState, action) => {
  return state.set(action.field, getApiReducer(UPLOAD_LANDING_PAGE_IMAGE)(state, action));
};

const defaultDeleteState = Immutable.Map({
  header_image: defaultState,
  body_image: defaultState,
});
const deleteLandingPageImage = (state = defaultDeleteState, action) => {
  return state.set(action.field, getApiReducer(DELETE_LANDING_PAGE_IMAGE)(state, action));
};

export default combineReducers({
  deleteLandingPageImage,
  editOrCreateLandingPage: getApiReducer(EDIT_OR_CREATE_LANDING_PAGE),
  getLandingPage: getApiReducer(GET_LANDING_PAGE),
  getLandingPages: getApiReducer(GET_LANDING_PAGES),
  uploadLandingPageImage,
});
