import client from '../client';

export const DELETE_LANDING_PAGE_IMAGE = 'DELETE_LANDING_PAGE_IMAGE';
export const EDIT_OR_CREATE_LANDING_PAGE = 'EDIT_OR_CREATE_LANDING_PAGE';
export const GET_LANDING_PAGE = 'GET_LANDING_PAGE';
export const GET_LANDING_PAGES = 'GET_LANDING_PAGES';
export const UPLOAD_LANDING_PAGE_IMAGE = 'UPLOAD_LANDING_PAGE_IMAGE';

export function getLandingPages() {
  return {
    type: GET_LANDING_PAGES,
    promise: client.get('landing-pages'),
  };
}

export function getLandingPage(location) {
  return {
    type: GET_LANDING_PAGE,
    promise: client.get(`landing-pages/${location}`, {}, { credentials: 'omit' }),
  };
}

export function editOrCreateLandingPage(location, data) {
  return {
    type: EDIT_OR_CREATE_LANDING_PAGE,
    promise: client.patch(`landing-pages/${location}`, data, { credentials: 'omit' }),
  };
}

export function deleteLandingPageImage(location, field) {
  return {
    type: DELETE_LANDING_PAGE_IMAGE,
    promise: client.delete(`landing-pages/${location}/upload/${field}`, {}, { credentials: 'omit' }),
  };
}

export function uploadLandingPageImage(location, field, file) {
  const formData = new FormData();
  formData.append('file', file);

  return {
    type: UPLOAD_LANDING_PAGE_IMAGE,
    field,
    promise: client.patch(`landing-pages/${location}/upload/${field}`, formData, { credentials: 'omit' }),
  };
}
