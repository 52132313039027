import React, { useEffect, useState } from 'react';
import { Close16, ChevronDown16 } from '@carbon/icons-react';
import styles from './selector.module.scss';
import { useToggle } from 'hooks/useToggle';

export const Selector = ({ options, handler }) => {
  const [isListOpen, toggleListOpen] = useToggle(false);

  const [selections, setSelections] = useState([]);

  const handleSelection = (event) => {
    event.preventDefault();
    const { value } = event.target;
    if (selections.includes(value)) return;

    setSelections([...selections, value]);

    // toggleListOpen()
  };

  const removeSelection = (value) => {
    if (!selections.includes(value)) return;

    setSelections(selections.filter((item) => item !== value));
  };

  const resetSelection = (e) => {
    e.preventDefault();
    setSelections([]);
  };

  useEffect(() => {
    handler && handler(selections);
  }, [selections]);

  const handleFocus = () => {
    // !isListOpen && toggleListOpen()
    toggleListOpen();
  };

  const handleClick = (e) => {
    const isFocused = document.activeElement === e.target;
    console.log(isFocused);

    isFocused ? toggleListOpen : e.target.focus();
  };

  return (
    <div
      className={styles.selectorContainer}
      // tabIndex='0' onFocus={handleFocus}
      // onClick={toggleListOpen}
    >
      <div className={styles.chips}>
        {selections.map((item, idx) => (
          <div key={idx} className={styles.chip}>
            {item} <Close16 onClick={() => removeSelection(item)} />
          </div>
        ))}

        <span
          // tabIndex='0'
          // onFocus={handleFocus}
          onClick={toggleListOpen}
          className={styles.inputRef}
        />
        {/* <input type="hidden" onFocus={handleFocus} /> */}
      </div>

      <div className={styles.selectorContainer_buttons}>
        {selections.length > 0 && (
          <button onClick={resetSelection}>
            <Close16 />
          </button>
        )}

        <span tabIndex="0" onClick={toggleListOpen} className={styles.arrow}>
          <ChevronDown16 />
        </span>
      </div>

      {isListOpen && (
        <ul className={styles.dropList}>
          {options
            .filter((opt) => !selections.includes(opt))
            .map((item, idx) => (
              <li key={idx} value={item}>
                <button name="grade" value={item} onClick={handleSelection}>
                  {item}
                </button>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
