import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './button.scss';

const LinkButton = ({
  className,
  href,
  children,
  color,
  disabled,
  fullWidth,
  fullWidthMobile,
  icon,
  tabIndex,
  size,
  target,
  theme,
  to,
}) => {
  const buttonClasses = {
    button: true,
    [`button-${size}`]: { size },
    [`button-${theme}-${color}`]: { color, theme },
    [`${className}`]: className,
    'button--disabled': disabled,
    'button--display--block-mobile': fullWidthMobile,
    'button--display--block': fullWidth,
    'button-icon': icon,
  };

  return href ? (
    <a className={`text-center ${classNames(buttonClasses)}`} href={href} target={target}>
      {children}
    </a>
  ) : (
    <Link
      className={`text-center ${classNames(buttonClasses)}`}
      disabled={disabled}
      to={to}
      tabIndex={tabIndex}
      target={target}>
      <span className="button__children">{children}</span>
    </Link>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'ghost-primary',
    'ghost-secondary',
    'bluelegacy',
    'greenlegacy',
    'redlegacy',
    'red',
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fontWeight: PropTypes.oneOf(['regular', 'bold']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  tabIndex: PropTypes.string,
  theme: PropTypes.oneOf(['contained', 'outlined']),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  target: PropTypes.string,
};

LinkButton.defaultProps = {
  className: null,
  color: 'secondary',
  disabled: false,
  fullWidth: false,
  fontWeight: 'regular',
  size: 'medium',
  theme: 'contained',
  tabIndex: '0',
  to: null,
  href: null,
  target: null,
};

export default LinkButton;
