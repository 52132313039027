import client from '../client';

export const REQUEST_CONNECT_TRANSACTION = 'REQUEST_CONNECT_TRANSACTION';
export const BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION = 'BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION';

export const REQUEST_CONNECT_TRANSACTION_RESET = 'REQUEST_CONNECT_TRANSACTION_RESET';
export const BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION_RESET = 'BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION_RESET';

export function requestConnectTransaction(schoolId, params) {
  return {
    type: REQUEST_CONNECT_TRANSACTION,
    promise: client.post(`requesttoconnect/${schoolId}/transaction`, params, { credentials: 'omit' }),
  };
}

export function requestBeFeaturedSchoolSubscription(schoolId, params) {
  return {
    type: BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION,
    promise: client.post(`schools/${schoolId}/marketing-dashboard/be-featured-school-subscription`, params, {
      credentials: 'omit',
    }),
  };
}

export function resetRequestConnectDetail() {
  return { type: REQUEST_CONNECT_TRANSACTION_RESET };
}

export function resetRequestBeFeaturedSchoolSubscription() {
  return { type: BE_FEATURED_SCHOOL_SUBSCRIPTION_TRANSACTION_RESET };
}
