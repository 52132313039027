import client from '../client';

export const GET_ABOUT_YOUR_SCHOOL = 'GET_ABOUT_YOUR_SCHOOL';
export const ADD_ABOUT_YOUR_SCHOOL = 'ADD_ABOUT_YOUR_SCHOOL';
export const ADD_IMAGE_ABOUT_YOUR_SCHOOL = 'ADD_IMAGE_ABOUT_YOUR_SCHOOL';

export function getAboutYourSchool(schoolId, aboutYourSchoolId) {
  return {
    type: GET_ABOUT_YOUR_SCHOOL,
    promise: client.get(`schools/${schoolId}/about-your-school/${aboutYourSchoolId}`, {}, { credentials: 'omit' }),
  };
}

export function addAboutYourSchool(schoolId, payload) {
  return {
    type: ADD_ABOUT_YOUR_SCHOOL,
    promise: client.post(`schools/${schoolId}/about-your-school`, payload, { credentials: 'omit' }),
  };
}

export function addImageAboutYourSchool(schoolId, data) {
  const formData = new FormData();
  formData.append('file', data);
  return {
    type: ADD_IMAGE_ABOUT_YOUR_SCHOOL,
    promise: client.post(`schools/${schoolId}/about-your-school/image`, formData, { credentials: 'omit' }),
  };
}
