import client from '../client';
import localstorage from 'store2';

export const _getUserProfileById = async (userId) => {
  // Added the replace because the localstorage return the string with ""
  return await client.get(`users/${userId.replace(/"/g, '')}/profile`, {}, { credentials: 'omit' });
};

export const _updateUserInfo = async (userId, userData) => {
  var payload = new FormData();
  payload.append('firstName', userData.first_name);
  payload.append('lastName', userData.last_name);
  payload.append('title', userData.title);
  payload.append('phone', userData.phone);
  payload.append('ext', userData.ext);
  payload.append('email', userData.email);
  payload.append('password', userData.password);

  return await client.patch(`users/${userId}/info`, payload, { credentials: 'omit' });
};

export const _updateUserSchoolDepartment = async (schoolId, userId, userData) => {
  var payload = new FormData();
  payload.append('user_school_deparment_id', userData.departmentValue);
  return await client.patch(`schools/${schoolId}/users/${userId}/department`, payload, { credentials: 'omit' });
};

export const _updateUserSchoolIsOwner = async (schoolId, userId, userData) => {
  var payload = new FormData();
  payload.append('isOwner', userData.isOwner);
  return await client.patch(`schools/${schoolId}/users/${userId}/isOwner`, payload, { credentials: 'omit' });
};

export const _uploadImage = async (file, userId) => {
  const formData = new FormData();
  formData.append('avatar', file);
  formData.append('fileName', file.name);
  formData.append('original', file.original);
  return await client.patch(`users/${userId}`, formData, { credentials: 'omit' });
};

export const _resetPassword = async (email) => {
  return await client.post(`users/reset-pwd`, { email });
};

export const _getUserTokenId = async (userId) => {
  const idToken = localstorage.get('idToken');
  return await client.get(`users/${userId}/token/`, { token: idToken }, { credentials: 'omit' });
};

export const _getUserLastLogin = async (userId) => {
  const res = await client.get(`users/${userId}/last-login`, {}, { credentials: 'omit' });
  return res;
};
