import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';
import { GET_ONBOARDING, INSERT_UPDATE_ONBOARDING } from './onboarding-actions';

const reducers = combineReducers({
  getOnboarding: getApiReducer(GET_ONBOARDING),
  insertOrUpdateOnboarding: getApiReducer(INSERT_UPDATE_ONBOARDING),
});

export default reducers;
