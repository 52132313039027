import Immutable from 'immutable';
import buildApiReducer from './build-api-reducer';
import config from 'config';

export const defaultState = Immutable.Map({
  loaded: false,
  loading: false,
  data: null,
  error: false,
  validationErrors: Immutable.Map(),
});

function formatValidationErrors(error) {
  const errors = {};

  if (!error) {
    return Immutable.Map();
  }

  if (!error.validationErrors) {
    return Immutable.fromJS(errors);
  }

  const validationMessageMap = config.validationMessageMap || {};

  error.validationErrors.forEach((validationError) => {
    if (!errors[validationError.key]) {
      errors[validationError.key] = [];
    }
    errors[validationError.key].push(validationMessageMap[validationError.type] || validationError.message);
  });

  return Immutable.fromJS(errors);
}

export default (actionTypePrefix) =>
  buildApiReducer(
    actionTypePrefix,
    defaultState,
    (state) =>
      state.merge({
        loading: true,
        loaded: false,
        validationErrors: Immutable.Map(),
      }),
    (state, action) =>
      state.merge({
        loading: false,
        loaded: true,
        data: Immutable.fromJS(action.json),
        validationErrors: Immutable.Map(),
        error: false,
      }),
    (state, action) =>
      state.merge({
        loading: false,
        loaded: false,
        data: null,
        validationErrors: formatValidationErrors(action.json),
        error: action.json
          ? action.json.validationErrors
            ? null
            : Immutable.fromJS(action.json)
          : { message: 'Unknown Error' },
      }),
    (state) => defaultState
  );
