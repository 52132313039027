import React from 'react';

const Caret = () => {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5.9 7l6.1 6.2L18.1 7 20 8.9 12 17 4 8.9z" />
    </svg>
  );
  /* eslint-enable */
};

export default Caret;
