import client from '../client';

export const GET_FEATURES = 'GET_FEATURES';
export const SEND_DEMO_REQUEST = 'SEND_DEMO_REQUEST';

export function getFeatures(grades) {
  return {
    type: GET_FEATURES,
    promise: client.get('features', grades ? { grades } : null, { credentials: 'omit' }),
  };
}

export function sendDemoRequest(name, email, day, time) {
  var payload = new FormData();
  payload.append('name', name);
  payload.append('email', email);
  payload.append('day', day);
  payload.append('time', time);

  return {
    type: SEND_DEMO_REQUEST,
    promise: client.post(`features/demo`, payload, { credentials: 'omit' }),
  };
}
