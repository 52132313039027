import { useEffect, useState } from 'react';
import { _getCallsSPOT, _getSPOTMessages } from 'controllers/dashboard/dashboard_controller';
import { useStatus } from 'hooks/useStatus';

/**
 * @param {{ schoolId: number, sinceDate: 'YYYY/MM/DD', today: 'YYYY/MM/DD', disablePhoneCalls: boolean }} props
 */
export const useSpotPerformance = ({ schoolId, sinceDate, today, disablePhoneCalls }) => {
  const [spotPerformance, setSpotPerformance] = useState({ emailsCount: null, smsCount: null });
  const [spotCalls, setSpotCalls] = useState({ totalCalls: null, totalDuration: null });
  const [timeSaved, setTimeSaved] = useState(null);
  const { onLoading, onSuccess, onError, status } = useStatus();

  useEffect(() => {
    onLoading();
    if (sinceDate) {
      Promise.all([getSPOTPerformance({ schoolId, sinceDate }), getCallsSPOT({ schoolId, from: sinceDate, to: today })])
        .then(([count, calls]) => {
          setSpotPerformance({ emailsCount: count.emailsCount, smsCount: count.smsCount });
          setSpotCalls({ totalCalls: calls.call_info.total_calls, totalDuration: calls.call_info.total_duration });

          onSuccess();
        })
        .catch((err) => {
          console.error('An error occurred:', err.message);
          onError();
        });
    }
  }, [sinceDate]);

  useEffect(() => {
    const timeSaved = getTimeSaved({
      callsDuration: disablePhoneCalls ? 0 : spotCalls?.totalDuration,
      totalCalls: disablePhoneCalls ? 0 : spotCalls?.totalCalls,
      totalSMS: spotPerformance?.smsCount,
      totalMails: spotPerformance?.emailsCount,
    });

    setTimeSaved(timeSaved);
  }, [spotCalls, spotPerformance]);

  return { spotPerformance, spotCalls, timeSaved, isLoadingSPOT: status.loading };
};

const getTimeSaved = ({ callsDuration, totalCalls, totalSMS, totalMails }) => {
  const smsOnMinutes = totalSMS * 1.5;
  const mailsOnMinutes = totalMails * 2;
  const callMinutes = callsDuration / 1000 / 60; // convert call duration on milliseconds to minutes

  const totalMinutes = smsOnMinutes + mailsOnMinutes + callMinutes + (totalCalls || 0);

  if (totalMinutes <= 0) return 0;

  if (totalMinutes < 60) return `${totalMinutes.toFixed()} min`;

  return `${(totalMinutes / 60).toFixed(1)} hrs`;
};

const getSPOTPerformance = async ({ schoolId, sinceDate }) => {
  const data = await _getSPOTMessages(schoolId, { sinceDate });
  return data;
};

/**
 * @param {{ schoolId: number, from: 'YYYY/MM/DD', to: 'YYYY/MM/DD' }} props
 */
const getCallsSPOT = async ({ schoolId, from, to }) => {
  const data = await _getCallsSPOT(schoolId, { from, to });
  return data;
};
