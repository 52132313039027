import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';
import {
  BATCH_EDIT_LEADS,
  GET_COMPLETED_APPLICATION,
  GET_LEADS,
  GET_POTENTIAL_LEADS,
  SEND_APPLICATIONS,
  UPDATE_LEADS_NOTE,
  GET_LEADS_COUNTS,
  GET_LEADS_HISTORY,
  CREATE_LEAD,
  GET_LEAD_FIELDS,
  SET_LEAD_FIELDS,
  GET_LEAD_SOURCES,
  ADD_LEAD_SOURCE,
  GET_LEAD_STATUSES,
  ADD_LEAD_STATUS,
  GET_EXPORT_TYPE_APPLICATIONS,
  GET_LONG_URL,
  CREATE_SF_LEAD,
  GET_LEAD_REASONS,
  CREATE_LEAD_REASON,
  DELETE_LEADS,
  GET_COUNT_LATEST_NEW_LEADS,
} from './lead-actions';

const reducers = combineReducers({
  batchEditLeads: getApiReducer(BATCH_EDIT_LEADS),
  getCompletedApplication: getApiReducer(GET_COMPLETED_APPLICATION),
  getLeads: getApiReducer(GET_LEADS),
  getPotentialLeads: getApiReducer(GET_POTENTIAL_LEADS),
  sendApplications: getApiReducer(SEND_APPLICATIONS),
  updateLeadNotes: getApiReducer(UPDATE_LEADS_NOTE),
  getLeadsCounts: getApiReducer(GET_LEADS_COUNTS),
  getLeadsHistory: getApiReducer(GET_LEADS_HISTORY),
  createLEad: getApiReducer(CREATE_LEAD),
  getLeadFields: getApiReducer(GET_LEAD_FIELDS),
  setLeadFields: getApiReducer(SET_LEAD_FIELDS),
  getLeadSources: getApiReducer(GET_LEAD_SOURCES),
  addLeadSource: getApiReducer(ADD_LEAD_SOURCE),
  getLeadStatuses: getApiReducer(GET_LEAD_STATUSES),
  addLeadStatus: getApiReducer(ADD_LEAD_STATUS),

  getExportTypeApplicationsForSchool: getApiReducer(GET_EXPORT_TYPE_APPLICATIONS),
  getLongUrl: getApiReducer(GET_LONG_URL),
  createSFLead: getApiReducer(CREATE_SF_LEAD),
  getLeadReaons: getApiReducer(GET_LEAD_REASONS),
  createLeadReason: getApiReducer(CREATE_LEAD_REASON),
  deleteLeads: getApiReducer(DELETE_LEADS),
  getCountLatestNewLeads: getApiReducer(GET_COUNT_LATEST_NEW_LEADS),
});

export default reducers;
