import React from 'react';

const AddBox = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 32 32">
      <path d="M28.4444444 0H3.55555556C1.58222196 0 0 1.60000016 0 3.55555556V28.4444444C0 30.4000005 1.58222197 32 3.55555556 32H28.4444444C30.4000005 32 32 30.4000005 32 28.4444444V3.55555556C32 1.60000016 30.4000005 0 28.4444444 0zm-3.5555555 17.7777778h-7.1111111v7.1111111h-3.5555556v-7.1111111H7.1111111v-3.5555556h7.1111111V7.1111111h3.5555556v7.1111111h7.1111111v3.5555556z" />
    </svg>
  );
  /* eslint-enable */
};

export default AddBox;
