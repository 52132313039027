import React from 'react';

const Remove = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="3 3 26 26">
      <path d="M25.6974644 28.9299526L16 19.2324881l-9.69746443 9.6974645-3.23248814-3.2324882L12.7675119 16 3.07004743 6.30253557l3.23248814-3.23248814L16 12.7675119l9.6974644-9.69746447 3.2324882 3.23248814L19.2324881 16l9.6974645 9.6974644" />
    </svg>
  );
  /* eslint-enable */
};

export default Remove;
