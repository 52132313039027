import React from 'react';

const About = () => {
  /* eslint-disable max-len */
  return (
    <svg
      width="734px"
      height="423px"
      viewBox="0 0 734 423"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M0 19.3458C0 8.7454 8.5946.152 19.194.152h274.575c10.6007 0 19.1942 8.5907 19.1942 19.1938v192.8474c0 10.6004-8.5946 19.1937-19.194 19.1937H19.194C8.5934 231.387 0 222.7962 0 212.193V19.3458z"
          id="path-1"
        />
        <mask id="mask-3" x="-6" y="-6" width="324.96319" height="243.234744">
          <path fill="#fff" d="M-6-5.848h324.9632v243.235H-6z" />
          <use xlinkHref="#path-1" />
        </mask>
        <path id="path-4" d="M0 0h346.714v28.6H0z" />
        <mask id="mask-5" x="-6" y="-6" width="358.714122" height="40.6000867">
          <path fill="#fff" d="M-6-6h358.714v40.6H-6z" />
          <use xlinkHref="#path-4" />
        </mask>
      </defs>
      <g id="Landing-Page" fill="none" fillRule="evenodd">
        <g id="Solutions-Landing-Page-Alternate-Hero-Image">
          <g id="Group-11">
            <g id="Group-24">
              <g id="Starts" opacity="0.299999982">
                <path
                  d="M91.413 62.0216V50.4723c0-2.1952-1.7812-3.9723-3.9784-3.9723-2.1928 0-3.9784 1.7784-3.9784 3.9723v11.5493H71.9068c-2.195 0-3.9722 1.7812-3.9722 3.9784 0 2.1927 1.7784 3.9784 3.9722 3.9784h11.5494v11.5493c0 2.1952 1.7812 3.9723 3.9784 3.9723 2.1927 0 3.9783-1.7784 3.9783-3.9723V69.9784h11.5493c2.1952 0 3.9723-1.7812 3.9723-3.9784 0-2.1927-1.7785-3.9784-3.9723-3.9784H91.413z"
                  id="Combined-Shape"
                  fill="#FF5B58"
                />
                <path
                  d="M612.4177 198.3725v-8.6297c0-1.6407-1.3443-2.9928-3.0026-2.9928-1.6547 0-3.0024 1.34-3.0024 2.9928v8.6297h-8.6297c-1.6407 0-2.993 1.3442-2.993 3.0025 0 1.655 1.34 3.0025 2.993 3.0025h8.6296v8.6297c0 1.6407 1.3443 2.9928 3.0025 2.9928 1.655 0 3.0027-1.34 3.0027-2.9928v-8.6297h8.6296c1.6407 0 2.9928-1.3442 2.9928-3.0025 0-1.655-1.3398-3.0025-2.9927-3.0025h-8.6296z"
                  id="Combined-Shape-Copy"
                  fill="#FF5B58"
                />
                <path
                  d="M391.6175 18.521V4.7362c0-2.6174-2.1172-4.7362-4.729-4.7362-2.6064 0-4.729 2.1205-4.729 4.7362V18.521h-13.7848c-2.6173 0-4.7362 2.1172-4.7362 4.729 0 2.6064 2.1205 4.729 4.7362 4.729h13.7848v13.7848c0 2.6174 2.1173 4.7362 4.729 4.7362 2.6065 0 4.729-2.1204 4.729-4.7362V27.979h13.785c2.6172 0 4.736-2.1172 4.736-4.729 0-2.6064-2.1204-4.729-4.736-4.729h-13.785z"
                  id="Combined-Shape-Copy-2"
                  fill="#0099CC"
                />
                <path
                  d="M678.3127 330.5216v-11.5493c0-2.1952-1.7812-3.9723-3.9783-3.9723-2.1928 0-3.9784 1.7784-3.9784 3.9723v11.5493h-11.5494c-2.1952 0-3.9722 1.7812-3.9722 3.9784 0 2.1927 1.7784 3.9784 3.9722 3.9784h11.5494v11.5493c0 2.1952 1.7812 3.9723 3.9784 3.9723 2.1927 0 3.9783-1.7784 3.9783-3.9723v-11.5493h11.5494c2.1953 0 3.9724-1.7812 3.9724-3.9784 0-2.1927-1.7785-3.9784-3.9723-3.9784h-11.5493z"
                  id="Combined-Shape-Copy-3"
                  fill="#0099CC"
                />
                <path
                  d="M131.19 217.2716v-11.5493c0-2.1952-1.7812-3.9723-3.9783-3.9723-2.1928 0-3.9784 1.7784-3.9784 3.9723v11.5493H111.684c-2.1953 0-3.9723 1.7812-3.9723 3.9784 0 2.1927 1.7784 3.9784 3.9722 3.9784h11.5493v11.5493c0 2.1952 1.7812 3.9723 3.9784 3.9723 2.1927 0 3.9783-1.7784 3.9783-3.9723v-11.5493h11.5494c2.1952 0 3.9723-1.7812 3.9723-3.9784 0-2.1927-1.7785-3.9784-3.9723-3.9784H131.19z"
                  id="Combined-Shape-Copy-4"
                  fill="#FF5B58"
                />
                <path
                  d="M170.967 399.5216v-11.5493c0-2.1952-1.781-3.9723-3.9782-3.9723-2.1928 0-3.9784 1.7784-3.9784 3.9723v11.5493H151.461c-2.1952 0-3.9722 1.7812-3.9722 3.9784 0 2.1927 1.7784 3.9784 3.9722 3.9784h11.5494v11.5493c0 2.1952 1.7812 3.9723 3.9784 3.9723 2.1927 0 3.9783-1.7784 3.9783-3.9723v-11.5493h11.5495c2.1952 0 3.9723-1.7812 3.9723-3.9784 0-2.1927-1.7785-3.9784-3.9723-3.9784H170.967z"
                  id="Combined-Shape-Copy-5"
                  fill="#FF5B58"
                />
                <path
                  d="M714.1115 183.75c-10.9842 0-19.8886-8.7304-19.8886-19.5s8.9043-19.5 19.8885-19.5c10.984 0 19.8885 8.7304 19.8885 19.5s-8.9044 19.5-19.8885 19.5zm0-7.8c6.5904 0 11.933-5.2383 11.933-11.7 0-6.4617-5.3426-11.7-11.933-11.7-6.5905 0-11.9332 5.2383-11.9332 11.7 0 6.4617 5.3427 11.7 11.9332 11.7z"
                  id="Combined-Shape"
                  fill="#FF5B58"
                />
                <path
                  d="M555.003 423c-10.984 0-19.8885-8.7304-19.8885-19.5S544.019 384 555.003 384s19.8886 8.7304 19.8886 19.5-8.9044 19.5-19.8885 19.5zm0-7.8c6.5906 0 11.9332-5.2383 11.9332-11.7 0-6.4617-5.3426-11.7-11.933-11.7-6.5906 0-11.9333 5.2383-11.9333 11.7 0 6.4617 5.3426 11.7 11.933 11.7z"
                  id="Combined-Shape-Copy-6"
                  fill="#0099CC"
                />
                <path
                  d="M550.1247 115.5c-8.29 0-15.0102-6.5478-15.0102-14.625 0-8.0772 6.7203-14.625 15.0102-14.625 8.29 0 15.0103 6.5478 15.0103 14.625 0 8.0772-6.7203 14.625-15.0103 14.625zm0-5.85c4.974 0 9.0062-3.9287 9.0062-8.775s-4.0323-8.775-9.0063-8.775c-4.974 0-9.006 3.9287-9.006 8.775s4.032 8.775 9.006 8.775z"
                  id="Combined-Shape-Copy-7"
                  fill="#FF5B58"
                />
                <path
                  d="M226.279 66c-10.984 0-19.8884-8.7304-19.8884-19.5S215.295 27 226.279 27c10.9843 0 19.8887 8.7304 19.8887 19.5S237.2633 66 226.279 66zm0-7.8c6.5906 0 11.9333-5.2383 11.9333-11.7 0-6.4617-5.3427-11.7-11.9332-11.7-6.5903 0-11.933 5.2383-11.933 11.7 0 6.4617 5.3427 11.7 11.933 11.7z"
                  id="Combined-Shape-Copy-8"
                  fill="#FF5B58"
                />
                <path
                  d="M19.8885 216C8.9045 216 0 207.2696 0 196.5S8.9044 177 19.8885 177c10.9842 0 19.8886 8.7304 19.8886 19.5S30.8728 216 19.8886 216zm0-7.8c6.5905 0 11.9332-5.2383 11.9332-11.7 0-6.4617-5.3427-11.7-11.9332-11.7-6.5904 0-11.933 5.2383-11.933 11.7 0 6.4617 5.3426 11.7 11.933 11.7z"
                  id="Combined-Shape-Copy-9"
                  fill="#0099CC"
                />
                <path
                  d="M63.4182 378.75c-13.0566 0-23.641-10.4094-23.641-23.25s10.5844-23.25 23.641-23.25c13.0566 0 23.641 10.4094 23.641 23.25s-10.5844 23.25-23.641 23.25zm0-9.3c7.834 0 14.1847-6.2456 14.1847-13.95s-6.3508-13.95-14.1848-13.95-14.1847 6.2456-14.1847 13.95 6.3507 13.95 14.1847 13.95z"
                  id="Combined-Shape-Copy-10"
                  fill="#0099CC"
                />
              </g>
              <g id="Icon">
                <g id="Editor" transform="translate(210.143 118.206)">
                  <mask id="mask-2" fill="#fff">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g id="Icon-BG">
                    <use fill="#FFF" xlinkHref="#path-1" />
                    <use stroke="#4A4A4A" mask="url(#mask-3)" strokeWidth="12" xlinkHref="#path-1" />
                  </g>
                  <g id="Taskbar" mask="url(#mask-2)">
                    <g id="Icon-BG" transform="translate(-16.875 .152)">
                      <use fill="#D3D3D3" xlinkHref="#path-4" />
                      <use stroke="#4A4A4A" mask="url(#mask-5)" strokeWidth="12" xlinkHref="#path-4" />
                    </g>
                    <g id="Group-12" stroke="#4A4A4A" strokeWidth="3.75">
                      <path
                        d="M21.938 20.8416c3.3892 0 6.1366-2.7245 6.1366-6.0852 0-3.3607-2.7474-6.085-6.1365-6.085-3.389 0-6.1364 2.7243-6.1364 6.085s2.7474 6.0852 6.1365 6.0852z"
                        id="Oval-1"
                        fill="#FC615D"
                      />
                      <path
                        d="M40.3477 20.8416c3.389 0 6.1365-2.7245 6.1365-6.0852 0-3.3607-2.7474-6.085-6.1365-6.085-3.389 0-6.1365 2.7243-6.1365 6.085s2.7474 6.0852 6.1365 6.0852z"
                        id="Oval-1-Copy"
                        fill="#FDBC40"
                      />
                      <path
                        d="M58.7573 20.8416c3.389 0 6.1365-2.7245 6.1365-6.0852 0-3.3607-2.7474-6.085-6.1365-6.085-3.389 0-6.1365 2.7243-6.1365 6.085s2.7474 6.0852 6.1365 6.0852z"
                        id="Oval-1-Copy-2"
                        fill="#00E815"
                      />
                    </g>
                  </g>
                </g>
                <g id="cog" stroke="#4A4A4A" strokeWidth="4.5" fill="#B8C2C5">
                  <g id="Group">
                    <path
                      d="M300.468 214.6326c-.6752-1.1294-2.0055-1.6995-3.3025-1.4154-1.4708.3367-2.9227.753-4.3466 1.2525-1.2477.4455-2.0723 1.6316-2.0464 2.9447.0508 2.8847.1447 7.6614.1447 7.6614-2.6276 1.4925-5.0106 3.3747-7.066 5.5812l-7.484-1.869c-1.285-.3212-2.637.2106-3.3564 1.32-.8053 1.2687-1.5405 2.5796-2.1962 3.9295-.5693 1.1884-.3096 2.601.6448 3.5066 2.0927 1.9937 5.5654 5.2882 5.5654 5.2882-.8034 2.8925-1.1552 5.888-1.0432 8.8818 0 0-4.147 2.4525-6.6518 3.9336-1.1422.6754-1.724 1.9977-1.4442 3.2826.332 1.457.7442 2.8948 1.2406 4.3042.4426 1.2355 1.6345 2.0473 2.9583 2.0147 2.9092-.065 7.7258-.1828 7.7258-.1828 1.4915 2.598 3.377 4.9518 5.5913 6.9794l-1.9233 7.4323c-.3304 1.2758.199 2.6142 1.3138 3.322 1.2748.7918 2.593 1.5142 3.9507 2.1576 1.1955.5588 2.621.2937 3.5392-.6575 2.021-2.0858 5.3605-5.5467 5.3605-5.5467 2.9123.7818 5.9307 1.1154 8.9498.9887 0 0 2.4517 4.1006 3.932 6.577.675 1.1293 2.0055 1.6997 3.3025 1.4156 1.4707-.337 2.9226-.7534 4.3465-1.2528 1.248-.4454 2.0722-1.6317 2.0464-2.9444-.051-2.8848-.1448-7.6615-.1448-7.6615 2.6275-1.4926 5.0105-3.375 7.066-5.5813l7.4842 1.869c1.2847.321 2.637-.2108 3.356-1.3203.8054-1.2683 1.5405-2.5795 2.1963-3.9292.5693-1.1886.3098-2.6013-.6446-3.5067-2.093-1.9937-5.5652-5.288-5.5652-5.288.8033-2.8928 1.155-5.8882 1.043-8.8817 0 0 4.1473-2.4527 6.6516-3.934 1.1422-.6753 1.724-1.9976 1.4443-3.2823-.332-1.457-.7442-2.8948-1.2407-4.3044-.4426-1.2354-1.6344-2.047-2.9583-2.0146-2.9088.0653-7.7257.1832-7.7257.1832-1.4913-2.5984-3.377-4.9523-5.591-6.9795l1.923-7.432c.3303-1.2763-.199-2.6145-1.3138-3.3222-1.275-.792-2.593-1.5143-3.9508-2.158-1.1954-.5584-2.621-.2936-3.539.6575-2.0207 2.086-5.3606 5.547-5.3606 5.547-2.9124-.782-5.9307-1.1152-8.95-.9886l-3.932-6.577zm1.4432 23.3404c6.9678-1.998 14.2313 1.982 16.21 8.8827 1.9787 6.9004-2.072 14.1248-9.0397 16.1227-6.968 1.998-14.2316-1.982-16.2103-8.8824-1.9787-6.9006 2.072-14.125 9.04-16.123z"
                      id="Shape"
                    />
                    <path
                      d="M363.3416 179.256c-.714-1.105-2.0634-1.6285-3.3498-1.2993-1.458.388-2.8946.8546-4.3 1.4035-1.2315.4888-2.0142 1.703-1.9425 3.0143.1515 2.8812.412 7.6517.412 7.6517-2.5738 1.5833-4.8897 3.5475-6.867 5.8244l-7.5445-1.6067c-1.2955-.2762-2.6282.3024-3.3083 1.4363-.7604 1.296-1.4494 2.6317-2.0577 4.0037-.5275 1.2076-.2186 2.6103.767 3.482 2.161 1.9193 5.7464 5.0906 5.7464 5.0906-.702 2.9188-.949 5.9247-.7326 8.9128l-6.5105 4.1634c-1.1178.7148-1.653 2.0566-1.3286 3.331.3826 1.4446.8448 2.867 1.39 4.2583.4855 1.2194 1.705 1.989 3.0268 1.9103 2.9052-.1667 7.7147-.4524 7.7147-.4524 1.5812 2.5442 3.5478 4.8308 5.8314 6.78 0 0-1.0368 4.6727-1.6628 7.4948-.2856 1.2866.29 2.6058 1.429 3.274 1.3017.747 2.6443 1.423 4.0236 2.0186 1.2143.5167 2.6297.202 3.514-.7806 1.947-2.155 5.1637-5.7305 5.1637-5.7305 2.9378.6796 5.966.9077 8.979.6758l4.159 6.4357c.714 1.105 2.0635 1.6288 3.3498 1.2996 1.458-.388 2.8946-.855 4.3-1.4037 1.232-.4887 2.0142-1.703 1.9425-3.014-.1514-2.8813-.412-7.6518-.412-7.6518 2.574-1.5833 4.8897-3.5477 6.867-5.8244l7.5448 1.6067c1.2953.276 2.628-.3026 3.308-1.4365.7606-1.2957 1.4495-2.6318 2.058-4.0035.5273-1.2078.2186-2.6106-.7667-3.482-2.1614-1.9196-5.7465-5.0907-5.7465-5.0907.702-2.919.949-5.925.7323-8.9127l6.5105-4.1636c1.118-.715 1.6532-2.0567 1.3288-3.3308-.3826-1.4446-.8448-2.867-1.3902-4.2585-.4853-1.2193-1.7047-1.9887-3.0266-1.9102-2.9048.1668-7.7146.4527-7.7146.4527-1.581-2.5448-3.5477-4.8314-5.8312-6.78 0 0 1.0364-4.6732 1.6624-7.495.2856-1.287-.29-2.6057-1.429-3.274-1.3017-.7473-2.6442-1.423-4.0236-2.019-1.2142-.5163-2.6297-.202-3.5138.7806-1.9468 2.1555-5.1638 5.731-5.1638 5.731-2.938-.68-5.966-.9078-8.979-.676 0 0-2.5933-4.0124-4.1592-6.4357zm2.257 23.2758c6.894-2.24 14.292 1.4843 16.5102 8.3116 2.2183 6.8272-1.5778 14.1885-8.4716 16.4284-6.894 2.24-14.292-1.484-16.5103-8.3112-2.2185-6.8274 1.5776-14.1888 8.4717-16.4288z"
                      id="Shape-Copy"
                    />
                    <path
                      d="M424.9992 232.5892c-.3818-1.2592-1.5347-2.1342-2.862-2.1723-1.5085-.0292-3.018.0235-4.5203.1637-1.3185.1305-2.4056 1.0818-2.698 2.362-.6486 2.8115-1.7132 7.469-1.7132 7.469-2.9105.8125-5.678 2.0623-8.2063 3.706l-6.8094-3.624c-1.169-.6225-2.6097-.4337-3.576.4688-1.0882 1.0362-2.1187 2.1303-3.0816 3.2815-.84 1.0153-.9295 2.449-.2226 3.5584 1.5483 2.4407 4.1208 6.4774 4.1208 6.4774-1.4793 2.6122-2.5453 5.4336-3.161 8.3656l-7.4058 2.2076c-1.2717.379-2.156 1.5212-2.1954 2.8357-.0304 1.494.0218 2.989.1625 4.4765.1304 1.3058 1.0905 2.3818 2.383 2.6705 2.8384.6405 7.5404 1.6915 7.5404 1.6915.8187 2.8817 2.0788 5.6218 3.7368 8.1248 0 0-2.2847 4.206-3.6642 6.7462-.6293 1.158-.4394 2.5848.471 3.5412 1.0454 1.0768 2.1497 2.0966 3.3115 3.0493 1.0248.8314 2.472.919 3.593.2183 2.4656-1.535 6.5432-4.0852 6.5432-4.0852 2.6367 1.463 5.4847 2.517 8.4448 3.1245 0 0 1.387 4.572 2.2242 7.3327.3817 1.2592 1.5346 2.1345 2.8618 2.1726 1.5086.0288 3.018-.024 4.5205-.164 1.3187-.1303 2.4054-1.082 2.698-2.362.6485-2.8113 1.713-7.4688 1.713-7.4688 2.9106-.8125 5.678-2.0625 8.2063-3.706 0 0 4.246 2.2595 6.8097 3.624 1.169.6223 2.6096.4334 3.576-.469 1.088-1.036 2.1185-2.1303 3.0814-3.2813.84-1.0156.93-2.449.223-3.5584-1.5486-2.4408-4.1208-6.4773-4.1208-6.4773 1.4793-2.6125 2.5452-5.4338 3.1606-8.3656 0 0 4.6175-1.3763 7.4058-2.2077 1.2717-.379 2.156-1.5213 2.1954-2.8355.0305-1.494-.0217-2.989-.1624-4.4767-.1306-1.3058-1.0907-2.3815-2.383-2.6704-2.8383-.6403-7.5406-1.6913-7.5406-1.6913-.8183-2.882-2.0785-5.622-3.7364-8.1248 0 0 2.2843-4.2063 3.664-6.7462.629-1.1584.4393-2.5848-.4712-3.5412-1.0454-1.077-2.1497-2.0965-3.3114-3.0495-1.0248-.831-2.472-.919-3.5928-.2183-2.4655 1.5353-6.5435 4.0855-6.5435 4.0855-2.6366-1.4633-5.4846-2.517-8.4447-3.1245 0 0-1.3868-4.572-2.224-7.333zm-4.246 22.9962c7.244-.253 13.329 5.366 13.5795 12.5405.2505 7.174-5.4276 13.2038-12.6717 13.4568-7.2444.253-13.3293-5.366-13.58-12.5402-.2504-7.1744 5.4277-13.2042 12.672-13.4572z"
                      id="Shape-Copy-2"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default About;
