import { isEmail } from 'util/validate';
import { t } from 'components/translation/Translation';

export const validateWaitlist = (values, dynamicInputs) => {
  let errors = {};

  if (!values.first_name) {
    errors.first_name = t('home:waitlist.errors.firstName');
  }

  if (!values.last_name) {
    errors.last_name = t('home:waitlist.errors.lastName');
  }

  if (!values.job_title) {
    errors.job_title = t('home:waitlist.errors.role');
  }

  if (!values.email) {
    errors.email = t('home:waitlist.errors.email');
  } else if (!isEmail(values.email)) {
    errors.email = t('home:waitlist.errors.invalidEmail');
  }

  if (dynamicInputs) {
    if (!values.school_name) {
      errors.school_name = t('home:waitlist.errors.schoolName');
    }

    // if(!values.school_address) {
    //   errors.school_address = t('home:waitlist.errors.address')
    // }

    // if(!values?.school_grades.length) {
    //   errors.school_grades = t('home:waitlist.errors.grade')
    // }

    // if(!values?.school_type) {
    //   errors.school_type = t('home:waitlist.errors.type')
    // }
  }

  return errors;
};
