import client from '../client';
import axios from 'axios';

export const joinPlusWaitlist = async (values) => {
  const payload = {
    list: 'ScholaPlus',
    userAgent: navigator.userAgent,
    language: navigator.language,
    ...values,
  };
  var ipData = await axios.get('https://api.ipify.org/?format=json');
  if (ipData) {
    payload.ip = ipData.data.ip;
  }

  if (navigator.geolocation) {
    try {
      const position = await getPosition({ enableHighAccuracy: true });
      payload.geolocation = `lat:${position.coords.latitude},lon:${position.coords.longitude}`;

      return await client.post('waitlist', payload, { credentials: 'omit' });
    } catch (error) {
      return await client.post('waitlist', payload, { credentials: 'omit' });
    }
  } else {
    return await client.post('waitlist', payload, { credentials: 'omit' });
  }
};

const getPosition = (options) => {
  return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));
};
