import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSchoolSitesReviews } from 'redux/school/school-actions';

export const useReviews = (schoolId) => {
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    dispatch(getSchoolSitesReviews(schoolId))
      .then((reviews) => {
        setReviews(reviews.json);
      })
      .catch((err) => console.log(err));
  }, []);

  return { reviews };
};
