export default {
  progress: 0,
  mision: '',
  learningModels: [],
  briefDescription: '',
  buildRelationship: '',
  socialMedia: [],
  gradesPriority: {
    pre_k: 'low',
    grade_1: 'low',
    grade_2: 'low',
    grade_3: 'low',
    grade_4: 'low',
    grade_5: 'low',
    grade_6: 'low',
  },
  currentEnrollment: {
    pre_k: '0',
    grade_1: '0',
    grade_2: '0',
    grade_3: '0',
    grade_4: '0',
    grade_5: '0',
    grade_6: '0',
  },
  goalsEnrollment: {
    pre_k: '0',
    grade_1: '0',
    grade_2: '0',
    grade_3: '0',
    grade_4: '0',
    grade_5: '0',
    grade_6: '0',
  },
  appicationDeadlineDate: '',
  offerTransport: null,
  zipsOffer: [],
  zipsTarget: [],
  partsNeedToBeDigitized: '',
  otherOptionDigitized: '',
  featuringVideos: [],
  currentlyEnrollementSystem: null,
  currentlyEnrollementSystemList: [],
  listEnrollmentSteps: [],
};
