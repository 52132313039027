import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';

import { UPDATE_USER, REQUEST_CHANGE_PASSWORD_LINK, GET_USER_PROFILE } from './user-actions.js';

const reducers = combineReducers({
  updateUser: getApiReducer(UPDATE_USER),
  requestChangePasswordLink: getApiReducer(REQUEST_CHANGE_PASSWORD_LINK),
  getUserProfile: getApiReducer(GET_USER_PROFILE),
});

export default reducers;
