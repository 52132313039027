import React from 'react';
import { Container, SeeProfile } from '..';
import styles from './profile-interaction.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import ReactTooltip from 'react-tooltip';

export const ProfileInteraction = ({ appearances, progress, editProfile, liveProfile }) => {
  return (
    <Container>
      <Container.Header title="Profile Interaction" />
      <Container.Body className={styles.parentProgress}>
        {progress && (
          <div className={styles.completion}>
            <p>Profile Completion:</p> <CircularProgressBar percentage={progress} />{' '}
          </div>
        )}
        <div className={styles.cardsWrapper}>
          <CardInteraction label="Appearances" amount={appearances} />
        </div>
        <SeeProfile editProfile={editProfile} liveProfile={liveProfile} />
      </Container.Body>
    </Container>
  );
};

const CardInteraction = ({ label = '', amount = 0 }) => {
  return (
    <div className={styles.card}>
      <span className={styles.card_label}>
        {label}
        <InfoCircleOutlined data-for="appareance-tip" data-tip />
        <ReactTooltip
          id="appareance-tip"
          place="top"
          effect="solid"
          backgroundColor="rgba(0, 31, 41, 0.9)"
          className={styles.tooltip}>
          <b>Appearances:</b> the number of times your profile appeared in the ScholaMatch™ results for parents
          searching for schools.
        </ReactTooltip>
      </span>
      <h3>{amount}</h3>
    </div>
  );
};

const CircularProgressBar = ({ percentage }) => {
  const radius = 20;
  const strokeWidth = 3;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div>
      <svg height={radius * 2} width={radius * 2} className={styles.circularProgressBar}>
        <circle
          stroke="#f5f5f5"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="#006b8f"
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fill="#000">
          {percentage}%
        </text>
      </svg>
    </div>
  );
};
