import { useCallback, useEffect, useState } from 'react';
import { useNodesState, useEdgesState, addEdge, useReactFlow } from '@xyflow/react';
import { useNodePlacement } from '.';

export const useFlowDesigner = (campaign) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState(null);

  const { fitView, setViewport } = useReactFlow();

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      console.log(flow);
      console.log(JSON.stringify(flow));
    }
  }, [rfInstance]);

  const getFlowElements = (key) => {
    const item = campaign.params?.find((item) => item.key === key);
    if (item?.value) {
      return item.value;
    }
  };

  // simulate async node map
  useEffect(() => {
    if (campaign) {
      const nodes = getFlowElements('nodes');
      const edges = getFlowElements('edges');
      const viewport = getFlowElements('viewport') || {};

      if (nodes?.length > 0) {
        restoreFlow({ nodes, edges, viewport });
      }
    }
  }, [campaign, setNodes, setEdges, setViewport]);

  const restoreFlow = async ({ nodes, edges, viewport }) => {
    // Provide default values
    const { x = 0, y = 0, zoom = 1 } = viewport;
    setNodes(nodes || []);
    setEdges(edges || []);
    setViewport({ x, y, zoom });
  };

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const { calculateNodePosition } = useNodePlacement();

  const createNode = (type, event) => {
    const position = calculateNodePosition(event);

    const isFirstNode = nodes.length === 0;

    let newNodeData;
    switch (type) {
      case 'emailNode':
        newNodeData = [{ key: '{{subject}}', value: 'New email!' }];
        break;
      case 'smsNode':
        newNodeData = {
          type: 'smsNode',
          content: '',
          isFirstNode,
        };
        break;
      case 'timerNode':
        newNodeData = {
          type: 'timerNode',
          delay: 24,
          unit: 'hours',
          isFirstNode,
        };
        break;
      case 'conditionNode':
        newNodeData = {
          type: 'conditionNode',
          userAction: 'open-last-message',
          condition: 'true',
          timeframe: 24,
          isFirstNode,
        };
        break;
      default:
        return;
    }

    const newNode = {
      id: `${type}-${nodes.length + 1}`,
      type,
      position,
      isFirstNode: false,
      data: newNodeData,
    };

    setNodes((nds) => {
      const newNodes = [...nds, newNode];
      // Fit view after adding the new node
      setTimeout(() => {
        fitView({
          padding: 0.2,
          duration: 800,
        });
      }, 50);
      return newNodes;
    });
  };

  const onNodeClick = useCallback(
    (type) => {
      createNode(type, null);
    },
    [createNode]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData('application/reactflow');
      if (!type) return;
      createNode(type, event);
    },
    [createNode]
  );

  return {
    onNodesChange,
    onEdgesChange,
    setRfInstance,
    nodes,
    edges,
    onConnect,
    onDragOver,
    onNodeClick,
    onDrop,
    onSave,
  };
};
