export const Select = ({ id, label, options, value, fieldParam, onChangeParams }) => {
  return (
    <div className="form-group col-12 col-md-6">
      <label>{label}</label>
      <select
        id={id}
        className="form-control"
        onChange={(e) => onChangeParams({ key: fieldParam, value: e.target.value })}
        value={value}>
        <option disabled>Select ...</option>
        {options.map((opt) => {
          return (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
