import { _getLeadsStatusCount } from 'controllers/leads/leads_controller';

const { useState, useEffect } = require('react');
const { useDispatch } = require('react-redux');
const { getSchoolDashboard } = require('redux/school/school-actions');

export const useProfileInteraction = (id, sinceDate) => {
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState({});
  useEffect(() => {
    if (sinceDate) {
      getDashboard(id);
    }
  }, [sinceDate]);

  const getDashboard = async () => {
    const data = await dispatch(getSchoolDashboard(id, sinceDate));
    const { scholaMatchAppearances } = data.json;
    setDashboard({
      scholaMatchAppearances,
    });
  };

  return { scholaMatchAppearances: dashboard.scholaMatchAppearances };
};
