import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/Button';
import styles from './table-pagination.module.scss';

export const TablePagination = ({ totalItems, setPagination }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setPagination({
        page: currentPage - 1,
        pageSize: itemsPerPage,
      });
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setPagination({
        page: currentPage + 1,
        pageSize: itemsPerPage,
      });
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        Displaying {startItem}-{endItem} of {totalItems}
      </div>
      <div className={styles.items}>
        <ul className={styles.pagination}>
          <li>
            <select
              className="page-size__select"
              name="page_size"
              onChange={(e) => {
                const value = Number(e.target.value);
                setItemsPerPage(value);
                setPagination({
                  page: 1,
                  pageSize: value,
                });
              }}
              value={itemsPerPage}>
              {[10, 20, 50, 100, 500].map((t, i) => {
                return (
                  <option key={t} value={t}>
                    {t}
                  </option>
                );
              })}
            </select>
          </li>
          <li>Per Page</li>
          <li>
            <Button variant="outline" size="sm" onClick={handlePrevClick} disabled={currentPage === 1}>
              Prev
            </Button>
          </li>
          <li className={styles.pageNumber}>{currentPage}</li>
          <li>
            <Button color="secondary" size="sm" onClick={handleNextClick} disabled={currentPage === totalPages}>
              Next
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  displayedItems: PropTypes.number.isRequired,
};
