import React from 'react';
import { FullScreenModal } from 'components/fullscreen-modal/fullscreen-modal';
import { Button } from 'schola-components';
import { Calendar16 } from '@carbon/icons-react';
import styles from './plans-modal.module.scss';

export const PlansModal = ({ onClose, children }) => {
  return (
    <FullScreenModal onClose={onClose}>
      <div className={styles.contentScroll}>
        {children}

        <div className={styles.salesContainer}>
          <div className={styles.salesBox}>
            <div className={styles.images}>
              <img
                src="https://scholamatch-static.s3.us-west-2.amazonaws.com/launchpage/briana-landing-page.png"
                alt=""
              />
            </div>
            <div>
              <h6>Sales Team</h6>
              <p className="spacing-my-8">833-4-SCHOLA</p>
              <p>sales@scholasolutions.com</p>
            </div>
          </div>

          <a
            href="https://pages.schola.com/meetings/schola-solutions/schedule-demo"
            target="_blank"
            rel="noopener noreferrer">
            <Button className="primary">
              <Calendar16 /> Contact Us
            </Button>
          </a>
        </div>
      </div>
    </FullScreenModal>
  );
};
