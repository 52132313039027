import { NodeWrapper } from '.';
import styles from '../flow-designer.module.scss';
import { MailOutlined } from '@ant-design/icons';

export const NodeEmail = ({ data, id }) => {
  const previewText = data.subject || 'No subject';

  return (
    <NodeWrapper isFirstNode={data.isFirstNode}>
      <NodeWrapper.Header id={id} Icon={MailOutlined}>
        Send Email
      </NodeWrapper.Header>
      <ContentPreview>{previewText}</ContentPreview>
    </NodeWrapper>
  );
};

export const ContentPreview = ({ children }) => {
  return (
    <div className={styles.emailNode}>
      <div className={styles.previewText}>{children}</div>
    </div>
  );
};
