import React from 'react';

const Private = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 48 29">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#8AC340"
        strokeWidth="1.2023">
        <path d="M11.3465761 27.9538171h25.4411732V9.7957683H11.3465761zM24.0663916.71718293L7.58806328 9.79510976H40.5458215z" />
        <path d="M11.3459152 15.9908415H.75396298v11.962317H47.3790406v-11.962317H36.7870884M16.8130633 27.9535976V9.79554878M31.3201606 27.9535976V9.79554878M16.8130633 15.9908415H31.31972" />
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default Private;
