import React from 'react';

const CurrentLocation = () => {
  /* eslint-disable max-len */
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <circle id="path-1" cx="8" cy="8" r="8" />
        <filter x="-43.8%" y="-31.2%" width="187.5%" height="187.5%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
      </defs>
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-381.000000, -254.000000)">
        <g id="Group" transform="translate(384.000000, 256.000000)">
          <g id="Oval">
            <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
          </g>
          <circle id="Oval" fill="#4A90E2" cx="8" cy="8" r="7" />
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default CurrentLocation;
