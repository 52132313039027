import client from '../client';

export const GET_ESTIMATION = 'GET_ESTIMATION';
export const CLEAR_ESTIMATION = 'CLEAR_ESTIMATION';

export function getEstimation(query) {
  return {
    type: GET_ESTIMATION,
    promise: client.get('campaign/estimation', query, { credentials: 'omit' }),
  };
}

export function clearEstimation() {
  return { type: CLEAR_ESTIMATION };
}
