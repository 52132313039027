// eslint-disable-next-line
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import URLSearchParams from 'url-search-params'; // eslint-disable-line
import { DevTools, store } from './redux/store.js';
import Routes from './components/Routes.js';
import './index.css';
import './sass/App.scss';
import config from 'config';
import { I18nextProvider } from 'react-i18next';
import i18next from './translations/i18n';
import { ConfigProvider } from 'antd';

require('autotrack');
// Facebook tracking
require('components/tracking/facebook_pixel');
// Hotjar
// require('components/tracking/hotjar');
// MCFX
// require('components/tracking/mcfx');
// Freshpaint
require('components/tracking/freshpaint');

// Google Analytics
const gaKey = config.google.analytics.key;

if (gaKey) {
  window.ga('create', gaKey, 'auto');

  // Only require the plugins you've imported above.
  window.ga('require', 'eventTracker');
  window.ga('require', 'outboundLinkTracker');
  window.ga('require', 'urlChangeTracker');
  window.ga('send', 'pageview');
}

window.URLSearchParams = URLSearchParams; // make ie happy

// <!-- Global site tag (gtag.js) - Google Ads-->
// var elem2 = document.createElement('script');
// elem2.src = `https://www.googletagmanager.com/gtag/js?id=${config.google.gtag.id}`;
// elem2.type = 'text/javascript';
// elem2.id = 'gtag-script-loader';
// elem2.async = true;
// elem2.defer = true;
// document.body.appendChild(elem2);

// var elem3 = document.createElement('script');
// elem3.type = 'text/javascript';
// var code = `
// window.dataLayer = window.dataLayer || [];
// function gtag(){dataLayer.push(arguments);}
// gtag('js', new Date());

// gtag('config', '${config.google.gtag.id}');
// `;
// try {
//   elem3.appendChild(code);
// } catch (error) {
//   elem3.text = code;
// }
// document.body.appendChild(elem3);

// Pardot Script
// if (process.env.REACT_APP_ENV === 'production') {
//   var elem4 = document.createElement('script');
//   elem4.type = 'text/javascript';
//   var code4 = `
//   piAId = '905942';
//   piCId = '7040';
//   piHostname = 'pi.pardot.com';
//   (function() {
//     function async_load(){
//       var s = document.createElement('script'); s.type = 'text/javascript';
//       s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
//       var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
//     }
//     if(window.attachEvent) { window.attachEvent('onload', async_load); }
//     else { window.addEventListener('load', async_load, false); }
//   })();
//   `;
//   try {
//     elem4.appendChild(code);
//   } catch (error) {
//     elem4.text = code4;
//   }
//   document.body.appendChild(elem4);
// }

// <!-- Google Places-->
var elem5 = document.createElement('script');
elem5.src = `https://maps.googleapis.com/maps/api/js?key=${config.google.places.apiKey}&libraries=places`;
elem5.type = 'text/javascript';
elem5.id = 'googleplaces-script-loader';
elem5.async = true;
elem5.defer = true;
document.body.appendChild(elem5);

class App extends Component {
  render() {
    return (
      <div>
        <ConfigProvider
          theme={{
            hashed: false,
            token: {
              colorPrimary: '#006b8f', // Color principal
              colorLink: '#1DA57A', // Color de los links
              borderRadius: 4,
              fontSizeHeading1: 36,
              fontSizeHeading2: 28,
              fontSizeHeading3: 24,
              fontSizeHeading4: 18,
              fontSizeHeading5: 16,
              fontSize: 12,
              fontFamilyCode: 'Open Sans, Montserrat, sans-serif',
            },
          }}>
          <I18nextProvider i18n={i18next}>
            <Provider store={store}>
              <div>
                {process.env.REACT_APP_ENV === 'development' ? <DevTools /> : null}
                <Routes />
              </div>
            </Provider>
          </I18nextProvider>
        </ConfigProvider>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
