import client from '../client';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const REQUEST_CHANGE_PASSWORD_LINK = 'REQUEST_CHANGE_PASSWORD_LINK';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_LEADS = 'GET_USER_LEADS';

export function updateUser(userId, data) {
  return {
    type: UPDATE_USER,
    promise: client.patch(`users/${userId}`, data, { credentials: 'omit' }),
  };
}

export function requestChangePasswordLink(userId, data) {
  return {
    type: REQUEST_CHANGE_PASSWORD_LINK,
    promise: client.post(`users/${userId}/password`, data, { credentials: 'omit' }),
  };
}

export function getUserProfile(userId) {
  return {
    type: GET_USER_PROFILE,
    promise: client.get(`users/${userId}/profile`, {}, { credentials: 'omit' }),
  };
}

export function getUserLeads(userId) {
  return {
    type: GET_USER_LEADS,
    promise: client.get(`users/${userId}/leads`, {}, { credentials: 'omit' }),
  };
}
