import React from 'react';

const GreenCheckmark = () => {
  /* eslint-disable max-len */
  return (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Feature check</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="ScholaSolution-Landing-Page"
        stroke="none"
        strokeWidth="2"
        fill="#24b356"
        fillRule="evenodd"
        transform="translate(-650.000000, -1302.000000)">
        <g id="Rectangle-3" transform="translate(604.000000, 1212.000000)">
          <g id="Feature-set-item" transform="translate(48.000000, 92.000000)">
            <g id="Feature-check">
              <circle id="Oval" stroke="#24b356" strokeWidth="3" cx="16" cy="16" r="15" />
              <g id="IC-Check-24px" transform="translate(4.000000, 4.000000)">
                <polygon id="Shape" points="0 0 24 0 24 24 0 24" fill="#24b356" />
                <polygon
                  id="Shape"
                  fill="#fff"
                  strokeWidth="2"
                  fillRule="nonzero"
                  points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default GreenCheckmark;
