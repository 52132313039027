import { ReactFlowProvider, ReactFlow, Controls, Background } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import styles from './flow-designer.module.scss';
import { EditNodeContent, NodeCondition, NodeEmail, NodeSms, NodeTimer, SelectorNode } from './components';
import { useFlowDesigner } from './hooks';
import { _getMarketingCampaign } from 'controllers/schools/schools_controller';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { onCloseEditModal } from 'redux/campaigns/campaigns-reducer';
import { Modal } from 'antd';

export const FlowDesigner = ({ params }) => {
  const { campaign_id: campaignId, id: schoolId } = params;
  const campaign = useGetCampaign({ schoolId, campaignId });
  return (
    <section className={styles.container}>
      <ReactFlowProvider>
        <Flow campaign={campaign} />
      </ReactFlowProvider>
    </section>
  );
};

const useGetCampaign = ({ schoolId, campaignId }) => {
  const [campaign, setCampaign] = useState({});
  useEffect(() => {
    _getMarketingCampaign(schoolId, campaignId)
      .then((response) => {
        const parsedParams = response.params.map((param) => ({
          ...param,
          value: JSON.parse(param.value),
        }));

        setCampaign({
          ...response,
          params: parsedParams,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return campaign;
};

const Flow = ({ campaign }) => {
  const { currentSchool } = useSelector((state) => state.school);

  const { isModalOpen, nodeId } = useSelector((state) => state.campaigns);

  const nodeTypes = {
    emailNode: NodeEmail,
    smsNode: NodeSms,
    timerNode: NodeTimer,
    conditionNode: NodeCondition,
  };

  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    setRfInstance,
    onConnect,
    onDragOver,
    onDrop,
    onNodeClick,
    onSave,
  } = useFlowDesigner(campaign || {});

  return (
    <>
      <SelectorNode campaignType={campaign?.platform} onNodeClick={onNodeClick} />
      <button onClick={onSave}>save</button>
      <div className={styles.canva}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onInit={setRfInstance}
          fitView
          fitViewOptions={{
            padding: 0.2,
            minZoom: 0.5,
            maxZoom: 1.5,
            duration: 500,
          }}
          minZoom={0.2}
          maxZoom={3}
          defaultViewport={{ x: 0, y: 0, zoom: 0.85 }}
          nodeTypes={nodeTypes}>
          <Controls />
          <Background />
        </ReactFlow>
      </div>
      {/* Edit Content from node selected */}
      <Modal open={isModalOpen} onCancel={onCloseEditModal} width={950} footer={null}>
        <EditNodeContent nodeId={nodeId} campaign={campaign} currentSchool={currentSchool} />
      </Modal>
    </>
  );
};
