import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { CheckmarkFilled16 } from '@carbon/icons-react';
import Button from 'components/button/v2/Button';
import { plans, check } from './plansData';
import { tString } from 'components/translation/Translation';
import { statusType, WaitlistScholaPlus } from 'components/waitlist-schols-plus/waitlist-schola-plus';
import { allowedZipcodes, isInAreaZip } from 'components/waitlist-schols-plus/utils';
// styles
import styles from './plans-table.module.scss';

export const PlansTable = ({
  className,
  onClickClaim,
  zipcode,
  schoolId,
  showSchoolSearcher = false,
  initialStep = statusType.previousWaitlist,
  fromHome = false,
  isPlusActive = false,
}) => {
  const [isScholaPlusOpen, setIsScholaPlusOpen] = useState(false);

  let plusButton = allowedZipcodes.includes(zipcode)
    ? tString('home:plansTable.upgrade')
    : tString('home:plansTable.joinButton');

  const handleScholaPlus = () =>
    isInAreaZip(zipcode, () => {
      setIsScholaPlusOpen(true);
    });

  const displayFeature = (feature) =>
    !feature ? '-' : feature === check ? <CheckmarkFilled16 fill="#0099CC" /> : feature;

  const scholaBasics = 'scholaBasics';
  const scholaPlus = 'scholaPlus';
  const scholaRecruiterPro = 'scholaRecruiterPro';

  const actionButtons = [
    ...(fromHome ? [{ id: scholaBasics, display: tString('home:plansTable.freeClaim') }] : [null]),

    ...(isPlusActive ? [null] : [{ id: scholaPlus, display: plusButton }]),

    { id: scholaRecruiterPro, display: tString('home:plansTable.upgrade') },
  ];

  const url = 'https://pages.schola.com/meetings/schola-solutions/schedule-demo';

  const handleUpgrade = (plan) => {
    if (plan === scholaRecruiterPro) window.open(url, '_blank').focus();
    if (plan === scholaBasics) onClickClaim();
    if (plan === scholaPlus) handleScholaPlus();
  };

  const getBackground = (index) => {
    return index % 2 !== 0;
  };

  return (
    <section className={className}>
      <h2 className={styles.title}>{tString('home:plansTable.title')}</h2>

      <div id="features" className={styles.gridWrapper}>
        <div className={styles.featuresRow}>
          <span />
          {plans.features.map((row, idx) => (
            <span key={idx} className={`${styles.featureName} ${getBackground(idx) ? styles.background : null}`}>
              {row.feature}
            </span>
          ))}
          <span />
        </div>

        <div className={styles.itemsGrid}>
          {plans.names.map((plan, idx) => (
            <div key={idx} id={plan?.id} className={styles.planName} style={{ background: plan?.color }}>
              <img src={plan?.logo} alt={plan?.name} style={plan.id === 'scholaPlus' ? { width: '45%' } : {}} />
            </div>
          ))}

          {plans.features.map((row, idx) => {
            return row.list.map((item, index) => (
              <span key={index} className={`${styles.item} ${getBackground(idx) ? styles.background : null}`}>
                {displayFeature(item)}
              </span>
            ));
          })}

          <span />
          <span className={styles.legend}>{tString('home:plansTable.legendLAandNY')}</span>
          <span />

          {actionButtons.map((action, idx) => {
            if (!action) return <span key={idx} />;
            return (
              <Button
                key={action.id}
                id={action.id}
                color="primary"
                className={styles.claim_button}
                onClick={() => handleUpgrade(action.id)}>
                {action.display}
              </Button>
            );
          })}
        </div>
      </div>

      {isScholaPlusOpen && (
        <WaitlistScholaPlus
          isScholaPlusOpen={isScholaPlusOpen}
          toggleScholaPlus={() => setIsScholaPlusOpen(false)}
          schoolId={schoolId}
          initialStep={initialStep}
          showSchoolSearcher={showSchoolSearcher}
        />
      )}
    </section>
  );
};
