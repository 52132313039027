import { combineReducers } from 'redux';
import getApiReducer from '../util/get-api-reducer';
import { GET_ABOUT_YOUR_SCHOOL, ADD_ABOUT_YOUR_SCHOOL, ADD_IMAGE_ABOUT_YOUR_SCHOOL } from './about-your-school-actions';

const reducers = combineReducers({
  getAboutYourSchool: getApiReducer(GET_ABOUT_YOUR_SCHOOL),
  addAboutYourSchool: getApiReducer(ADD_ABOUT_YOUR_SCHOOL),
  addImageAboutYourSchool: getApiReducer(ADD_IMAGE_ABOUT_YOUR_SCHOOL),
});

export default reducers;
