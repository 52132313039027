import { useEffect, useState } from 'react';
import { _getMarketingTemplates } from 'controllers/schools/schools_controller';

export const useTemplates = () => {
  const getTemplates = async () => {
    const templates = await _getMarketingTemplates();
    const validTemplates = templates.filter((template) => Number(template.id) !== 0);
    return validTemplates;
  };

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    getTemplates().then((templates) => setTemplates(templates));
  }, []);

  return templates;
};
