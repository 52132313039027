import React from 'react';
import { Container } from '..';
import styles from './campaigns-review.module.scss';
import classNames from 'classnames';
import { useCampaignStatus } from '../../hooks';
import { Link } from 'react-router';

export const CampaignsReview = ({ schoolId, link }) => {
  const { campaigns, isLoading, isSuccess } = useCampaignStatus({ schoolId });

  if (isLoading) {
    return <div className={styles.wrapperCenter}>-</div>;
  }

  return (
    <Container title="Campaigns" link={link}>
      <Container.Body>
        {isSuccess && campaigns.results.length > 0 ? (
          <>
            <div className={styles.row}>
              <span>Name</span>
              <span>Status</span>
            </div>

            <div className={styles.campaignsWrapper}>
              {campaigns.results.slice(0, 4).map((campaign) => (
                <CampaignItem key={campaign.id} name={campaign.name} status={campaign.status} />
              ))}
            </div>

            <span className={styles.additional}>
              {campaigns.results.length} Total Campaigns -{' '}
              <Link to={link} className={styles.link}>
                View in Detail
              </Link>
            </span>
          </>
        ) : (
          <div className={styles.wrapperCenter}>
            <p>No campaigns were found</p>
          </div>
        )}
      </Container.Body>
    </Container>
  );
};

const CampaignItem = ({ status, name }) => {
  const labelStatus = {
    [styles.active]: status === 'Active',
    [styles.paused]: status === 'Paused',
    [styles.running]: status === 'Running',
  };

  return (
    <div className={styles.campaign}>
      <p className={styles.campaignTitle}>{name}</p>
      <span className={classNames(styles.label, labelStatus)}>{status}</span>
    </div>
  );
};
