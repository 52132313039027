import React from 'react';

const SchoolMenu = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 36 36" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3391 3.46937C17.2863 2.6274 18.7137 2.6274 19.6609 3.46937L27.6609 10.5805C28.1946 11.0549 28.5 11.7349 28.5 12.449V33.4999H7.5V12.449C7.5 11.7349 7.80537 11.0549 8.33909 10.5805L16.3391 3.46937ZM10.5 12.6735V30.4999H25.5V12.6735L18 6.00687L10.5 12.6735Z"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 24.4999V30.4999H13.5V23.9999C13.5 22.6192 14.6193 21.4999 16 21.4999H20C21.3807 21.4999 22.5 22.6192 22.5 23.9999V30.4999H19.5V24.4999H16.5Z"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 16.9999C0.5 15.6192 1.61929 14.4999 3 14.4999H7.5V17.4999H3.5V30.4999H7.5V33.4999H3C1.61929 33.4999 0.5 32.3806 0.5 30.9999V16.9999Z"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 14.4999H33C34.3807 14.4999 35.5 15.6192 35.5 16.9999V30.9999C35.5 32.3806 34.3807 33.4999 33 33.4999H28.5V30.4999H32.5V17.4999H28.5V14.4999Z"
        fillOpacity="0.85"
      />
      <path
        d="M20 12.9999C20 14.1045 19.1046 14.9999 18 14.9999C16.8954 14.9999 16 14.1045 16 12.9999C16 11.8954 16.8954 10.9999 18 10.9999C19.1046 10.9999 20 11.8954 20 12.9999Z"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default SchoolMenu;
