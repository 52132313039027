import { useCallback } from 'react';
import { useReactFlow } from '@xyflow/react';

export const useNodePlacement = () => {
  const { getNodes, screenToFlowPosition } = useReactFlow();

  const calculateNodePosition = useCallback(
    (event) => {
      if (event) {
        // If dragged, use the drop position
        const reactFlowBounds = document.querySelector('.react-flow')?.getBoundingClientRect();
        if (reactFlowBounds) {
          const position = screenToFlowPosition({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
          });
          return position;
        }
      }

      // If clicked (no drag event), place to the right of existing nodes
      const nodes = getNodes();
      if (nodes.length === 0) {
        return { x: 100, y: 100 };
      }

      const lastNode = nodes[nodes.length - 1];
      return {
        x: lastNode.position.x + 250,
        y: lastNode.position.y,
      };
    },
    [getNodes, screenToFlowPosition]
  );

  return { calculateNodePosition };
};
