import * as React from 'react';
import { Modal } from 'antd';

export const AlertDialog = ({ visible, onCancel, onOk, title, description, children }) => (
  <Modal visible={visible} onCancel={onCancel} onOk={onOk} title={title} className={styles.modal}>
    <div className={styles.content}>
      {description && <div className={styles.description}>{description}</div>}
      {children}
    </div>
  </Modal>
);
