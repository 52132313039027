import React from 'react';

const Bulb = () => {
  /* eslint-disable max-len */
  return (
    <svg width="180px" height="180px" viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
      <g id="Illustrations" fill="none" fillRule="evenodd">
        <g id="LearningModels">
          <g id="Group-2">
            <g id="Group" stroke="#4A4A4A" strokeWidth="3.49726776" fill="#F6D270">
              <path
                d="M68.1745 115.173c-19.2636-8.439-32.738-27.7014-32.738-50.091 0-30.159 24.449-54.6447 54.5634-54.6447 30.114 0 54.5634 24.4856 54.5634 54.6448 0 22.3896-13.4746 41.652-32.738 50.091v25.579c0 5.7972-2.2997 11.3565-6.3928 15.4557C101.34 160.307 95.7884 162.61 90 162.61c-12.054 0-21.8255-9.7863-21.8255-21.858v-25.579z"
                id="Shape"
              />
            </g>
            <g id="Group" transform="translate(81.27 152.118)" stroke="#4A4A4A" strokeWidth="3.49661799" fill="#363636">
              <ellipse id="Oval" cx="8.73016091" cy="8.74316066" rx="8.73016091" ry="8.74316066" />
            </g>
            <g id="Group" stroke="#D7A212" strokeWidth="3.4970296">
              <path
                d="M81.5783 127.0052V77.1696c0-2.203-.8737-4.3156-2.4292-5.8734-1.5553-1.5577-3.6648-2.4327-5.8645-2.4327h-.8586c-4.3533 0-7.8834 3.5346-7.8834 7.895 0 2.1156.8477 4.143 2.352 5.6278 1.505 1.4855 3.5416 2.304 5.654 2.2723 9.6263-.1416 25.672-.3786 35.1758-.5187 4.289-.0637 7.7332-3.5636 7.7332-7.8605 0-2.0896-.8296-4.093-2.3057-5.5685-1.4762-1.476-3.4782-2.3034-5.564-2.2998-.101 0-.2012.0007-.3015.0007-4.4997.0078-8.1423 3.6624-8.1423 8.1688v50.4244"
                id="Shape"
              />
            </g>
            <g id="Group" stroke="#4A4A4A" strokeWidth="3.49465965" fill="#7C7C7C">
              <path
                d="M120.2597 132.8656c0-1.554-.6167-3.0448-1.7144-4.144-1.0972-1.099-2.5856-1.7166-4.138-1.7166H65.5932c-1.5522 0-3.0406.6177-4.1377 1.7167-1.0978 1.099-1.714 2.5898-1.714 4.144v.0012c0 1.5544.6162 3.045 1.714 4.144 1.097 1.0992 2.5855 1.7164 4.1378 1.7164h48.8144c1.5523 0 3.0407-.6172 4.138-1.7163 1.0976-1.099 1.7143-2.5896 1.7143-4.144v-.0014z"
                id="Shape"
              />
            </g>
            <g id="Group" stroke="#4A4A4A" strokeWidth="3.49538448" fill="#7C7C7C">
              <path
                d="M115.4574 144.588c0-3.2367-2.6203-5.8606-5.852-5.8606H70.394c-3.2317 0-5.8515 2.624-5.8515 5.8606v.0013c0 3.2367 2.6198 5.8605 5.8514 5.8605h39.2115c3.2316 0 5.852-2.6238 5.852-5.8605v-.0013z"
                id="Shape"
              />
            </g>
            <g id="Group" stroke="#4A4A4A" strokeWidth="3.49937594" fill="#7C7C7C">
              <path
                d="M111.529 156.3108c0-1.554-.6165-3.0448-1.7142-4.1438-1.0972-1.099-2.5856-1.7167-4.1378-1.7167H74.323c-1.5523 0-3.0407.6177-4.138 1.7167-1.0976 1.099-1.7138 2.5897-1.7138 4.1438v.0013c0 1.5547.6162 3.0454 1.714 4.1444 1.097 1.099 2.5855 1.7163 4.1377 1.7163h31.354c1.5522 0 3.0406-.6173 4.1378-1.7163 1.0977-1.099 1.7143-2.5897 1.7143-4.1443v-.0012z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default Bulb;
