import client from '../client';
import { getResponseValue } from 'util/validate';

export const _batchEditLeads = (schoolId, leads, values, createdBy) => {
  return client.patch(`schools/${schoolId}/leads/batch`, { leads, values, createdBy }, { credentials: 'omit' });
};

export const _getLeads = async (params) => {
  const {
    schoolId,
    status = 'new',
    page = 1,
    pageSize = 10,
    text = '',
    year = '',
    grade = '',
    parent = '',
    phone = '',
    email = '',
    student = '',
    birthdate = '',
    leadSource = '',
    lastUpdate = '',
    notes = '',
    address = '',
    archivedReason = '',
    subStage = '',
    referredBy = '',
    referredByOther = '',
    zipcode = '',
    fieldSort = '',
    fieldDirection = '',
    language = '',
    customField1 = '',
    customField2 = '',
    customField3 = '',
    customField4 = '',
    customField5 = '',
    applicationReceived = '',
    leadTransportationNeed = '',
    yearAccepted = '',
    createdAt_from: createdAtFrom = '',
    createdAt_to: createdAtTo = '',
    birthdate_from: birthdateFrom = '',
    birthdate_to: birthdateTo = '',
    appReceivedAt_from: appReceivedAtFrom,
    appReceivedAt_to: appReceivedAtTo,
    enrollmentConfirmed,
    enrollmentConfirmedAt_from: enrollmentConfirmedAtFrom,
    enrollmentConfirmedAt_to: enrollmentConfirmedAtTo,
    attendanceConfirmed = '',
    sync_intg_status: syncIntgStatus = '',
    sync_intg_with: syncIntgWith = '',
    iep = '',
    sibling_in_school: siblingInSchool = '',
    district = '',
    intg_lead_id: intgLeadId = '',
    intg_campus: intgCampus = '',
    intg_app_id: intgAppId = '',
    sync_intg_date_from: syncIntgDateFrom = '',
    sync_intg_date_to: syncIntgDateTo = '',
    score_weight_grade = '',
  } = params;
  if (params.lead_id) delete params.schoolId;

  return await client.get(
    `schools/${schoolId}/leads`,
    params.lead_id
      ? params
      : {
          status,
          page,
          pageSize,
          text,
          year,
          grade,
          language,
          parent,
          phone,
          email,
          student,
          birthdate,
          leadSource,
          lastUpdate,
          notes,
          address,
          archivedReason,
          subStage,
          referredBy,
          referredByOther,
          zipcode,
          customField1,
          customField2,
          customField3,
          customField4,
          customField5,
          applicationReceived,
          leadTransportationNeed,
          yearAccepted,
          createdAt_to: createdAtTo,
          createdAt_from: createdAtFrom,
          birthdate_from: birthdateFrom,
          birthdate_to: birthdateTo,
          appReceivedAt_from: appReceivedAtFrom,
          appReceivedAt_to: appReceivedAtTo,
          enrollmentConfirmed,
          enrollmentConfirmedAt_from: enrollmentConfirmedAtFrom,
          enrollmentConfirmedAt_to: enrollmentConfirmedAtTo,
          attendanceConfirmed,
          sync_intg_status: syncIntgStatus,
          sync_intg_with: syncIntgWith,
          iep,
          sibling_in_school: siblingInSchool,
          district,
          intg_lead_id: intgLeadId,
          intg_app_id: intgAppId,
          intg_campus: intgCampus,
          sync_intg_date_from: syncIntgDateFrom,
          sync_intg_date_to: syncIntgDateTo,
          score_weight_grade,
          fieldSort,
          fieldDirection,
        },
    {
      credentials: 'omit',
    }
  );
};

export const _getLeadsExport = async (params) => {
  const { schoolId, status, title } = params;
  return await client.get(`schools/${schoolId}/leads/exports/${status}/${title}`, {}, { credentials: 'omit' });
};

export const _getAllLeadsExport = async (params) => {
  const { schoolId, title } = params;
  return await client.get(`schools/${schoolId}/leads/exports/${title}`, {}, { credentials: 'omit' });
};

export const _getLeadsStatusCount = (schoolId) => {
  return client.get(`schools/${schoolId}/leads/counts`, {}, { credentials: 'omit' });
};

export const _getPotentialLeadsCount = (schoolId) => {
  return client.get(`schools/${schoolId}/potential-leads/count`, {}, { credentials: 'omit' });
};

export const _getLeadFields = (schoolId) => {
  return client.get(`schools/${schoolId}/leadfields`, {}, { credentials: 'omit' });
};

export const _getHearAboutUsFields = async (schoolId) => {
  const res = await client.get(`lead/hear-about-us/${schoolId}`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _setLeadFields = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/leadfields`, payload, { credentials: 'omit' });
};

export const _getLeadStatuses = (schoolId) => {
  return client.get(`schools/${schoolId}/leadstatuses`, {}, { credentials: 'omit' });
};

export const _addLeadStatus = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/leadstatus`, payload, { credentials: 'omit' });
};

export const _getLeadSources = (schoolId) => {
  return client.get(`schools/${schoolId}/leadsources`, {}, { credentials: 'omit' });
};

export const _addLeadSource = async (schoolId, payload) => {
  return await client.post(`schools/${schoolId}/leadsource`, payload, { credentials: 'omit' });
};

export const _sendApplications = (schoolId, leads, emailRequired = true, loggedId) => {
  return client.post(
    `schools/${schoolId}/leads/send-apps`,
    { leads, emailRequired, logged_id: loggedId },
    { credentials: 'omit' }
  );
};

export const _addLeadReason = async (payload) => {
  return await client.post(`reason`, payload, { credentials: 'omit' });
};

export const _getLeadReasons = async (schoolId) => {
  return await client.get(`reason/school/${schoolId}/lead`, {}, { credentials: 'omit' });
};

/// Lead Source Cost  ///

export const _getLeadSourceCost = async (payload) => {
  let query = Object.keys(payload)
    .map((k) => {
      let value = payload[k];
      if (value instanceof Date) value = value.toISOString();
      return `${k}=${value}`;
    })
    .join('&');

  return await client.get(`lead/source-costs?${query}`, {}, { credentials: 'omit' });
};

export const _addLeadSourceCost = async (payload) => {
  return await client.post(`lead/source-costs/`, payload, { credentials: 'omit' });
};

export const _updateLeadSourceCost = async (id, payload) => {
  return await client.patch(`lead/source-costs/${id}`, payload, { credentials: 'omit' });
};

export const _deleteLeadSourceCost = async (id) => {
  return await client.delete(`lead/source-costs/${id}`, {}, { credentials: 'omit' });
};

///

export const _getLeadsToNotify = async (schoolId) => {
  return await client.get(`lead/news-notify/${schoolId}`, {}, { credentials: 'omit' });
};

export const _markLeadsToReminder = async (schoolId) => {
  return await client.post(`lead/news-notify/${schoolId}`, {}, { credentials: 'omit' });
};

export const _leadsImport = async (schoolId, mapping, data) => {
  return await client.post(`schools/${schoolId}/lead/custom-import`, { mapping, data }, { credentials: 'omit' });
};

export const _createLead = async (payload) => {
  return await client.post('leads', payload, { credentials: 'omit' });
};

export const _updateLeadBatch = async (schoolId, leads, values) => {
  return await client.patch(`schools/${schoolId}/leads/batch`, { leads, values }, { credentials: 'omit' });
};

export const _getPotentialLeads = async (payload) => {
  const { schoolId, page = 1, pageSize = 10, text = '', fieldSort = '', fieldDirection = '', grade = '' } = payload;
  return client.get(
    `schools/${schoolId}/potential-leads`,
    { page, pageSize, text, grade, fieldSort, fieldDirection },
    { credentials: 'omit' }
  );
};

export const _updateSchoolScheduleTour = (schoolId, tourId, payload) => {
  return client.post(`schools/${schoolId}/scheduletour/${tourId}`, payload, { credentials: 'omit' });
};

export const _getExportTypeApplicationsForSchool = async (schoolId) => {
  return await client.get(`schools/${schoolId}/leads/export/type-applications`, {}, { credentials: 'omit' });
};

export const _alternativeSchools = async (schoolId, leadId) => {
  return await client.post(`schools/${schoolId}/lead/${leadId}/alternative-schools`, {}, { credentials: 'omit' });
};

export const _testActiveCampaign = async (schoolId, leadId) => {
  return await client.post(`schools/${schoolId}/lead/${leadId}/test-active-campaign`, {}, { credentials: 'omit' });
};

export const _getGeneralLeads = async (schoolId) => {
  const res = await client.get(`schools/${schoolId}/lead/general-leads`, {}, { credentials: 'omit' });
  return getResponseValue(res);
};

export const _deleteLeads = async (schoolId, leadIds, deletedBy) => {
  return await client.post(`schools/${schoolId}/delete-leads`, { leadIds, deletedBy }, { credentials: 'omit' });
};

export const _getCountForMarketingCampaigns = async (schoolId, hoursTimezonesOffset = 0) => {
  const res = await client.get(
    `schools/${schoolId}/lead/count-for-marketing-campaigns/${hoursTimezonesOffset}`,
    {},
    { credentials: 'omit' }
  );
  return getResponseValue(res);
};

export const _convertPotentialLeadToLead = (schoolId, payload) => {
  return client.post(`schools/${schoolId}/lead/convert-potential-lead-to-lead`, payload, { credentials: 'omit' });
};

export const _getLeadTransportation = async (schoolId, leadId) => {
  const res = await client.get(`schools/${schoolId}/lead/${leadId}/transportation`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getLeadYearOptions = async (schoolId) => {
  const res = await client.get(`schools/${schoolId}/lead/year-options`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getIntegrationStatus = async (schoolId) => {
  const res = await client.get(`schools/${schoolId}/lead/integration-status`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getIntegrationCampus = async (schoolId) => {
  const res = await client.get(`schools/${schoolId}/lead/integration-campus`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getExecutionsByLeadId = async (schoolId, leadId) => {
  const res = await client.get(`school/${schoolId}/marketing/campaign/lead/${leadId}`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getLeadsRelated = async (schoolId, leadId) => {
  const res = await client.get(`schools/${schoolId}/lead/${leadId}/related`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};
