import ReactPixel from 'react-facebook-pixel';

const fbPixelAdvancedMatching = {};
const fbPixelOptions = { autoConfig: true, debug: false };
if (process.env.REACT_APP_ENV === 'production') {
  ReactPixel.init('1531704463704789', fbPixelAdvancedMatching, fbPixelOptions);
} else {
  ReactPixel.init('277097404036186', fbPixelAdvancedMatching, fbPixelOptions);
}
ReactPixel.pageView();
