import merge from 'lodash/merge';
import validationMessageMap from 'redux/util/validation-message-map';

const MAX_LEN = 64;

const getQaAdminUrl = () => {
  const { REACT_APP_CS_PROJECT, REACT_APP_CS_REPO, REACT_APP_CS_BRANCH } = process.env;

  const branchName = REACT_APP_CS_BRANCH ? REACT_APP_CS_BRANCH.substr(0, 15) : '';

  const rawSubDomain = `https://${REACT_APP_CS_PROJECT}-${REACT_APP_CS_REPO}-searchproxy-${branchName}`;
  const subDomain = rawSubDomain.toLowerCase().substr(0, MAX_LEN);
  return `${subDomain}.synsit.es`;
};

// old api key AIzaSyAZjX2US7Db9MU9cmlMcet3uqeqMHzr7MA
const defaults = {
  validationMessageMap,
  google: {
    places: {
      apiKey: 'AIzaSyBCsBXUiJZlhOsCLYE1c7Av46JYKrNDmLk',
    },
    analytics: {},
    gtag: {
      id: 'AW-811705801',
      tags: {
        compare_schools_parent_portal: '',
        create_campaign_apply: '',
        create_campaign_birthday_nuturing: '',
        create_campaign_enroll: '',
        create_campaign_re_enroll: '',
        create_custom_campaign: '',
        fill_claim_your_school_school_portal: '',
        open_apply_now_parent_portal: '',
        open_parent_portal_dashboard: '',
        open_request_demo_school_portal: '',
        open_request_info_parent_portal: '',
        open_schola_match_parent_portal: '',
        question_1_schola_match_parent_portal: '',
        question_2_schola_match_parent_portal: '',
        question_3_schola_match_parent_portal: '',
        question_4_schola_match_parent_portal: '',
        question_5_schola_match_parent_portal: '',
        question_6_schola_match_parent_portal: '',
        question_7_schola_match_parent_portal: '',
        question_8_schola_match_parent_portal: '',
        submit_claim_your_school_school_portal: '',
        submit_question_9_schola_match_parent_portal: '',
        submit_request_demo_school_portal: '',
        submit_request_info_parent_portal: '',
        submit_schedule_tour_parent_portal: '',
        view_profile_parent_portal_dashboard: '',
      },
    },
  },
  intercom: {
    APP_ID: '',
  },
  auth0: {
    domain: 'schola-staging.auth0.com',
    clientId: 'waP9dK6zDt3aUND4ietlxFL7I70EIC6Y',
    lockOptions: {
      allowSignUp: false,
      configurationBaseUrl: 'https://cdn.auth0.com',
      auth: {
        params: {
          scope: 'openid email name picture user_metadata app_metadata',
        },
        redirect: true,
        redirectUrl: `${window.location.protocol}//${window.location.host}/login`,
        responseType: 'token',
      },
      container: 'auth0-lock',
      languageDictionary: {
        title: 'Schola® Admin',
      },
      theme: {
        logo: `${window.location.protocol}//${window.location.host}/logo.svg`,
        primaryColor: '#8ac341',
      },
      allowedConnections: ['Username-Password-Authentication'],
    },
    rememberLastLogin: false,
  },
  stripe: {
    key: 'pk_test_AXYQo5L7yenTsGokgeNfLMOg',
    planId_connect_m: 'monthlyconnect2',
    planId_recruiter_m: 'monthlyrecruiter2',
    planId_pro_m: 'monthlypro',
    planId_connect_y: 'yearlyconnect2',
    planId_recruiter_y: 'yearlyrecruiter2',
    planId_pro_y: 'yearlypro',
  },
};

const environments = {
  ci: {},
  devlocal: {
    searchHost: 'http://localhost:3000',
    google: {
      places: {
        apiKey: 'AIzaSyBCsBXUiJZlhOsCLYE1c7Av46JYKrNDmLk',
      },
      recaptcha: {
        siteKey: '6LeMo6coAAAAAIW5Ru6mHKsVcJ2IIxm2oFPOC-I5',
      },
    },
  },
  DIT: {
    searchHost: 'http://search-dit-schola.sooryen.com:3000',
  },
  qa: {
    searchHost: getQaAdminUrl(),
  },
  development: {
    searchHost: 'https://dev.schola.com',
    google: {
      places: {
        apiKey: 'AIzaSyBCsBXUiJZlhOsCLYE1c7Av46JYKrNDmLk',
      },
      recaptcha: {
        siteKey: '6LeMo6coAAAAAIW5Ru6mHKsVcJ2IIxm2oFPOC-I5',
      },
    },
  },
  staging: {
    searchHost: 'https://staging.schola.com',
    google: {
      places: {
        apiKey: 'AIzaSyBCsBXUiJZlhOsCLYE1c7Av46JYKrNDmLk',
      },
      recaptcha: {
        siteKey: '6LeMo6coAAAAAIW5Ru6mHKsVcJ2IIxm2oFPOC-I5',
      },
    },
  },
  production: {
    searchHost: 'https://schola.com',
    auth0: {
      domain: 'login.schola.com',
      clientId: 'kwk1RzabXqEthR8zN1SHa6nrdTYyrm5W',
    },
    google: {
      places: {
        apiKey: 'AIzaSyA9r4PPM5zOT2d0JUcKL4YPoUr6kqpunnI',
      },
      recaptcha: {
        siteKey: '6LeMo6coAAAAAIW5Ru6mHKsVcJ2IIxm2oFPOC-I5',
      },
      analytics: {
        key: 'UA-90035592-1',
      },
      gtag: {
        id: 'G-ERJWFTG0LQ',
        tags: {
          compare_schools_parent_portal: 'AW-811705801/vMD-CI_wuvsBEMnLhoMD',
          create_campaign_apply: 'AW-811705801/_zH8CJzgi_wBEMnLhoMD',
          create_campaign_birthday_nuturing: 'AW-811705801/7TKtCLPS6_sBEMnLhoMD',
          create_campaign_enroll: 'AW-811705801/LBhxCOq16_sBEMnLhoMD',
          create_campaign_re_enroll: 'AW-811705801/eEvVCKXjgPwBEMnLhoMD',
          create_custom_campaign: 'AW-811705801/A-YaCNvsi_wBEMnLhoMD',
          fill_claim_your_school_school_portal: 'AW-811705801/R7FoCMOapvsBEMnLhoMD',
          open_apply_now_parent_portal: 'AW-811705801/cF4qCMDouvsBEMnLhoMD',
          open_parent_portal_dashboard: 'AW-811705801/XtVdCJbkpfsBEMnLhoMD',
          open_request_demo_school_portal: 'AW-811705801/etdhCOmNpvsBEMnLhoMD',
          open_request_info_parent_portal: 'AW-811705801/j3KqCIjKpfsBEMnLhoMD',
          open_schola_match_parent_portal: 'AW-811705801/Y0KdCK3x4PcBEMnLhoMD',
          question_1_schola_match_parent_portal: 'AW-811705801/gwM8CPfHpfsBEMnLhoMD',
          question_2_schola_match_parent_portal: 'AW-811705801/ciXeCP_9pvsBEMnLhoMD',
          question_3_schola_match_parent_portal: 'AW-811705801/DkKSCNX9pvsBEMnLhoMD',
          question_4_schola_match_parent_portal: 'AW-811705801/oMhnCI2Ap_sBEMnLhoMD',
          question_5_schola_match_parent_portal: 'AW-811705801/uVMBCO6vx_sBEMnLhoMD',
          question_6_schola_match_parent_portal: 'AW-811705801/ghejCIKHp_sBEMnLhoMD',
          question_7_schola_match_parent_portal: 'AW-811705801/QByYCJuMvPsBEMnLhoMD',
          question_8_schola_match_parent_portal: 'AW-811705801/D582CLWmx_sBEMnLhoMD',
          submit_claim_your_school_school_portal: 'AW-811705801/wNxACKKgpvsBEMnLhoMD',
          submit_question_9_schola_match_parent_portal: 'AW-811705801/XvckCJ37xfsBEMnLhoMD',
          submit_request_demo_school_portal: 'AW-811705801/aFFICNKzxvsBEMnLhoMD',
          submit_request_info_parent_portal: 'AW-811705801/IKv3CNXZuvsBEMnLhoMD',
          submit_schedule_tour_parent_portal: 'AW-811705801/IxCOCO-8pfsBEMnLhoMD',
          view_profile_parent_portal_dashboard: 'AW-811705801/JuJYCJHipfsBEMnLhoMD',
        },
      },
    },
    intercom: {
      APP_ID: 'd2wswl65',
    },
    stripe: {
      key: 'pk_live_BadfXuIvegLjssNgvvKqu7VA',
      planId_connect_m: 'monthlyconnect2',
      planId_recruiter_m: 'monthlyrecruiter2',
      planId_pro_m: 'monthlypro',
      planId_connect_y: 'yearlyconnect2',
      planId_recruiter_y: 'yearlyrecruiter2',
      planId_pro_y: 'yearlypro',
    },
  },
};

export default merge(defaults, environments[process.env.REACT_APP_ENV]);
