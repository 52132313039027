import Auth0Lock from 'auth0-lock';
import { store } from '../store.js';
import config from 'config.js';
import { _addLog } from 'controllers/logauths/logauths_controller';
import { _getSchools } from 'controllers/schools/schools_controller';
import { isSuperadmin } from 'util/auth';
import localstorage from 'store2';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SESSION_REFRESH = 'SESSION_REFRESH';

export const LOGOUT = 'LOGOUT';

const lock = new Auth0Lock(config.auth0.clientId, config.auth0.domain, config.auth0.lockOptions);

export function login() {
  lock.show({
    rememberLastLogin: config.auth0.rememberLastLogin,
  });
  // checkSession each 10min
  setTimeout(() => checkSession(), 10 * 60000);
  return {
    type: LOGIN,
  };
}

export function loginSuccess(result) {
  const { idToken, idTokenPayload, accessToken } = result;
  lock.hide();

  if (!isSuperadmin(idToken)) {
    try {
      localstorage.set('idToken', idToken);
      const searchOptions = { name: '%', page: 1, pageSize: 1 };
      _getSchools(searchOptions)
        .then((result) => result.json())
        .then((schools) => {
          if (schools.results.length === 0) {
            // parent user (no schools)... logout
            localstorage.set('idToken', null);
            return {
              type: LOGOUT,
            };
          }
        });
    } catch (error) {
      console.log(error);
      return {
        type: LOGOUT,
      };
    }
  }
  // save login
  if (window.freshpaint) {
    window.freshpaint.identify(idTokenPayload.user_id, {
      email: idTokenPayload.email,
    });
    window.freshpaint.track(
      'login-successful',
      { email: idTokenPayload.email, user_id: idTokenPayload.user_id },
      { integrations: { All: true } }
    );
  }

  let payload = {
    user_id: idTokenPayload.user_id,
    email: idTokenPayload.email,
    geolocation: '',
    userAgent: navigator.userAgent,
    language: navigator.language,
    appsite: 'schools_admin',
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // success function
        payload.geolocation = `lat:${position.coords.latitude},lon:${position.coords.longitude}`;
        _addLog(payload);
      },
      () => {
        // error function
        _addLog(payload);
      },
      { enableHighAccuracy: true } // parameters passed
    );
  } else {
    _addLog(payload);
  }

  return {
    type: LOGIN_SUCCESS,
    idToken,
    accessToken,
  };
}

export function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

export function logout() {
  if (window.freshpaint) {
    window.freshpaint.reset();
  }

  return {
    type: LOGOUT,
  };
}

// pipe auth0 events to redux actions
lock
  .on('authenticated', (result) => {
    store.dispatch(loginSuccess(result));
  })
  .on('authorization_error', (error) => {
    store.dispatch(loginFailure(error));
  })
  .on('unrecoverable_error', (error) => {
    store.dispatch(loginFailure(error));
  });

export function checkSession() {
  // checkSession each 10min
  setTimeout(() => checkSession(), 10 * 60000);
  return lock.checkSession({}, (err, authRes) => {
    if (err || !authRes) {
      lock.show();
    } else {
      const { idToken } = authRes;
      store.dispatch({
        type: SESSION_REFRESH,
        idToken,
      });
    }
  });
}
