import React from 'react';
import { InputText, Dropdown } from 'schola-components';
import { roles } from '../../utils';
import { t } from 'components/translation/Translation';
import styles from '../waitlist-form.module.scss';

export const ContactInformation = ({ contactInputs, handleChange }) => {
  return (
    <div>
      <h5 className="spacing-my-24">{t('home:waitlist.contactInfo')}</h5>

      {contactInputs.map((input, idx) => {
        if (input.component === 'text') {
          return (
            <InputText
              key={input.id}
              type={input?.type}
              className={`spacing-mb-32 ${styles.labelAdapter}`}
              label={input.label}
              id={input.id}
              name={input.name}
              value={input.value}
              onChange={handleChange}
              error={input?.error}
              errorMessage={input?.error}
            />
          );
        }
        return (
          <div className="spacing-mb-32" key={input.id}>
            <Dropdown
              label={input.label}
              id={input.id}
              name={input.name}
              value={input.value}
              onChange={handleChange}
              options={roles}
            />
            {input?.error && <div className={styles.errorLabel}>{input.error}</div>}
          </div>
        );
      })}
    </div>
  );
};
