import React, { useEffect } from 'react';
import { EyeOutlined, StopOutlined, WarningOutlined, ThunderboltOutlined } from '@ant-design/icons';
import {
  CampaignsTable,
  CampaignFilters,
  StatCard,
  CalendarRange,
  Button,
  CampaignCreationSettings,
} from '../../components';

import { Card } from 'components/card/card';
import styles from './advanced-campaigns.module.scss';
import { Row, Col } from 'antd';
import dayjs from 'dayjs';
import { _getMarketingCampaigns } from 'controllers/schools/schools_controller';

import { useToggle } from 'hooks/useToggle';

export const AdvancedCampaigns = ({ params: { id } }) => {
  const [statsDates, setStatsDates] = React.useState({
    startDate: dayjs().subtract(7, 'day'),
    endDate: dayjs(),
  });
  const [openNewCampaign, toggleNewCampaign] = useToggle(false);
  const [campaigns, setCampaigns] = React.useState([]);
  const [totalCampaigns, setTotalCampaigns] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });
  const [sort, setSort] = React.useState({
    field: 'updated_at',
    direction: 'DESC',
  });
  const [typeFilter, setTypeFilter] = React.useState('all');
  const [statusFilter, setStatusFilter] = React.useState('all');

  // get the info for the tab
  const onLoadData = async () => {
    if (id) {
      const filters = [];
      if (typeFilter !== 'all') {
        filters.push({
          name: 'platform',
          value: typeFilter,
        });
      }
      if (statusFilter !== 'all') {
        filters.push({
          name: 'status',
          value: statusFilter,
        });
      }
      let _payload = {
        school_id: id,
        page: pagination.page || 1,
        pageSize: pagination.pageSize || 0,
        platform: 'all',
        sortField: sort.field || 'updated_at',
        sortDirection: sort.direction || 'DESC',
        filters: filters || [],
      };

      let data = await _getMarketingCampaigns(_payload);
      console.log(data);
      setTotalCampaigns(data.pagination.rowCount);
      const _campaigns = data.results.map((campaign) => {
        let mode = campaign.type === 'advanced' ? 'Advanced' : 'Simple';

        return {
          id: campaign.id,
          name: campaign.name,
          platform: campaign.platform,
          type: campaign.type,
          mode,
          audience: campaign.audience_language,
          executions: campaign.count_executions,
          sentTo:
            campaign.platform === 'email'
              ? campaign.count_email
              : campaign.platform === 'sms'
              ? campaign.count_sms
              : '--',
          lastEdited: campaign.updated_at,
          lastSent: campaign.last_execution,
          status: campaign.status,
          row: campaign,
        };
      });
      setCampaigns(_campaigns);
    }
  };

  useEffect(() => {
    onLoadData();
  }, [typeFilter, statusFilter, pagination, sort]);

  useEffect(() => {
    onLoadData();
  }, []);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <h4 style={{ marginBottom: 0 }} className={`heading-4 ${styles.heading}`}>
            Campaigns Performance
          </h4>
        </Col>
        <Col>
          <CalendarRange
            defaultValue={[statsDates.startDate, statsDates.endDate]}
            format={'MMM D, YYYY'}
            onChange={() => {}}
          />
        </Col>
      </Row>
      <Row className="spacing-mb-16" gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <StatCard
            title="Emails Opened"
            value={43}
            total={65}
            percentage={Math.round((43 / 65) * 100 * 10) / 10}
            icon={EyeOutlined}
            trend="+15.3% this month"
            color="primary"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StatCard
            title="Click Rate"
            value={3}
            total={56}
            percentage={Math.round((3 / 56) * 100 * 10) / 10}
            icon={ThunderboltOutlined}
            trend="+5.7% this month"
            color="success"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StatCard
            title="Bounce Rate"
            value={12}
            total={42}
            percentage={Math.round((12 / 42) * 100 * 10) / 10}
            icon={StopOutlined}
            trend="-1.2% this month"
            color="warning"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StatCard
            title="Spam Reports"
            value={32}
            total={45}
            percentage={Math.round((32 / 45) * 100 * 10) / 10}
            icon={WarningOutlined}
            trend="-0.8% this month"
            color="destructive"
          />
        </Col>
      </Row>
      <Row className="spacing-mb-16">
        <Col span={24}>
          <CampaignFilters
            typeFilter={typeFilter}
            statusFilter={statusFilter}
            setStatusFilter={(value) => {
              setStatusFilter(value, onLoadData());
            }}
            setTypeFilter={(value) => {
              setTypeFilter(value, onLoadData());
            }}>
            <Button onClick={toggleNewCampaign}>Create Campaign</Button>
          </CampaignFilters>
        </Col>
      </Row>
      <Row className="spacing-mb-16">
        <Col span={24}>
          <Card className={styles.cardTableCampains}>
            <CampaignsTable
              totalCampaigns={totalCampaigns}
              campaigns={campaigns}
              onChange={onLoadData}
              setPagination={setPagination}
              setSort={setSort}
              school_id={id}
            />
          </Card>
        </Col>
      </Row>

      {/* Modal Layers Components */}
      <CampaignCreationSettings isVisible={openNewCampaign} toggleVisible={toggleNewCampaign} />
    </>
  );
};
