import client from '../client';

export const CREATE_SCHOOL = 'CREATE_SCHOOL';
export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const GET_APPLICATION_FIELDS = 'GET_APPLICATION_FIELDS';
export const SET_APPLICATION_FIELDS = 'SET_APPLICATION_FIELDS';
export const ADD_APPLICATION_FIELDS = 'ADD_APPLICATION_FIELDS';
export const REMOVE_APPLICATION_FIELDS = 'REMOVE_APPLICATION_FIELDS';
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_SCHOOLS = 'GET_SCHOOLS';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const UPDATE_SCHOOL_FEATURES = 'UPDATE_SCHOOL_FEATURES';
export const UPLOAD_SCHOOL_LOGO = 'UPLOAD_SCHOOL_LOGO';
export const UPLOAD_SCHOOL_LOGO_UPLOAD = 'UPLOAD_SCHOOL_LOGO_UPLOAD';
export const UPLOAD_SCHOOL_IMAGE = 'UPLOAD_SCHOOL_IMAGE';
export const UPLOAD_SCHOOL_IMAGE_UPLOAD = 'UPLOAD_SCHOOL_IMAGE_UPLOAD';
export const DELETE_SCHOOL_IMAGE = 'DELETE_SCHOOL_IMAGE';
export const GET_SCHOOL_USERS = 'GET_SCHOOL_USERS';
export const DELETE_SCHOOL_USER = 'DELETE_SCHOOL_USER';
export const CREATE_SCHOOL_USER = 'CREATE_SCHOOL_USER';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const GET_INVOICES = 'GET_INVOICES';
export const IMPORT_SCHOOLS = 'IMPORT_SCHOOLS';
export const CLAIM_SCHOOL = 'CLAIM_SCHOOL';
export const GET_AUTOCOMPLETE_SCHOOLS = 'GET_AUTOCOMPLETE_SCHOOLS';
export const GET_AUTOCOMPLETE_SCHOOLS_V2 = 'GET_AUTOCOMPLETE_SCHOOLS_V2';
export const GET_AUTOCOMPLETE_SCHOOLS_RESET = 'GET_AUTOCOMPLETE_SCHOOLS_RESET';
export const GET_SCHOOL_APPLICATION = 'GET_SCHOOL_APPLICATION';
export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const GET_SCHOOL_CARDS = 'GET_SCHOOL_CARDS';
export const GET_SCHOOL_CARDS_RESET = 'GET_SCHOOL_CARDS_RESET';

export const GET_SCHOOL_SCHEDULE_TOUR = 'GET_SCHOOL_SCHEDULE_TOUR';
export const GET_SCHOOL_SCHEDULE_TOUR_ID = 'GET_SCHOOL_SCHEDULE_TOUR_ID';
export const GET_SCHOOL_TESTIMONIAL = 'GET_SCHOOL_TESTIMONIAL';
export const GET_SCHOOL_REVIEWS = 'GET_SCHOOL_REVIEWS';
export const GET_SCHOOL_SEATS = 'GET_SCHOOL_SEATS';
export const GET_SCHOOL_TOUR_TIMES = 'GET_SCHOOL_TOUR_TIMES';
export const GET_SCHOOL_SITES_REVIEWS = 'GET_SCHOOL_SITES_REVIEWS';
export const GET_SCHOOL_ACADEMICS = 'GET_SCHOOL_ACADEMICS';
export const GET_SCHOOL_VIEWS = 'GET_SCHOOL_VIEWS';

export const ADD_SCHOOL_SCHEDULE_TOUR = 'ADD_SCHOOL_SCHEDULE_TOUR';
export const ADD_SCHOOL_TESTIMONIAL = 'ADD_SCHOOL_TESTIMONIAL';
export const ADD_SCHOOL_SEATS = 'ADD_SCHOOL_SEATS';
export const UPDATE_SCHOOL_SEATS = 'UPDATE_SCHOOL_SEATS';
export const UPDATE_SCHOOL_SCHEDULE_TOUR = 'UPDATE_SCHOOL_SCHEDULE_TOUR';
export const UPDATE_BATCH_SCHOOL_SCHEDULE_TOUR = 'UPDATE_BATCH_SCHOOL_SCHEDULE_TOUR';
export const ADD_SCHOOL_TOUR_TIMES = 'ADD_SCHOOL_TOUR_TIMES';
export const UPDATE_SCHOOL_TOUR_TIMES = 'UPDATE_SCHOOL_TOUR_TIMES';
export const ADD_SCHOOL_SITES_REVIEWS = 'ADD_SCHOOL_SITES_REVIEWS';
export const UPDATE_SCHOOL_PLANID = 'UPDATE_SCHOOL_PLANID';
export const UPDATE_SCHOOL_TESTIMONIAL = 'UPDATE_SCHOOL_TESTIMONIAL';

export const ADD_SCHOOL_ACADEMICS = 'ADD_SCHOOL_ACADEMICS';
export const GET_SCHOOL_CONNECT_COUNT = 'GET_SCHOOL_CONNECT_COUNT';
export const REMOVE_SCHOOL_TESTIMONIAL = 'REMOVE_SCHOOL_TESTIMONIAL';
export const UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD = 'UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD';
export const UPDATE_SCHOOL_MARKETING = 'UPDATE_SCHOOL_MARKETING';
export const CUSTOM_UPGRADE_SCHOOL_PLAN = 'CUSTOM_UPGRADE_SCHOOL_PLAN';

export const UPDATE_SCHOOL_EMAIL_MARKETING = 'UPDATE_SCHOOL_EMAIL_MARKETING';

export const ADD_FEATURE_SUGGESTION = 'ADD_FEATURE_SUGGESTION';

export const GET_SCHOOL_ENROLLEMENT = 'GET_SCHOOL_ENROLLEMENT';
export const GET_SCHOOL_AWERENESS = 'GET_SCHOOL_AWERENESS';
export const GET_SCHOOL_ENGAGEMENT = 'GET_SCHOOL_ENGAGEMENT';
export const UPDATE_SCHOOL_ENROLLMENT_GOAL = 'UPDATE_SCHOOL_ENROLLMENT_GOAL';
export const GET_SCHOOL_LEADERS = 'GET_SCHOOL_LEADERS';
export const SEND_SCHOLA_BOOST_NOTIFICATION = 'SEND_SCHOLA_BOOST_NOTIFICATION';
export const UPLOAD_SCHOOL_CUSTOM_APPLICATION = 'UPLOAD_SCHOOL_CUSTOM_APPLICATION';
export const BUY_SCHOLA_BOOST = 'BUY_SCHOLA_BOOST';
export const GET_SCHOOL_TEACHER_STUFF = 'GET_SCHOOL_TEACHER_STUFF';

export const SEND_CLAIM_VERIFICATION_CODE = 'SEND_CLAIM_VERIFICATION_CODE';

export const GET_S3BUCKET = 'GET_S3BUCKET';

export function resetSearchSchools() {
  return { type: 'SEARCH_SCHOOLS_RESET' };
}

export function createSchool(data) {
  return {
    type: CREATE_SCHOOL,
    promise: client.post('schools', data, { credentials: 'omit' }),
  };
}

export function getSchool(id) {
  return {
    type: GET_SCHOOL,
    promise: client.get(`schools/${id}`, {}, { credentials: 'omit' }),
  };
}

export function resetGetSchool() {
  return { type: 'GET_SCHOOL_RESET' };
}

export function getSchools(query) {
  return {
    type: GET_SCHOOLS,
    promise: client.get('schools', query, { credentials: 'omit' }),
  };
}

export function deleteSchool(id) {
  return {
    type: DELETE_SCHOOL,
    promise: client.delete(`schools/${id}`, {}, { credentials: 'omit' }),
  };
}

export function updateSchool(id, data) {
  return {
    type: UPDATE_SCHOOL,
    promise: client.patch(`schools/${id}`, data, { credentials: 'omit' }),
  };
}

export function updateSchoolSub(id, data) {
  return {
    type: UPDATE_SCHOOL,
    promise: client.patch(`schools/sub/${id}`, data, { credentials: 'omit' }),
  };
}

export function claimSchool(data) {
  return {
    type: CLAIM_SCHOOL,
    promise: client.post(`schools/claim`, data, { credentials: 'omit' }),
  };
}

export function uploadSchoolLogo(id, data) {
  const formData = new FormData();
  formData.append('file', data);

  return {
    type: UPLOAD_SCHOOL_LOGO,
    promise: client.put(`schools/${id}/logo`, formData, { credentials: 'omit' }),
  };
}

export function uploadSchoolLogoV2(id, data) {
  const formData = new FormData();
  formData.append('file', data);
  formData.append('fileName', data.name);
  formData.append('original', data.original);

  return {
    type: UPLOAD_SCHOOL_LOGO_UPLOAD,
    promise: client.put(`schools/${id}/logo/upload`, formData, { credentials: 'omit' }),
  };
}

export function updateSchoolFeatures(id, data) {
  return {
    type: UPDATE_SCHOOL_FEATURES,
    promise: client.put(`schools/${id}/features`, data, { credentials: 'omit' }),
  };
}

export function uploadSchoolImage(id, data, profileImage) {
  const formData = new FormData();
  formData.append('file', data);
  formData.append('profile_image', profileImage);
  return {
    type: UPLOAD_SCHOOL_IMAGE,
    promise: client.post(`schools/${id}/images`, formData, { credentials: 'omit' }),
  };
}

export function uploadSchoolImageV2(id, data, profileImage) {
  let original = data.original;
  delete data.original;
  const formData = new FormData();
  formData.append('file', data);
  formData.append('profile_image', profileImage);
  formData.append('fileName', data.name);
  formData.append('original', original);
  return {
    type: UPLOAD_SCHOOL_IMAGE_UPLOAD,
    promise: client.post(`schools/${id}/images/upload`, formData, { credentials: 'omit' }),
  };
}

export function updateSchoolCustomApplication(id, data) {
  const formData = new FormData();
  formData.append('file', data);
  return {
    type: UPLOAD_SCHOOL_CUSTOM_APPLICATION,
    promise: client.post(`schools/${id}/custom/application`, formData, { credentials: 'omit' }),
  };
}

export function deleteSchoolImage(schoolId, imageId) {
  return {
    imageId,
    type: DELETE_SCHOOL_IMAGE,
    promise: client.delete(`schools/${schoolId}/images/${imageId}`, {}, { credentials: 'omit' }),
  };
}

export function getSchoolUsers(id) {
  return {
    type: GET_SCHOOL_USERS,
    promise: client.get(`schools/${id}/users`, {}, { credentials: 'omit' }),
  };
}

export function createSchoolUser(id, email) {
  return {
    type: CREATE_SCHOOL_USER,
    email,
    promise: client.post(`schools/${id}/users`, { email }, { credentials: 'omit' }),
  };
}

export function deleteSchoolUser(schoolId, userId) {
  return {
    userId,
    type: DELETE_SCHOOL_USER,
    promise: client.delete(`schools/${schoolId}/users/${userId}`, {}, { credentials: 'omit' }),
  };
}

export function getApplicationFields(schoolId) {
  return {
    type: GET_APPLICATION_FIELDS,
    promise: client.get(`schools/${schoolId}/application/fields`, {}, { credentials: 'omit' }),
  };
}

export function setApplicationFields(schoolId, payload) {
  return {
    type: SET_APPLICATION_FIELDS,
    promise: client.put(`schools/${schoolId}/application/fields`, payload, { credentials: 'omit' }),
  };
}

export function addApplicationFields(schoolId, payload) {
  return {
    type: ADD_APPLICATION_FIELDS,
    promise: client.post(`schools/${schoolId}/application/additional-fields`, payload, { credentials: 'omit' }),
  };
}

export function removeApplicationFields(schoolId, payload) {
  return {
    type: REMOVE_APPLICATION_FIELDS,
    promise: client.delete(`schools/${schoolId}/application/additional-fields/${payload.fieldId}`, null, {
      credentials: 'omit',
    }),
  };
}

export function getSubscription(schoolId) {
  return {
    type: GET_SUBSCRIPTION,
    promise: client.get(`schools/${schoolId}/subscription`, {}, { credentials: 'omit' }),
  };
}

export function createSubscription(schoolId, token, planId, email = null, amount = 0) {
  return {
    type: CREATE_SUBSCRIPTION,
    promise: client.post(
      `schools/${schoolId}/subscription`,
      token && planId ? { token, planId, email, amount } : { email },
      { credentials: 'omit' }
    ),
  };
}

export function deleteSubscription(schoolId) {
  return {
    type: DELETE_SUBSCRIPTION,
    promise: client.delete(`schools/${schoolId}/subscription`, {}, { credentials: 'omit' }),
  };
}

export function updateSubscription(schoolId, token, planId) {
  return {
    type: UPDATE_SUBSCRIPTION,
    promise: client.patch(`schools/${schoolId}/subscription`, { token, planId }, { credentials: 'omit' }),
  };
}

export function getInvoices(schoolId) {
  return {
    type: GET_INVOICES,
    promise: client.get(`schools/${schoolId}/invoices`, {}, { credentials: 'omit' }),
  };
}

export function importSchools(file) {
  const formData = new FormData();
  formData.append('file', file);
  return {
    type: IMPORT_SCHOOLS,
    promise: client.post('schools/import', formData, { credentials: 'omit' }),
  };
}

export function getAutocompleteSchools(query) {
  return {
    type: GET_AUTOCOMPLETE_SCHOOLS,
    promise: client.get('schools/autocomplete', query, { credentials: 'omit' }),
  };
}

export function getAutocompleteSchoolsV2(query) {
  return {
    type: GET_AUTOCOMPLETE_SCHOOLS_V2,
    promise: client.get('schools/autocomplete-v2', query, { credentials: 'omit' }),
  };
}

export function resetAutocompleteSchools() {
  return { type: GET_AUTOCOMPLETE_SCHOOLS_RESET };
}

export function submitApplicationBySchoolId(schoolId, payload) {
  return {
    type: SUBMIT_APPLICATION,
    promise: client.post(`schools/${schoolId}/applications`, payload, { credentials: 'omit' }),
  };
}

export function submitApplicationByToken(token, payload) {
  return {
    type: SUBMIT_APPLICATION,
    promise: client.post(`applications/${token}`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolApplicationByToken(token) {
  return {
    type: GET_SCHOOL_APPLICATION,
    promise: client.get(`applications/${token}`, {}, { credentials: 'omit' }),
  };
}

export function getSchoolApplicationBySchoolId(schoolId) {
  return {
    type: GET_SCHOOL_APPLICATION,
    promise: client.get(`schools/${schoolId}/application`, {}, { credentials: 'omit' }),
  };
}

export function updateSchoolProfileImage(schoolId, imageId) {
  return {
    type: UPDATE_PROFILE_IMAGE,
    promise: client.patch(`schools/${schoolId}/images/profile`, { image_id: imageId }, { credentials: 'omit' }),
  };
}

export function getSchoolCardsBySchoolId(schoolId) {
  return {
    type: GET_SCHOOL_CARDS,
    promise: client.get(`schools/${schoolId}/cards`, {}, { credentials: 'omit' }),
  };
}

export function resetSchoolCardsBySchoolId() {
  return { type: GET_SCHOOL_CARDS_RESET };
}

export function addSchoolScheduleTour(schoolId, payload) {
  return {
    type: ADD_SCHOOL_SCHEDULE_TOUR,
    promise: client.post(`schools/${schoolId}/scheduletour`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolScheduleTour(schoolId, page = 1, pageSize = 10, includeArchived = 0) {
  return {
    type: GET_SCHOOL_SCHEDULE_TOUR,
    promise: client.get(
      `schools/${schoolId}/scheduletour`,
      { page, pageSize, includeArchived },
      { credentials: 'omit' }
    ),
  };
}

export function getSchoolScheduleTourById(schoolId, tourId) {
  return {
    type: GET_SCHOOL_SCHEDULE_TOUR_ID,
    promise: client.get(`schools/${schoolId}/scheduletour/${tourId}`, {}, { credentials: 'omit' }),
  };
}

export function updateSchoolScheduleTour(schoolId, tourId, payload) {
  return {
    type: UPDATE_SCHOOL_SCHEDULE_TOUR,
    promise: client.post(`schools/${schoolId}/scheduletour/${tourId}`, payload, { credentials: 'omit' }),
  };
}

export function multiUpdateSchoolScheduleTour(schoolId, payload) {
  return {
    type: UPDATE_BATCH_SCHOOL_SCHEDULE_TOUR,
    promise: client.post(`schools/${schoolId}/scheduletour/u`, payload, { credentials: 'omit' }),
  };
}

export function addSchoolTestimonial(schoolId, payload) {
  return {
    type: ADD_SCHOOL_TESTIMONIAL,
    promise: client.post(`schools/${schoolId}/testimonial`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolTestimonials(schoolId) {
  return {
    type: GET_SCHOOL_TESTIMONIAL,
    promise: client.get(`schools/${schoolId}/testimonial`, {}, { credentials: 'omit' }),
  };
}

export function removeSchoolTestimonial(schoolId, testimonialId) {
  return {
    type: REMOVE_SCHOOL_TESTIMONIAL,
    promise: client.post(`schools/${schoolId}/testimonial/${testimonialId}/d`, {}, { credentials: 'omit' }),
  };
}

export function updateSchoolTestimonial(schoolId, testimonialId, payload) {
  return {
    type: UPDATE_SCHOOL_TESTIMONIAL,
    promise: client.post(`schools/${schoolId}/testimonial/${testimonialId}`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolReviews(schoolId, query) {
  return {
    type: GET_SCHOOL_REVIEWS,
    promise: client.get(`schools/${schoolId}/user-reviews`, query, { credentials: 'omit' }),
  };
}

export function addSchoolSeats(schoolId, payload) {
  return {
    type: ADD_SCHOOL_SEATS,
    promise: client.post(`schools/${schoolId}/seats`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolSeats(schoolId) {
  return {
    type: GET_SCHOOL_SEATS,
    promise: client.get(`schools/${schoolId}/seats`, {}, { credentials: 'omit' }),
  };
}

export function updateSchoolSeats(schoolId, payload) {
  return {
    type: UPDATE_SCHOOL_SEATS,
    promise: client.post(`schools/${schoolId}/seats/update`, payload, { credentials: 'omit' }),
  };
}

export function addSchoolTourTimes(schoolId, payload) {
  return {
    type: ADD_SCHOOL_TOUR_TIMES,
    promise: client.post(`schools/${schoolId}/tour-times`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolTourTimes(schoolId) {
  return {
    type: GET_SCHOOL_TOUR_TIMES,
    promise: client.get(`schools/${schoolId}/tour-times`, {}, { credentials: 'omit' }),
  };
}

export function updateSchoolTourTimes(schoolId, timeTimesId, payload) {
  return {
    type: UPDATE_SCHOOL_TOUR_TIMES,
    promise: client.post(`schools/${schoolId}/tour-times/${timeTimesId}`, payload, { credentials: 'omit' }),
  };
}

export function addSchoolSitesReviews(schoolId, payload) {
  return {
    type: ADD_SCHOOL_SITES_REVIEWS,
    promise: client.post(`schools/${schoolId}/sites-reviews`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolSitesReviews(schoolId) {
  return {
    type: GET_SCHOOL_SITES_REVIEWS,
    promise: client.get(`schools/${schoolId}/sites-reviews`, {}, { credentials: 'omit' }),
  };
}

export function updateSubscriptionPlan(schoolId, payload) {
  return {
    type: UPDATE_SCHOOL_PLANID,
    promise: client.post(`schools/${schoolId}/updatePlan`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolAcademics(schoolId) {
  return {
    type: GET_SCHOOL_ACADEMICS,
    promise: client.get(`academics/${schoolId}`, {}, { credentials: 'omit' }),
  };
}

export function addSchoolAcademics(schoolId, payload) {
  return {
    type: ADD_SCHOOL_ACADEMICS,
    promise: client.post(`academics/${schoolId}`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolViews(schoolId) {
  return {
    type: GET_SCHOOL_VIEWS,
    promise: client.get(`schools/${schoolId}/views`, {}, { credentials: 'omit' }),
  };
}

export function updateSchoolTourLead(schoolId, tourId, payload) {
  return {
    type: UPDATE_SCHOOL_SCHEDULE_TOUR_LEAD,
    promise: client.post(`schools/${schoolId}/scheduletour/${tourId}/lead`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolConnectCount(schoolId) {
  return {
    type: GET_SCHOOL_CONNECT_COUNT,
    promise: client.get(`requesttoconnect/${schoolId}/school-connections-count`, {}, { credentials: 'omit' }),
  };
}

export function updateSchoolMarketing(id, payload) {
  return {
    type: UPDATE_SCHOOL_MARKETING,
    promise: client.post(`schools/${id}/school-marketing`, payload, { credentials: 'omit' }),
  };
}

export function customerUpgrade(id, payload) {
  return {
    type: CUSTOM_UPGRADE_SCHOOL_PLAN,
    promise: client.post(`schools/${id}/plan-upgrade`, payload, { credentials: 'omit' }),
  };
}

export function updateSchoolEmailMarketing(schoolId, status) {
  return {
    type: UPDATE_SCHOOL_EMAIL_MARKETING,
    promise: client.post(`schools/${schoolId}/email-marketing`, status, { credentials: 'omit' }),
  };
}

export function addFeatureSuggestion(schoolId, data) {
  return {
    type: ADD_FEATURE_SUGGESTION,
    promise: client.post(`schools/${schoolId}/featureRequest`, data, { credentials: 'omit' }),
  };
}

export function saveEnrollmentGoal(schoolId, enrollmentGoal) {
  let payload = new FormData();
  payload.append('enrollmentGoal', enrollmentGoal);
  return {
    type: UPDATE_SCHOOL_ENROLLMENT_GOAL,
    promise: client.post(`schools/${schoolId}/enrollementGoal`, payload, { credentials: 'omit' }),
  };
}

export function getSchoolEnrollement(schoolId) {
  return {
    type: GET_SCHOOL_ENROLLEMENT,
    promise: client.get(`schools/${schoolId}/enrollement`, {}, { credentials: 'omit' }),
  };
}

export function getSchoolAwareness(schoolId) {
  return {
    type: GET_SCHOOL_AWERENESS,
    promise: client.get(`schools/${schoolId}/awareness`, {}, { credentials: 'omit' }),
  };
}

export function getSchoolEngagement(schoolId) {
  return {
    type: GET_SCHOOL_ENGAGEMENT,
    promise: client.get(`schools/${schoolId}/engagement`, {}, { credentials: 'omit' }),
  };
}

export function getSchoolLeaders() {
  return {
    type: GET_SCHOOL_LEADERS,
    promise: client.get(`schools/leaders`, {}, { credentials: 'omit' }),
  };
}

export function getSchoolDashboard(schoolId, sinceDate) {
  const url = `schools/${schoolId}/dashboard${sinceDate ? `?from=${sinceDate}` : ''}`;

  return {
    type: GET_SCHOOL_ENGAGEMENT,
    promise: client.get(url, {}, { credentials: 'omit' }),
  };
}

export function sendScholaBoostsNotification(schoolid, userid) {
  return {
    type: SEND_SCHOLA_BOOST_NOTIFICATION,
    promise: client.get(`schools/${schoolid}/${userid}/scholaboosts`, {}, { credentials: 'omit' }),
  };
}

export function buyScholaBoosts(schoolId, data) {
  return {
    type: BUY_SCHOLA_BOOST,
    promise: client.post(`schools/${schoolId}/buy-scholaboosts`, data, { credentials: 'omit' }),
  };
}

export function getSchoolTeacherStuff(schoolId) {
  return {
    type: GET_SCHOOL_TEACHER_STUFF,
    promise: client.get(`schools/${schoolId}/teachers-staff`, {}, { credentials: 'omit' }),
  };
}

export function sendClaimVerificationCode(code, payload) {
  return {
    type: SEND_CLAIM_VERIFICATION_CODE,
    promise: client.post(`schools/claim/${code}`, payload, { credentials: 'omit' }),
  };
}

export function getS3Bucket() {
  return {
    type: GET_S3BUCKET,
    promise: client.get(`schools/s3bucket`, {}, { credentials: 'omit' }),
  };
}
