import { combineReducers } from 'redux';
import localstorage from 'store2';

import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from './auth-actions.js';

import { UPDATE_USER_SUCCESS } from '../user/user-actions.js';

function token(state = localstorage.get('idToken') || null, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localstorage.set('idToken', action.idToken);
      localstorage.set('accessToken', action.accessToken);
      return action.idToken;
    case UPDATE_USER_SUCCESS:
      localstorage.set('idToken', action.json.idToken);
      localstorage.set('accessToken', action.json.accessToken);
      return action.json.idToken;
    case LOGIN_FAILURE:
    case LOGOUT:
      localstorage.set('idToken', null);
      localstorage.set('accessToken', null);
      return null;
    default:
      return state;
  }
}

const auth = combineReducers({
  token,
});

export default auth;
