import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { _getSchoolById } from 'controllers/schools/schools_controller';
import { planName } from 'util/schoolUtils';
import { getResponseValue } from 'util/validate';
import { Tabs } from 'antd';
import { MessageOutlined, MailOutlined, HistoryOutlined } from '@ant-design/icons';
import { SectionPreview } from 'components/upgrade/section-preview';
import styles from './communication-hub.module.scss';

export const CommunicationHub = ({ location, params, children }) => {
  const { id: schoolId } = params;
  const [path, setPath] = useState('message');
  const [school, setSchool] = React.useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setPath(location.pathname.split('/').at(-1));
  }, [location.pathname]);

  const onLoadSchool = async () => {
    const _school = await _getSchoolById(schoolId);
    const school = await getResponseValue(_school);

    setSchool(school);
    loadItems(school);
  };

  useEffect(() => {
    onLoadSchool();
  }, []);

  const handleTabChange = (key) => {
    browserHistory.push(`/admin/v2/schools/${schoolId}/communication-hub/${key}`);
  };

  const loadItems = (school) => {
    setItems([
      {
        key: 'messages',
        label: (
          <span className={styles.tabTrigger}>
            <MessageOutlined className={styles.icon} />
            Message Center
            {school && planName(school) === 'scholabasics' && <i className="fa fa-lock" aria-hidden="true" />}
          </span>
        ),
      },
      {
        key: 'campaigns-center',
        label: (
          <span className={styles.tabTrigger}>
            <MailOutlined className={styles.icon} />
            Campaigns
            {school && planName(school) === 'scholabasics' && <i className="fa fa-lock" aria-hidden="true" />}
          </span>
        ),
      },
      {
        key: 'historical',
        label: (
          <span className={styles.tabTrigger}>
            <HistoryOutlined className={styles.icon} />
            Historical
          </span>
        ),
      },
    ]);
  };

  const previewBlocker = () => {
    return (
      <SectionPreview
        title="Build Automated Messages and Campaigns"
        description="Create one-way automated text and email campaigns to simplify and streamline communication, so families take action and submit an application to your school."
        image="https://scholamatch-static.s3.us-west-2.amazonaws.com/landing/schools/campaigns-preview.png"
        schoolId={school.id}
        zipcode={school.zip}
      />
    );
  };

  return (
    <div className={styles.layout}>
      <h1>Communication Hub</h1>
      <Tabs items={items} activeKey={path} onChange={handleTabChange} className={styles.tabsList} />
      {school && planName(school) === 'scholabasics' && path !== 'historical' && previewBlocker()}
      {school && (planName(school) !== 'scholabasics' || path === 'historical') && (
        <section className={styles.contentChildren}>{children}</section>
      )}
    </div>
  );
};
