import client from '../client';
import { getResponseValue } from 'util/validate';

export const _sendEmailMessageToLead = async (schoolId, leadId, subject, body, attachments) => {
  let payload = {
    lead_id: leadId,
    subject,
    body,
    communication_type: 'Email',
  };
  if (attachments) {
    payload.attachments = attachments;
  }
  const res = await client.post(`school/${schoolId}/messages/send-to-lead`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _replyEmailMessageToLead = async (schoolid, leadId, messageId, body, attachments) => {
  let payload = {
    lead_id: leadId,
    message_id: messageId,
    body,
    communication_type: 'Email',
  };
  if (attachments) {
    payload.attachments = attachments;
  }
  const res = await client.post(`school/${schoolid}/messages/reply-to-lead`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _resendMessageToLead = async (schoolId, leadId, messageId, body, attachments) => {
  let payload = {
    lead_id: leadId,
    message_id: messageId,
    body,
    communication_type: 'Email',
  };
  if (attachments) {
    payload.attachments = attachments;
  }
  const res = await client.post(`school/${schoolId}/messages/resend-to-lead`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _deleteMessage = async (schoolId, messageId) => {
  const res = await client.delete(`school/${schoolId}/message/${messageId}`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _sendSmsMessageToLead = async (schoolId, leadId, message) => {
  let payload = {
    lead_id: leadId,
    body: message,
    communication_type: 'Sms',
  };

  const res = await client.post(`school/${schoolId}/messages/send-to-lead`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _sendSmsMessage = async (schoolId, leadId, phone, message) => {
  let payload = {
    lead_id: leadId,
    phone,
    body: message,
  };

  const res = await client.post(`school/${schoolId}/messages/send-sms`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getMessagesByFilters = async (params) => {
  const {
    school_id: schoolId,
    lead_id: leadId,
    communication_type: communicationType,
    page = 1,
    page_size: pageSize = 200,
  } = params;

  let payload = {
    page,
    page_size: pageSize,
    lead_id: leadId,
    communication_type: communicationType,
  };

  const res = await client.get(`school/${schoolId}/messages/get-messages`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getLeadForMessages = async (params) => {
  const { school_id: schoolId, page = 1, page_size: pageSize = 20, sort_by: sortBy, search = '', stage } = params;
  let payload = {
    page,
    page_size: pageSize,
    sort_by: sortBy,
    search,
    stage: stage || 'all',
  };

  const res = await client.get(`school/${schoolId}/messages/leads-for-messages`, payload, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _insertAttachment = async (schoolId, file) => {
  const formData = new FormData();
  formData.append('attachment', file);
  formData.append('file_name', file.name);
  formData.append('file_type', file.type);
  formData.append('file_size', file.size);

  const res = await client.post(`school/${schoolId}/messages/attachment`, formData, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _deleteAttachment = async (schoolId, messageAttachmentId) => {
  const res = await client.delete(
    `school/${schoolId}/messages/attachments/${messageAttachmentId}`,
    {},
    { credentials: 'omit' }
  );
  return await getResponseValue(res);
};

export const _setMessageAsRead = async (schoolId, messageId, isReadBy) => {
  let payload = {
    is_read_by: isReadBy,
  };
  const res = await client.patch(`school/${schoolId}/message/${messageId}/set-as-read`, payload, {
    credentials: 'omit',
  });
  return await getResponseValue(res);
};

export const _setMessageAsUnread = async (schoolId, messageId) => {
  const res = await client.patch(`school/${schoolId}/message/${messageId}/set-as-unread`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getMessageCountBySchool = async (schoolId) => {
  const res = await client.get(`school/${schoolId}/messages/message-count`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};

export const _getMessageCountByLeadId = async (schoolId, leadId) => {
  const res = await client.get(`school/${schoolId}/messages/lead/${leadId}/message-count`, {}, { credentials: 'omit' });
  return await getResponseValue(res);
};
