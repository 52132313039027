import React, { useMemo } from 'react';
import styles from './dashboard-school.module.scss';
import {
  TotalStatusCount,
  ProfileInteraction,
  CampaignsReview,
  LiveSearchPreview,
  PipelineLeads,
  PerformanceTimeline,
  NotificationsCenter,
  RangeSelector,
  SpotPerformance,
  WelcomeBox,
  EnrollmentProgress,
} from './components';
import { _isSuperadmin, getProfile } from 'util/auth';
import {
  useGetLastLogin,
  useLeadTimeline,
  useLeadsCount,
  useLiveLeadsDetails,
  usePerformanceByStatus,
  useProfileInteraction,
  useProfileRate,
  useRangeDates,
  useRanges,
  useReviews,
  useSpotPerformance,
} from './hooks';
import { useSelector } from 'react-redux';
import config from 'config';
import SchoolUrl from 'util/schoolUrl';
import { LoadingOutlined } from '@ant-design/icons';

export const DashboardSchool = ({ params: { id } }) => {
  const { name, sub: userId } = getProfile();
  const isAdmin = useMemo(() => _isSuperadmin(), []);

  const { currentSchool } = useSelector((state) => state.school);

  const { leadsCount, leadStatus } = useLeadsCount(id);

  const lastLoginUser = useGetLastLogin(userId);

  const { defaultRanges, todayDate } = useRanges(lastLoginUser);

  const { range, handleRange } = useRangeDates(defaultRanges);

  const disablePhoneCalls = useMemo(() => {
    const dialpadDataAvailableSince = '2024/03/27';
    const disable = dialpadDataAvailableSince >= range.value;

    return disable;
  }, [range.value]);

  const { timeline, isErrorTimeline } = useLeadTimeline({ schoolId: id, sinceDate: range.value, today: todayDate });

  const { performanceLeads, isPerformaceLoading } = usePerformanceByStatus({ schoolId: id, rangeDates: range });

  const { spotPerformance, spotCalls, timeSaved, isLoadingSPOT } = useSpotPerformance({
    schoolId: id,
    sinceDate: range.value,
    today: todayDate,
    disablePhoneCalls,
  });

  const { scholaMatchAppearances } = useProfileInteraction(id, range.value);

  const { reviews } = useReviews(id);

  const progressProfileRate = useProfileRate(currentSchool, reviews);

  const { liveLeads } = useLiveLeadsDetails(id, {
    latitude: currentSchool?.latitude,
    longitude: currentSchool?.longitude,
  });

  const dashboardLinks = {
    rm: `/admin/v2/schools/${id}/relationship-manager`,
    liveSearch: `/admin/v2/schools/${id}/live-search`,
    campaigns: `/admin/v2/schools/${id}/campaigns`,
    reports: `/admin/v2/schools/${id}/reports`,
    tours: `/admin/v2/schools/${id}/tours`,
    messages: `/admin/v2/schools/${id}/messages`,
    editProfile: `/admin/v2/schools/${id}/profile`,
    liveProfile: currentSchool ? `${config.searchHost}${SchoolUrl.build(currentSchool)}` : '',
  };

  return (
    <main className={styles.main}>
      <section className={styles.wrapper}>
        <WelcomeBox name={name} lastLoginUser={lastLoginUser} isLastLoginSelected={range.id === 'lastLogin'}>
          <RangeSelector ranges={defaultRanges} currentRange={range} onChangeRange={handleRange}>
            {range.label}
          </RangeSelector>
        </WelcomeBox>

        <div className={styles.firstgrid}>
          <TotalStatusCount
            title="New Leads"
            isPerformaceLoading={isPerformaceLoading}
            count={performanceLeads?.new}
            link={dashboardLinks.rm}
          />
          <TotalStatusCount
            title="New Applications"
            isPerformaceLoading={isPerformaceLoading}
            count={performanceLeads?.applications}
            link={dashboardLinks.rm}
          />
          <TotalStatusCount
            title="New Enrollments"
            isPerformaceLoading={isPerformaceLoading}
            count={performanceLeads?.enrollments}
            link={dashboardLinks.rm}
          />

          <SpotPerformance
            spotMessageCount={spotPerformance}
            spotCalls={spotCalls.totalCalls}
            className={styles.spot}
            timeSaved={timeSaved}
            disablePhoneCalls={disablePhoneCalls}
            isLoading={isLoadingSPOT}
          />

          <div className={styles.pipeline}>{leadStatus.loading && <LoadingOutlined />}</div>
          {leadStatus.success && (
            <PipelineLeads
              leadsCount={leadsCount.byStatus.filter(({ status }) => status !== 'archived')}
              className={styles.pipeline}
              link={dashboardLinks.rm}
            />
          )}

          <LiveSearchPreview
            schoolData={currentSchool}
            leads={liveLeads}
            link={dashboardLinks.liveSearch}
            className={styles.map}
          />
        </div>

        <PerformanceTimeline timeline={timeline} className={styles.timeline} link={dashboardLinks.reports} />
      </section>

      <aside className={styles.sideLine}>
        <NotificationsCenter schoolId={id} toursLink={dashboardLinks.tours} messagesLink={dashboardLinks.messages}>
          <EnrollmentProgress schoolId={id} isAdmin={isAdmin} />
        </NotificationsCenter>
        <ProfileInteraction
          appearances={scholaMatchAppearances}
          progress={progressProfileRate}
          editProfile={dashboardLinks.editProfile}
          liveProfile={dashboardLinks.liveProfile}
        />
        <CampaignsReview schoolId={id} link={dashboardLinks.campaigns} />
      </aside>
    </main>
  );
};

export default DashboardSchool;
