import { store } from '../store';

export const OPEN_CONTACT = 'OPEN_CONTACT';
export const CLOSE_CONTACT = 'CLOSE_CONTACT';

export const onOpenContactModal = () => store.dispatch({ type: OPEN_CONTACT });
export const onCloseContactModal = () => store.dispatch({ type: CLOSE_CONTACT });

export const OPEN_IMPORT = 'OPEN_IMPORT';
export const CLOSE_IMPORT = 'CLOSE_IMPORT';

export const onOpenImportModal = () => store.dispatch({ type: OPEN_IMPORT });
export const onCloseImportModal = () => store.dispatch({ type: CLOSE_IMPORT });
