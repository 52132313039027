import React from 'react';

const District = () => {
  /* eslint-disable max-len */
  return (
    <svg width="45" height="28" viewBox="-1 0 45 28" xmlns="http://www.w3.org/2000/svg">
      <g fill="#8AC340" fillRule="nonzero">
        <path
          d="M35.1227026,2.6412284 C35.4293629,2.67266737 35.6625437,2.93097625 35.6625437,3.2392439 L35.6625437,26.5459512 C35.6625437,26.8779572 35.3933997,27.1471012 35.0613937,27.1471012 L9.58166836,27.1471012 C9.24966238,27.1471012 8.98051836,26.8779572 8.98051836,26.5459512 L8.98051836,0.62704878 C8.98051836,0.270796718 9.28858272,-0.00729944003 9.64297723,0.0290332749 L35.1227026,2.6412284 Z M34.4602437,3.78191461 L10.1828184,1.29297993 L10.1828184,25.9448012 L34.4602437,25.9448012 L34.4602437,3.78191461 Z"
          transform="translate(-1)"
        />
        <path
          d="M43.6033998 11.9759351C43.9339214 11.9780171 44.2007631 12.246545 44.2007631 12.5770732L44.2007631 26.5468293C44.2007631 26.8788352 43.9316191 27.1479793 43.5996131 27.1479793L35.0619445 27.1479793C34.7299385 27.1479793 34.4607945 26.8788352 34.4607945 26.5468293L34.4607945 12.5232927C34.4607945 12.1898069 34.732252 11.920054 35.0657312 11.9221546L43.6033998 11.9759351zM35.6630945 13.1282414L35.6630945 25.9456793 42.9984631 25.9456793 42.9984631 13.1744483 35.6630945 13.1282414zM9.58567535 11.9759351C9.91619692 11.9780171 10.1830387 12.246545 10.1830387 12.5770732L10.1830387 26.5468293C10.1830387 26.8788352 9.91389463 27.1479793 9.58188866 27.1479793L1.04422 27.1479793C.712214023 27.1479793.44307 26.8788352.44307 26.5468293L.44307 12.5232927C.44307 12.1898069.714527558 11.920054 1.04800669 11.9221546L9.58567535 11.9759351zM8.98073866 13.1744483L1.64537 13.1282414 1.64537 25.9456793 8.98073866 25.9456793 8.98073866 13.1744483zM30.297769 15.9127646L14.8151899 15.9127646C14.4831839 15.9127646 14.2140399 16.1819087 14.2140399 16.5139146 14.2140399 16.8459206 14.4831839 17.1150646 14.8151899 17.1150646L30.297769 17.1150646C30.6297749 17.1150646 30.898919 16.8459206 30.898919 16.5139146 30.898919 16.1819087 30.6297749 15.9127646 30.297769 15.9127646z"
          transform="translate(-1)"
        />
        <path
          d="M14.8147493,27.14765 C14.4827433,27.14765 14.2135993,26.878506 14.2135993,26.5465 L14.2135993,16.5136951 C14.2135993,16.1816891 14.4827433,15.9125451 14.8147493,15.9125451 L18.9034896,15.9125451 C19.2354955,15.9125451 19.5046396,16.1816891 19.5046396,16.5136951 L19.5046396,26.5465 C19.5046396,26.878506 19.2354955,27.14765 18.9034896,27.14765 L14.8147493,27.14765 Z M18.3023396,25.94535 L18.3023396,17.1148451 L15.4158993,17.1148451 L15.4158993,25.94535 L18.3023396,25.94535 Z"
          transform="translate(-1)"
        />
        <path
          d="M18.9034896,27.14765 C18.5714836,27.14765 18.3023396,26.878506 18.3023396,26.5465 L18.3023396,16.5136951 C18.3023396,16.1816891 18.5714836,15.9125451 18.9034896,15.9125451 L22.9922299,15.9125451 C23.3242358,15.9125451 23.5933799,16.1816891 23.5933799,16.5136951 L23.5933799,26.5465 C23.5933799,26.878506 23.3242358,27.14765 22.9922299,27.14765 L18.9034896,27.14765 Z M22.3910799,25.94535 L22.3910799,17.1148451 L19.5046396,17.1148451 L19.5046396,25.94535 L22.3910799,25.94535 Z"
          transform="translate(-1)"
        />
        <path
          d="M22.9922299,27.14765 C22.6602239,27.14765 22.3910799,26.878506 22.3910799,26.5465 L22.3910799,16.5136951 C22.3910799,16.1816891 22.6602239,15.9125451 22.9922299,15.9125451 L27.0809701,15.9125451 C27.4129761,15.9125451 27.6821201,16.1816891 27.6821201,16.5136951 L27.6821201,26.5465 C27.6821201,26.878506 27.4129761,27.14765 27.0809701,27.14765 L22.9922299,27.14765 Z M26.4798201,25.94535 L26.4798201,17.1148451 L23.5933799,17.1148451 L23.5933799,25.94535 L26.4798201,25.94535 Z"
          transform="translate(-1)"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default District;
